import * as React from 'react';
import cn from 'classnames';
import { IDividerProps } from '@common/components/data/divider/type';
import { classPrefix, useClasses } from '@common/components/data/divider/classes';
import { StyledDivider } from './styled';

const Divider = (props: IDividerProps) => {
  const { root, vertical, horizontal } = useClasses();
  const { isVertical, className: classNameFromProps } = props;

  const className = cn(classPrefix, root, {
    [vertical]: isVertical,
    [horizontal]: !isVertical,
    [classNameFromProps]: !!classNameFromProps,
  });

  return <StyledDivider {...props} className={className} />;
};

export default Divider;
