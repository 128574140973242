import i18next from 'i18next';
import { IMap } from '@common/types/map';
import { getControlledPromise, IControlledPromise } from '@common/utils/controlledPromise';
import * as moment from 'moment';
import { filterObject } from '@common/utils/objects';
import { getRelayAPI, RelayAPI } from '@common/utils/suspenseUtils';
import { INsMap } from '@common/locales/utils/withLocales/types';

const eventsStore: IMap<IControlledPromise<void>> = {};

const i18nextInitiPromise = getControlledPromise<void>();
const resolvedPromise = Promise.resolve();
i18next.on('initialized', i18nextInitiPromise.resolve);

// Проверяет наличие заглушки
const isLoading = (lng: string, ns: string): boolean =>
  !!i18next.getResourceBundle(lng, ns)?.isStub;

// Ждет загрузку локали
const waitLocaleLoading = async (lng: string, ns: string): Promise<void> =>
  isLoading(lng, ns) ? eventsStore[ns] : resolvedPromise;

// Удаляет колбэк оповещения загрузки локали
const removeCallback = (ns: string): void => {
  if (eventsStore[ns]) {
    eventsStore[ns]?.resolve();
    delete eventsStore[ns];
  }
};

// Добавляет колбэк (управляемый промис) ожидания загрузки локали
const addCallback = (ns: string) => {
  eventsStore[ns] = getControlledPromise();
};

// Удаляет все колбэки ожидания загрузки локали
const removeAllCallbacks = (nsMap: INsMap): void => {
  Object.keys(nsMap).forEach((ns) => {
    removeCallback(ns);
  });
};

// Фильтрует список локалей - оставляет только те что не были загружены
const getNotLoadedLocales = (nsMap: INsMap): INsMap =>
  i18next.isInitialized
    ? filterObject(nsMap, (ns: string) => {
        const hasResourceBundle = i18next.hasResourceBundle(i18next.language, ns);
        return !hasResourceBundle || isLoading(i18next.language, ns);
      })
    : nsMap;

// Добавляет локаль или заглушку на время загрузки
const addResourceBundle = (lng: string, ns: string, resources?: any): void => {
  const isPlg = isLoading(lng, ns);
  i18next.removeResourceBundle(lng, ns);
  i18next.addResourceBundle(lng, ns, !resources ? { isStub: true } : resources);

  if (!resources) {
    addCallback(ns);
    return;
  }

  if (isPlg) {
    removeCallback(ns);
  }
};

// Создает загрузчик локалей
const getLocalesLoader = (
  nsMap: INsMap,
  currentLanguage: string,
  updateRelayAPI: (newApiInstance: RelayAPI) => void,
) => {
  function loadLocales(lng: string) {
    // затем ждем загрузку всех необходимых локалей
    const notLoadedLocales = Object.entries(getNotLoadedLocales(nsMap));

    if (notLoadedLocales.length === 0) return;
    const loadLocalesFactory = async () => {
      // сначала ждем инициализацию i18next
      if (!i18next.isInitialized) {
        await i18nextInitiPromise;
      }

      await Promise.all(
        notLoadedLocales.map(async ([ns, getLocales]) => {
          if (!i18next.hasResourceBundle(lng, ns)) {
            // ставим дефолт заглушку для дальнейшей загрузки
            addResourceBundle(lng, ns);
            try {
              const translations = await getLocales(lng, ns);
              addResourceBundle(lng, ns, translations);
            } catch (e) {
              console.error(e);
            }
            return;
          }
          await waitLocaleLoading(lng, ns);
        }),
      );

      moment.locale(lng);
    };

    updateRelayAPI(getRelayAPI<void>(loadLocalesFactory()));
  }
  loadLocales(currentLanguage); // инициализационный вызов
  return loadLocales;
};

export default {
  removeAllCallbacks,
  getLocalesLoader,
};
