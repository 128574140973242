import { ETreeListViewItemSelected } from '@common/components/data/tree-list-view/TreeListViewModel';

export const draggableTerm = 'draggable-term';

export const itemSelectedArray = [
  ETreeListViewItemSelected.SELECTED,
  ETreeListViewItemSelected.PARENT_PART_CHILD,
];

export const TREE_LIST_VIEW_UNIQUE_DND_ID = 'TLV';
export const TREE_LIST_VIEW_ROOT_ID = 'ROOT';

/**
 * TODO: Избавиться от классов, по мере стилизации компонентов, в которых они используются
 */
const treeItemClassName = 'tree-list-view-item';
const treeItemLabelClassName = `${treeItemClassName}__label`;
export const treeItemClassNamesMap = {
  item: treeItemClassName,
  itemDnd: `${treeItemClassName}__draggable`,
  itemArrow: `${treeItemClassName}__arrow`,
  itemLabel: treeItemLabelClassName,
  itemLabelIcon: `${treeItemLabelClassName}-icon`,
  itemLabelContent: `${treeItemLabelClassName}-content`,
  itemLabelActions: `${treeItemLabelClassName}-actions`,
  itemLabelActionButton: `${treeItemLabelClassName}-action-button`,
  itemContent: `${treeItemClassName}__content`,
};

export const treeItemSelectors = {
  itemWithChildren: `${treeItemClassNamesMap.item}_has-children`,
};

const className = 'ui-tree-list-view';
export const treeClassNamesMap = {
  tree: className,
  treeDnd: `${className}__droppable`,
  wrapper: `${className}__wrapper`,
};

export const resizeObserverSettings = {
  observeClass: treeClassNamesMap.wrapper,
  triggerHeight: 35,
  triggerWidth: 5,
};
