import { getThemeValue } from '@themes/utils/getters';
// Необходимо для работы полифила focus-visible
// https://github.com/WICG/focus-visible
import '../../../node_modules/focus-visible/dist/focus-visible.min';
import * as React from 'react';
import '@common/styles/styles.less';

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  // надо ли?
  //html {
  //  -webkit-font-smoothing: antialiased;
  //  -moz-osx-font-smoothing: grayscale;
  //}
  #root, body, html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden!important;
    box-sizing: border-box;
  }
  html, body {
    font: ${({ theme }) =>
      `${theme.typography.fontSize}/${theme.typography.lineHeight} ${theme.typography.fontFamily}`};
  }
  * {
    font-family: ${({ theme }) => `${theme.typography.fontFamily}`};
  }
  *, *::before, *::after {
    box-sizing: inherit;
  }
  *:focus-visible {
    outline: none;
  }
  body {
    color: ${getThemeValue.paletteTextPrimary};
    background-color: ${getThemeValue.paletteBgDefault};
  }

  ::-webkit-input-placeholder {
    color: #c4c4c4;
  }

  ::-moz-placeholder {
    color: #c4c4c4;
    opacity: 1;
  } /* FF 19+ */
  :-moz-placeholder {
    color: #c4c4c4;
    opacity: 1;
  } /* FF 18- */
  :-ms-input-placeholder {
    color: #c4c4c4;
  }
  ::-moz-focus-inner {
    border: 0 !important;
  }
  h1 {
    ${(props) => props.theme.typography.h1}
  }
  h2 {
    ${(props) => props.theme.typography.h2}
  }
  h3 {
    ${(props) => props.theme.typography.h3}
  }
  h4 {
    ${(props) => props.theme.typography.h4}
  }
`;

/**
 * Базовые стили для приложения
 */
function CssBaseline(props) {
  const { children = null } = props;
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  );
}

export default CssBaseline;
