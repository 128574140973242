import { EPaletteMode, IThemePalette, IThemePaletteColors } from '@themes/styles/palette';
import { EThemeControlSize, EThemeIconSize, IThemeSize } from '@themes/styles/size';
import { IThemeTypography } from '@themes/styles/typography';
import { Shape } from '@themes/styles/shape';
import { IMap } from '@common/types/map';

type TComponentTheme<P = any> = {
  overrideStyles: boolean;
  defaultProps: Partial<P>;
  overridesProps: Partial<P>;
};

export interface BaseTheme {
  key: string;
  name: string;
  mode: EPaletteMode;
  palette: {
    colors?: IThemePaletteColors;
    light?: IThemePalette;
    dark?: IThemePalette;
  };
  size: IThemeSize;
  typography: IThemeTypography;
  shape: Shape;
  common: IMap<TComponentTheme>;
  platform: IMap<TComponentTheme>;
}

export type ITheme = Partial<BaseTheme> & {
  isFile?: boolean;
  [key: string]: any;
};

export const getCommonTheme = <P = any>(component: string, theme: ITheme): TComponentTheme<P> =>
  theme?.common?.[component];
export const getPlatformTheme = <P = any>(component: string, theme: ITheme): TComponentTheme<P> =>
  theme?.platform?.[component];

export { EThemeControlSize, EThemeIconSize };

export const CONTROL_STYLE_SIZE_M = { size: EThemeControlSize.M };
