import * as React from 'react';
import { EIcon, Icon } from '@themes';
import {
  StyledItemIcon,
  StyledSubMenu,
  StyledTitle,
} from '@common/components/popups/context-menu-advance/styled';
import { IContextSubMenuProps } from '@common/components/popups/context-menu-advance/types';

export const ContextMenuAdvanceSubMenu: React.FC<IContextSubMenuProps> = (props) => {
  const { hoverDelay, iconPath, titleClassName, title, ...rest } = props;
  const subMenuTitle = React.useMemo(() => {
    const cssClass = titleClassName == null ? '' : `${titleClassName}__`;
    return (
      <>
        {iconPath && (
          <StyledItemIcon className={`${cssClass}icon`}>
            <Icon icon={iconPath} />
          </StyledItemIcon>
        )}
        <StyledTitle title={title} mainClass={cssClass} />
        <Icon className="arrow-right" icon={EIcon.ActionsArrowRight} />
      </>
    );
  }, [titleClassName, iconPath, title]);
  return <StyledSubMenu {...rest} title={subMenuTitle} hoverDelay={hoverDelay || 100} />;
};
ContextMenuAdvanceSubMenu.displayName = 'ContextMenuAdvanceSubMenu';

export default ContextMenuAdvanceSubMenu;
