import { canUseDOM as canUseDOMFlag } from 'exenv';

export const canUseDOM = () => canUseDOMFlag;

export const supportsInlineSVG = () => {
  if (!document) {
    return false;
  }

  const div = document.createElement('div');
  div.innerHTML = '<svg />';
  return (
    div.firstChild && (div.firstChild as HTMLElement).namespaceURI === 'http://www.w3.org/2000/svg'
  );
};

export class InlineSVGError extends Error {
  public name: string;

  public message: string;

  public data?: object;

  constructor(message: string, data?: object) {
    super();

    this.name = 'InlineSVGError';
    this.message = message;
    this.data = data;

    return this;
  }
}

export const isSupportedEnvironment = () =>
  supportsInlineSVG() && typeof window !== 'undefined' && window !== null;
