export type IPromiseControl<T> = {
  resolve: (value?: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
  resolved: boolean;
};

export type IControlledPromise<T> = Promise<T> & IPromiseControl<T>;

export const getControlledPromise = <T>(): IControlledPromise<T> => {
  const control = { resolve: null, reject: null, resolved: false } as IPromiseControl<T>;
  const promise = new Promise<T>((resolve, reject) => {
    control.resolve = (data?: T) => {
      control.resolved = true;
      resolve(data);
    };
    control.reject = (reason?: any) => {
      control.resolved = true;
      reject(reason);
    };
  });
  return Object.assign(promise, control);
};
