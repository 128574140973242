import { paletteGetters } from '@themes/utils/getters/palette';
import { shapeGetters } from '@themes/utils/getters/shape';
import { sizeGetters } from '@themes/utils/getters/size';
import { typographyGetters } from '@themes/utils/getters/typography';

// THEME GETTER TOKENS
export const getThemeValue = {
  /** PALETTE */
  ...paletteGetters,
  /** SIZE */
  ...sizeGetters,
  /** SHAPE */
  ...shapeGetters,
  /** TYPOGRAPHY */
  ...typographyGetters,
};
