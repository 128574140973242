import styled, { css } from 'styled-components';
import ScrollButton from './ScrollButton';

export const StyledUpButton = styled(ScrollButton)`
  top: 0;
`;

export const ScrollButtonDown = styled(ScrollButton)`
  top: unset;
  bottom: 0;
  transform: rotate(180deg);
`;

export const StyledFlatList = styled.div`
  position: relative;

  &::after {
    border-top: 1px solid #e0e0e0;
    position: absolute;
    bottom: 0;
    left: 8px;
    right: 8px;
    height: 1px;
    content: '';
  }
`;

export const StyledScrollWrapper = styled.div<{ height: number; width: number }>`
  ${({ height, width }) =>
    css`
      height: ${height}px;
      width: ${width}px;
    `}
  overflow: hidden;
`;

export const StyledFlatItems = styled.div`
  position: relative;

  & > *::after {
    border-top: 1px solid #e0e0e0;
    position: absolute;
    top: 0;
    left: 8px;
    right: 8px;
    height: 1px;
    content: '';
  }
`;
