import { ITreeListViewItemProps } from '@common/components/data/tree-list-view/TreeListViewModel';
import classNames from 'classnames';
import * as React from 'react';
import { MouseEvent } from '@common/types/mouseEvent';
import { EIcon, EThemeIconSize, Icon } from '@themes';
import {
  StyledTreeItemArrow,
  StyledTreeItemContainer,
  StyledTreeItemContent,
  StyledTreeItemContentContainer,
} from '@common/components/data/tree-list-view/styled/StyledTreeItem';
import {
  ETreeListViewItemSelected,
  IStyledTreeItemContainer,
} from '@common/components/data/tree-list-view/types/types';
import {
  itemSelectedArray,
  treeItemClassNamesMap,
} from '@common/components/data/tree-list-view/constants';
import Divider from '@common/components/data/divider/Divider';
import ItemIcon from './ItemIcon';

/**
 * TODO: Избавиться от классов, по мере стилизации компонентов, в которых они используются
 */

class Item extends React.Component<ITreeListViewItemProps> {
  private handleItemClick = (event): void => {
    const { item, onItemClick } = this.props;
    if (onItemClick) {
      onItemClick(item.key, event, item);
    }
  };

  private handleMouseOver = (): void => {
    const { item, onItemHover } = this.props;
    if (onItemHover) {
      onItemHover(item.key);
    }
  };

  private handleMouseLeave = (): void => {
    const { item, onItemLeave } = this.props;
    if (onItemLeave) {
      onItemLeave(item.key);
    }
  };

  private handleItemDoubleClick = (): void => {
    const { item, onItemDoubleClick } = this.props;
    if (onItemDoubleClick) {
      onItemDoubleClick(item.key);
    }
  };

  private handleItemRightClick = (): void => {
    const { item, onItemRightClick } = this.props;
    if (onItemRightClick) {
      onItemRightClick(item.key, item.object);
    }
  };

  private handleToggle = (event: MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    const { item, onItemToggle } = this.props;
    if (onItemToggle) {
      onItemToggle(item.key);
    }
  };

  private renderContent = (): JSX.Element => {
    const { item, hideIcon, customization } = this.props;
    const { caption, contentClass, labelClass, itemSettings, icon } = item;
    const isCut = itemSettings?.isCut;
    const isIconExists = icon && !hideIcon;

    const {
      itemContent: itemContentClassName,
      itemLabelContent: itemLabelContentClassName,
    } = treeItemClassNamesMap;

    const contentClassNames = classNames(itemContentClassName, {
      [contentClass]: contentClass,
    });

    const labelClassNames = classNames(itemLabelContentClassName, {
      [labelClass]: labelClass,
    });
    return (
      <StyledTreeItemContentContainer
        className={labelClassNames}
        customization={customization}
        isCut={isCut}
      >
        <ItemIcon item={item} isIconExists={isIconExists} />
        <StyledTreeItemContent
          className={contentClassNames}
          customization={customization}
          isCut={isCut}
          isIconExists={isIconExists}
        >
          {caption}
        </StyledTreeItemContent>
      </StyledTreeItemContentContainer>
    );
  };

  private renderArrow = (): JSX.Element => {
    const {
      item: { collapsed },
      testId,
      customization,
      onItemToggle,
    } = this.props;

    const { itemArrow } = treeItemClassNamesMap;
    const className = classNames(itemArrow, {
      [`${itemArrow}_collapsed`]: collapsed,
    });

    const arrowIcon = customization?.collapseIcon?.icon ?? EIcon.ActionsArrowRightSlim;

    return (
      <StyledTreeItemArrow
        className={className}
        iconSize={EThemeIconSize.M}
        onClick={this.handleToggle}
        collapsed={collapsed}
        testId={testId && `${testId}_arrow-toggle`}
        customization={customization}
        disabled={!onItemToggle}
      >
        <Icon icon={arrowIcon} />
      </StyledTreeItemArrow>
    );
  };

  private renderItem = ({ ref }) => {
    const {
      item,
      className,
      itemRef,
      styledDndProps,
      testId,
      rightToggleIcon,
      customization,
      displayVariant,
      cssStyle,
    } = this.props;
    const {
      children,
      selectable,
      selected,
      nesting = 0,
      className: itemClassName,
      isDraggable,
      itemSettings,
      isDivider,
    } = item;

    const isChildrenExists = !!children;
    const isItemSelected = itemSelectedArray.includes(selected);
    const isItemChildSelected = selected === ETreeListViewItemSelected.PART_CHILD;
    const isItemRoot = nesting === 0;

    const { item: treeItemClassName, itemLabel: treeItemLabelClassName } = treeItemClassNamesMap;
    const containerClassName = classNames(treeItemClassName, treeItemLabelClassName, {
      [`${treeItemClassName}_not-selectable`]: !selectable,
      [`${treeItemClassName}_selected`]: isItemSelected,
      [`${treeItemClassName}_root`]: isItemRoot,
      [`${treeItemClassName}_empty`]: !isChildrenExists,
      [`${treeItemClassName}_has-children`]: isChildrenExists,
      [className]: className,
      [itemClassName]: itemClassName,
    });

    const containerStyledProps: IStyledTreeItemContainer = {
      isChildrenExists,
      selectable,
      isItemSelected,
      isItemChildSelected,
      itemSettings,
      nesting,
      isDraggable,
      customization,
      isDivider,
    };

    let content;
    if (isDivider) {
      content = <Divider size="100%" />;
    } else {
      content = (
        <>
          {!rightToggleIcon && isChildrenExists && this.renderArrow()}
          {this.renderContent()}
          {rightToggleIcon && isChildrenExists && this.renderArrow()}
        </>
      );
    }

    return (
      <StyledTreeItemContainer
        ref={ref || itemRef}
        className={containerClassName}
        onClick={this.handleItemClick}
        onDoubleClick={this.handleItemDoubleClick}
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
        onContextMenu={this.handleItemRightClick}
        data-testid={testId && `${testId}_item`}
        data-item-key={item.key}
        rightToggleIcon={rightToggleIcon}
        displayVariant={displayVariant}
        tabIndex={0}
        style={cssStyle}
        {...containerStyledProps}
        {...styledDndProps}
      >
        {content}
      </StyledTreeItemContainer>
    );
  };

  public render = (): JSX.Element => {
    const { item } = this.props;

    if (!item) {
      return null;
    }

    return this.renderItem({ ref: undefined });
  };
}

export default Item;
