import * as _ from 'lodash';
import { IMap } from '@common/types/map';
import {
  IBuiltTreeData,
  IBuiltTreeItem,
  ITreeItem,
  ITreeListViewDragAndDrop,
  TreeListViewItemKey,
} from '@common/components/data/tree-list-view/TreeListViewModel';
import { itemSelectedArray } from '@common/components/data/tree-list-view/constants';
import { MouseEvent } from '@common/types/mouseEvent';
import memoizeOne from 'memoize-one';
import { Keys } from '@common/types/keyboardEvent';
import { ETreeListViewItemSelected } from './types/types';

const parentOrRoot = (parent: TreeListViewItemKey): TreeListViewItemKey => {
  const notOk = parent === undefined || parent === null || parent === '';
  return notOk ? 'ROOT' : parent;
};

const itemSelected = memoizeOne(
  (item: ITreeItem): ETreeListViewItemSelected => {
    if (!item.selected) {
      return ETreeListViewItemSelected.NOT_SELECTED;
    }

    if (item.selected === true) {
      return ETreeListViewItemSelected.SELECTED;
    }

    return item.selected;
  },
);

const breakRefs = (parent: IBuiltTreeItem, child: IBuiltTreeItem): void => {
  child.parentElement = null;
  parent.children = _.remove(parent.children, (item: IBuiltTreeItem) => item === child);
};

const flatten = (tree: IBuiltTreeItem[]): IBuiltTreeItem[] =>
  _.reduce(
    tree,
    (flat: IBuiltTreeItem[], item: IBuiltTreeItem): IBuiltTreeItem[] => {
      const needChildren = item.children?.length > 0 && !item.collapsed;
      const children = needChildren ? flatten(item.children) : [];

      flat.push(item, ...children);

      return flat;
    },
    [],
  );

/**
 * Первращает массив элементов дерева в массив подготовленных для отрисовки
 * элементов дерева.
 * @param items - Элементы дерева.
 * @param itemsMap - Мапа подготовленных для отрисовки элементом вдерева.
 */
const flattenByData = (items: ITreeItem[], itemsMap: IMap<IBuiltTreeItem>) =>
  items.map((item) => itemsMap[item?.key]);

/**
 *
 * @param data
 * @param parentsPartialSelection
 * @param dragAndDrop
 * @param calcDedicatedParents
 * @param isItemsSorted - Элементы уже отсортированы для отображения в дереве.
 */
export const treeBuilder = (
  data: ITreeItem[],
  parentsPartialSelection = true,
  dragAndDrop: ITreeListViewDragAndDrop = null,
  calcDedicatedParents = false,
  isItemsSorted?: boolean,
): IBuiltTreeData => {
  const selectedItemsKeys: TreeListViewItemKey[] = [];
  const selectedItems: IMap<IBuiltTreeItem> = {};
  const draggableItems: IMap<IBuiltTreeItem> = {};
  const itemByIndex: IMap<IBuiltTreeItem> = {};
  const itemByDraggableId: IMap<IBuiltTreeItem> = {};

  const uniqueDragAndDropId = dragAndDrop?.uniqueDragAndDropId ?? '';
  const autoIsDraggable = dragAndDrop?.autoIsDraggable ?? true;
  const dragBySelected = dragAndDrop?.dragBySelected ?? true;

  const updateCounters = (tree: IBuiltTreeItem[]): IBuiltTreeItem[] => {
    let index = -1;

    const selectedParents = new Set<TreeListViewItemKey>();
    const selectedNesting = new Set<TreeListViewItemKey>();

    const updateLevels = (items: IBuiltTreeItem[], nesting: number): void => {
      items.forEach((item: IBuiltTreeItem): void => {
        if (!item) {
          return;
        }
        index += 1;

        item.index = index;
        item.nesting = item.nesting ?? nesting;

        itemByIndex[index] = item;

        if (item.selected === ETreeListViewItemSelected.SELECTED) {
          selectedItems[item.key] = item;
          selectedItemsKeys.push(item.key);

          selectedParents.add(item.parent);
          selectedNesting.add(item.nesting);
        }

        if (item.children?.length > 0 && !item.collapsed) {
          updateLevels(item.children, nesting + 1);
        }
      });
    };

    const updateDraggable = (items: IBuiltTreeItem[]): void => {
      const dragAndDropEnabled = !!uniqueDragAndDropId;
      const dragAndDropAvailable = selectedParents.size === 1 && selectedNesting.size === 1;

      items.forEach((item: IBuiltTreeItem): void => {
        if (!item) {
          return;
        }
        const key = item.key ?? '';
        const parent = parentOrRoot(item.parent);
        const checkSelected = dragBySelected ? selectedItemsKeys.includes(key) : true;

        const autoIsDraggableValue: boolean =
          dragAndDropEnabled && dragAndDropAvailable && checkSelected;
        const customIsDraggableValue = item?.isDraggable && checkSelected;

        const isDraggable = autoIsDraggable ? autoIsDraggableValue : customIsDraggableValue;
        const isSelected = item?.selected === ETreeListViewItemSelected.SELECTED;

        const draggableId = `${uniqueDragAndDropId}.${parent}.${key}`;
        item.draggableId = draggableId;

        item.isDraggable = isDraggable;

        itemByDraggableId[draggableId] = item;

        const willDrag = autoIsDraggable || !dragBySelected ? isDraggable : isSelected;
        if (willDrag) {
          draggableItems[item.key] = item;
        }

        if (item.children?.length > 0 && !item.collapsed) {
          updateDraggable(item.children);
        }
      });
    };

    updateLevels(tree, 0);
    updateDraggable(tree);

    return tree;
  };

  const itemsMap: IMap<IBuiltTreeItem> = {};
  if (!data || data.length <= 0) {
    return null;
  }

  data.forEach((item: ITreeItem): void => {
    if (!item) {
      return;
    }
    itemsMap[item.key] = {
      key: item.key,
      parent: item.parent === 0 ? 0 : item.parent || null,
      parentElement: undefined,
      caption: item.caption,
      collapsed: item.collapsed,
      selected: itemSelected(item),
      selectable: item.selectable ?? true,
      children: item.hasChildren ? [] : null,
      icon: item.icon || null,
      labelClass: item.labelClass,
      contentClass: item.contentClass,
      className: item.className,
      isDraggable: item?.isDraggable ?? false,
      index: 0,
      nesting: item.levelIndex,
      object: item.object,
      itemSettings: item.itemSettings,
      iconSize: item.iconSize,
      isDivider: item.isDivider,
    };
  });

  const tree: IBuiltTreeItem[] = [];

  // Помечает всех родителей выбранных элементв
  if (calcDedicatedParents) {
    data
      .slice()
      .reverse()
      .forEach((item) => {
        if (!item) {
          return;
        }
        const built: IBuiltTreeItem = itemsMap[item.key];
        const parent: IBuiltTreeItem = itemsMap[item.parent];

        if (
          (built.selected === ETreeListViewItemSelected.SELECTED ||
            built.selected === ETreeListViewItemSelected.PART_CHILD) &&
          parent
        ) {
          parent.selected = ETreeListViewItemSelected.PART_CHILD;
        }
      });
  }

  data.forEach((item: ITreeItem): void => {
    if (!item) {
      return;
    }
    const built: IBuiltTreeItem = itemsMap[item.key];

    const parent = itemsMap[item.parent];
    if (parent) {
      built.parentElement = parent;
      if (!parent.children) {
        parent.children = [];
      }
      parent.children.push(built);
      return;
    }

    tree.push(built);
  });

  data.forEach((item: ITreeItem): void => {
    if (!item) {
      return;
    }

    const built: IBuiltTreeItem = itemsMap[item.key];

    let parent: IBuiltTreeItem = built.parentElement;
    let current: IBuiltTreeItem = built;
    while (parent) {
      if (parent === built) {
        // предотвращение зацикливания
        breakRefs(parent, current);
        break;
      }
      // если передали в данных,
      // что родительский каталог выбран явно,
      // значит менять выделение ему не нужно
      if (
        !parentsPartialSelection &&
        item.selected === ETreeListViewItemSelected.SELECTED &&
        current.selected !== ETreeListViewItemSelected.SELECTED
      ) {
        switch (current.selected) {
          case ETreeListViewItemSelected.PARENT_PART_CHILD:
            current.selected = ETreeListViewItemSelected.SELECTED;
            break;
          case ETreeListViewItemSelected.PART_CHILD:
            current.selected = ETreeListViewItemSelected.NOT_SELECTED;
            break;
          default:
            break;
        }
      }
      current = parent;
      parent = parent.parentElement;
    }
  });

  const updated = updateCounters(tree);
  const flat = isItemsSorted ? flattenByData(data, itemsMap) : flatten(tree);

  return {
    tree: updated,
    flat,
    selectedItems,
    draggableItems,
    itemByIndex,
    itemByDraggableId,
  };
};

export const buildTree = memoizeOne(
  (data: ITreeItem[], parentsPartialSelection = true): IBuiltTreeItem[] =>
    treeBuilder(data, parentsPartialSelection)?.tree,
);

export const getVisibleTreeItems = (tree: ITreeItem[]): ITreeItem[] => {
  const nonCollapsed = tree
    .filter((treeItem) => !treeItem.collapsed && treeItem.hasChildren)
    .map((treeItem) => treeItem.key);
  return tree.filter(
    (treeItem) =>
      nonCollapsed.includes(treeItem.key) ||
      nonCollapsed.includes(treeItem.parent) ||
      !treeItem.parent,
  );
};

/**
 * Возвращает все видимые ключи элементов находящихся между двумя элементами,
 * включая начальный и конечный ключи.
 */
export function findBetweenKeys(
  firstKey: TreeListViewItemKey,
  secondKey: TreeListViewItemKey,
  tree: IBuiltTreeItem[],
): TreeListViewItemKey[] {
  let isFirstFind = false;
  let isSecondFind = false;
  const keys: TreeListViewItemKey[] = [];
  if (firstKey === secondKey) {
    keys.push(firstKey);
  } else {
    const pickingUpKeys = (levelItems: IBuiltTreeItem[]): void => {
      for (let i = 0; i < levelItems.length; i += 1) {
        const { key, children, collapsed } = levelItems[i];
        if (isFirstFind && isSecondFind) {
          break;
        } else if (key === firstKey) {
          isFirstFind = true;
          keys.push(key);
        } else if (key === secondKey) {
          isSecondFind = true;
          keys.push(key);
        } else if (isFirstFind || isSecondFind) {
          keys.push(key);
        }
        if (!collapsed && children?.length) {
          pickingUpKeys(children);
        }
      }
    };
    pickingUpKeys(tree);
  }
  return keys;
}

export function getFlatKeys(tree: IBuiltTreeItem[]): TreeListViewItemKey[] {
  const keys: TreeListViewItemKey[] = [];
  const pickingUpKeys = (levelItems: IBuiltTreeItem[]): void => {
    for (let i = 0; i < levelItems.length; i += 1) {
      const { key, children, collapsed } = levelItems[i];
      keys.push(key);
      if (!collapsed && children?.length) {
        pickingUpKeys(children);
      }
    }
  };
  pickingUpKeys(tree);
  return keys;
}

/**
 * Устанавливает выделение для всех элементов совпадающих с values. Для остальных снимает.
 *
 * @param items - Элементы дерева.
 * @param keys - Ключи выделяемых элементов.
 */
export function setSelection(items: ITreeItem[], keys: (string | number)[]): void {
  const keyMap: IMap<boolean> = {};
  keys.forEach((key) => {
    keyMap[key] = true;
  });
  items.forEach((item) => {
    item.selected = !!keyMap[item.key];
  });
}

/**
 * Функция клика в дереве TreeListView(в т.ч. для ДНД)
 * @param {TreeListViewItemKey} key Ключ дерева, на который совершён клик
 * @param {MouseEvent} event Событие клика
 * @param {ITreeItem[]} data Массив элементов дерева
 * @param {TreeListViewItemKey} focusedKey Элемент в фокусе
 * @param {(key: TreeListViewItemKey, keys: TreeListViewItemKey[], event: MouseEvent) => void} onItemClick Переданая функция клика по элементу
 * @param {IBuiltTreeItem[]} tree Массив элементов построенного дерева из data
 * @param item
 */
export function treeListItemClick(
  key: TreeListViewItemKey,
  event: MouseEvent,
  data: ITreeItem[],
  focusedKey: TreeListViewItemKey,
  onItemClick: (
    key: TreeListViewItemKey,
    keys: TreeListViewItemKey[],
    event: MouseEvent,
    data: ITreeItem,
  ) => void,
  tree: IBuiltTreeItem[],
  item: ITreeItem,
): void {
  if (!data.some((x) => x?.key === key && (x?.selectable ?? true))) return;
  const isKey = !!focusedKey || Number.isFinite(focusedKey as number);
  const isShift = event.getModifierState('Shift');
  const firstKey = isKey ? focusedKey : key;
  const keys = isShift ? findBetweenKeys(firstKey, key, tree) : [key];
  onItemClick(key, keys, event, item);
}

/** Возвращает признак выделения объекта */
export const getSelected = (item: ITreeItem) => {
  if (!item) return false;
  return typeof item.selected !== 'boolean'
    ? itemSelectedArray.includes(item.selected)
    : item.selected;
};

const keyboardEventMap = {
  [Keys.ArrowUp]: { code: Keys.ArrowUp },
  [Keys.ArrowDown]: { code: Keys.ArrowDown },
  [Keys.ArrowRight]: { code: Keys.ArrowRight, isToggle: true },
  [Keys.ArrowLeft]: { code: Keys.ArrowLeft, isToggle: true },
};

/** Перемещение по дереву через стрелки на клавиатуре
 * (влево и вправо раскрывают или скрывают дечерние элементы родителя)
 */
export const treeListKeyboardArrowDown = (
  event: any,
  data: ITreeItem[],
  tree: IBuiltTreeItem[],
  onSelect: (
    key: TreeListViewItemKey,
    keys: TreeListViewItemKey[],
    event: any,
    item: ITreeItem,
  ) => void,
  onItemToggle?: (key: TreeListViewItemKey) => void,
) => {
  const { code, isToggle } = keyboardEventMap[event.code] || {};
  if (code) {
    event.preventDefault();
    const selectedItem = data.find(
      (item) => item.selected === true || item.selected === ETreeListViewItemSelected.SELECTED,
    );
    if (selectedItem) {
      const { key } = selectedItem;
      if (isToggle) {
        onItemToggle?.(key);
        return;
      }
      const keys = getFlatKeys(tree);
      const index = keys.findIndex((item) => item === key);
      const nextKey = keys[index + 1] || key;
      const prevKey = keys[index - 1] || key;
      // TODO добавить в пропы проверку доступности выбора, или обработчик или настройку,
      // сейчас есть в ROS, например в источниках РО ограничения на выбор
      const newKey = code === Keys.ArrowDown ? nextKey : prevKey;
      const newItem = data.find((item) => item.key === newKey);
      onSelect(newKey, [newKey], event, newItem);
    }
  }
};

export const getTreeSelectedAndCollapsedState = (
  data: ITreeItem[],
): { selectedKeys: IMap<TreeListViewItemKey>; toggledCollapseKeys: IMap<TreeListViewItemKey> } => {
  const selectedKeys = {};
  const toggledCollapseKeys = {};
  data.forEach((item) => {
    if (item.selected) selectedKeys[item.key] = item.key;
    if (item.collapsed) toggledCollapseKeys[item.key] = item.key;
  });
  return { selectedKeys, toggledCollapseKeys };
};
