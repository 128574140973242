import * as React from 'react';
import { createPortal } from 'react-dom';
import Waiter from '@common/components/data/waiter/Waiter';
import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import Item from '@common/components/popups/context-menu-advance/ContextMenuAdvanceItem';
import { StyledHeader, StyledMenu } from '@common/components/popups/context-menu-advance/styled';
import {
  IContextMenuAdvanceItem,
  IContextMenuItemProps,
  IContextMenuMouseEventArgs,
  IContextMenuProps,
  IContextMenuWrapperProps,
  IContextSubMenuProps,
} from '@common/components/popups/context-menu-advance/types';
import { IMouseEventHandler } from '@common/types/mouseEvent';
import SubMenu from './ContextMenuAdvanceSubMenu';
import SplitSubMenu from './ContextMenuAdvanceSplitSubMenu';

const oldCssClass = 'react-contextmenu';
const oldCssClassItem = `${oldCssClass}-item`;

class ContextMenuAdvance<TActionType = number, TActionData = {}> extends React.PureComponent<
  IContextMenuProps<TActionType, TActionData>
> {
  public static Item: React.ComponentType<IContextMenuItemProps>;

  public static SubMenu: React.ComponentType<IContextSubMenuProps>;

  public static Wrapper: React.ComponentType<IContextMenuWrapperProps>;

  public static defaultProps: Partial<IContextMenuProps> = {
    saveFocus: true,
  };

  /** элемент, активный в момент срабатывания меню */
  public activeElement: HTMLElement;

  private hasIconsMemoized = memoizeOne(
    (items: IContextMenuAdvanceItem<TActionType, TActionData>[]): boolean =>
      items && items.findIndex((d) => d.iconPath) >= 0,
  );

  private usePortal: boolean = this.props.usePortal ?? true;

  private handleClick: IMouseEventHandler<IContextMenuMouseEventArgs<TActionType, TActionData>> = (
    event,
    args,
  ): void => {
    const { data } = this.props;
    data?.onItemClick?.(event, args);
    data?.onItemTypeClick?.(args.action?.type);
  };

  private getRecursiveMenu = (
    itemsData: IContextMenuAdvanceItem<TActionType, TActionData>[],
    buffer = [],
  ): JSX.Element[] => {
    const { showIconMargins, customization } = this.props;

    return itemsData.map((item, i) => {
      const key = `${i}.${item.action?.type}`;
      const isCheck = item.selected != null;
      if (!item?.divider) {
        buffer.push(item.disabled);
      }

      if (item.hidden) return null;
      const classes = classNames({
        [item.className]: !!item.className, // TODO: должно быть в самом низу, но в ContextMenuAdvanceItem добовляется __name к концу вместо нового класса.
        [oldCssClassItem]: true,
      });
      if (item.items) {
        const buffer = [];
        const children = this.getRecursiveMenu(item.items, buffer);
        const isDisabled = buffer.every((i) => i);
        const isSplit = !!children.length && item.action?.type != null;
        let showSubMenuIconMargins;
        if (showIconMargins) {
          showSubMenuIconMargins = this.hasIconsMemoized(item.items);
        }

        if (isSplit) {
          return (
            <SplitSubMenu
              key={key}
              data={{ action: item.action, item }}
              onClick={this.handleClick}
              titleClassName={classes}
              attributes={{ hidden: item.hidden }}
              title={item.caption}
              disabled={item.disabled || isDisabled}
              iconPath={item.iconPath}
              showIconMargins={showSubMenuIconMargins}
              hotkey={item.hotkey}
              selected={item.selected}
              isCheck={isCheck}
            >
              {children}
            </SplitSubMenu>
          );
        }

        return (
          <SubMenu
            key={key}
            data={{ action: item.action, item }}
            onClick={this.handleClick}
            titleClassName={classes}
            attributes={{ hidden: item.hidden }}
            title={item.caption}
            disabled={item.disabled || isDisabled}
            iconPath={item.iconPath}
            showIconMargins={showSubMenuIconMargins}
            preventCloseOnClick
          >
            {children}
          </SubMenu>
        );
      }

      if (item?.divider) {
        return !item.hidden && <Item key={key} divider />;
      }

      return (
        <Item
          key={key}
          data={{ action: item.action, item }}
          title={item.caption}
          tooltip={item.tooltip}
          onClick={this.handleClick}
          attributes={{ hidden: item.hidden }}
          disabled={item.disabled}
          iconPath={item.iconPath}
          className={classes}
          hotkey={item.hotkey}
          selected={item.selected}
          isCheck={isCheck}
          customization={customization}
          preventClose={item.preventClose}
        />
      );
    });
  };

  private onHandleShow = (event) => {
    // запоминаем активный элемент в момент открытия меню
    this.activeElement = document.activeElement as HTMLElement;
    this.props.onShow?.(event);
  };

  private onHandleHide = (event) => {
    if (this.props.saveFocus) {
      // восстанавливаем активный элемент в момент закрытия меню
      this.activeElement?.focus?.();
    }
    this.props.onHide?.(event);
  };

  private renderMenu = (): JSX.Element => {
    const { data, isLoading, className, showIconMargins, header, ...restProps } = this.props;
    const hasIcons = showIconMargins && data && this.hasIconsMemoized(data.items);

    return data ? (
      <StyledMenu
        className={className}
        hasIcons={hasIcons}
        {...restProps}
        onShow={this.onHandleShow}
        onHide={this.onHandleHide}
      >
        {header && (
          <StyledHeader
            className={`${oldCssClass}__header`}
            width={restProps.width}
            customization={restProps.customization?.header}
          >
            {header}
          </StyledHeader>
        )}
        {this.getRecursiveMenu(data.items)}
        <Waiter visible={isLoading} />
      </StyledMenu>
    ) : (
      <StyledMenu className={className} {...restProps} />
    );
  };

  public render(): JSX.Element {
    if (this.usePortal) {
      return createPortal(this.renderMenu(), document?.getElementById('root') || document.body);
    }
    return this.renderMenu();
  }
}

export default ContextMenuAdvance;
