import * as React from 'react';
import styled, { css } from 'styled-components';
import { getThemeValue } from '@themes/utils/getters';
import { ContextMenu, MenuItem, SubMenu } from 'react-contextmenu-fsght';
import ContextMenuAdvanceTitle, {
  ITitleProps,
} from '@common/components/popups/context-menu-advance/ContextMenuAdvanceTitle';
import {
  IContextMenuCustomization,
  IContextMenuHeaderCustomization,
  IContextMenuItemProps,
  IContextMenuProps,
  IContextSubMenuProps,
} from '@common/components/popups/context-menu-advance/types';

type TMenuProps = IContextMenuProps<any, any> &
  Pick<IContextMenuProps, 'customization'> & {
    width?: number;
    hasIcons?: boolean;
  };
const ItemClassName = `.react-contextmenu-item`;
const MenuClassName = `.react-contextmenu`;
const SubMenuClassName = `.react-contextmenu-submenu`;

/** Item */
export const StyledCheckIcon = styled.span<IContextMenuItemProps>`
  flex-shrink: 0;
  display: flex;
  margin-left: 12px;
  visibility: hidden;
  > svg {
    color: #93989e;
    /** max-width: 100% - дает неверное поведение при переполнении элемента меню */
    max-width: none;
  }
  ${(props) =>
    !props?.disabled &&
    css`
      visibility: visible;
    `}
`;

const getItemStyles = (customization?: IContextMenuCustomization) => css`
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  max-width: 400px; // @maxItemWidth;
  height: 32px;
  padding: 6px 16px;
  // в будущем context-menu-advance должен строится на list-view
  white-space: nowrap;
  text-align: inherit;
  outline: none;
  cursor: pointer;
  .react-contextmenu-item__name {
    flex: 1;
    min-width: 0;
    user-select: none;
  }
  &:hover {
    background-color: ${customization?.hoverBackground || '#f0f2fc'};
    transition: background-color 100ms;
    & > ${StyledCheckIcon} {
      visibility: visible;
    }
  }
  &--active,
  &--selected {
    color: #3b5edc;
    background-color: #f0f2fc;
  }

  &--disabled,
  &--disabled:hover {
    color: #c4c4c4;
    background-color: transparent;
    cursor: default;
  }

  &--disabled:hover &__check-icon,
  &--disabled:hover & .check-icon {
    visibility: hidden;
  }

  &[hidden] {
    display: none;
  }

  &--divider {
    // for divider
    height: 1px;
    padding: 0;
    background-color: #c4c4c4; // @divider-color;
    pointer-events: none;
  }

  &--disabled,
  &--disabled .icon,
  &--disabled:hover,
  &--disabled:hover .icon {
    color: #93989e;
    svg {
      color: #93989e;
    }
  }

  ${customization?.wordWrap &&
  css`
    white-space: break-spaces;
    text-overflow: initial;
    min-height: 32px;
    height: initial;
    align-items: flex-start;
  `}
`;

const getMenuStyles = ({ width, hasIcons, customization }: Partial<TMenuProps>) => css`
  min-width: 160px;
  color: #222b3c; // @txt-color
  font-size: 14px; // @font-size-secondary
  text-align: left;
  background-color: #ffffff; // @bg-color
  border-radius: ${getThemeValue.shapeBorderRadius};
  outline: none;
  box-shadow: 0 2px 8px #00000026, 0 0 2px #00000040;
  visibility: hidden;
  opacity: 0; // @no-opacity
  pointer-events: none;

  &:empty {
    display: none;
  }

  // react-contextmenu по дефолту выставлят для .react-contextmenu--visible z-index: 1000. Повторяется только на проде.
  &--visible {
    z-index: 20000; // Обязателен, т.к. лежит в DOM изначально на одном месте. Появившийся Dropdown перекроет меню.
    visibility: visible;
    opacity: 1;
    transition-duration: 250ms;
    transition-property: opacity, visibility;
    pointer-events: auto;
  }

  &_not-portal {
    position: relative !important;
    box-shadow: none !important;
    visibility: initial !important;
    opacity: 1 !important;
    pointer-events: auto !important;
  }

  // используется только в platform/components/data/dimension-tree/index.tsx
  &-contracted {
    min-width: 240px;
    .react-contextmenu-item {
      align-items: center;
    }
    &_wide {
      min-width: 320px;
    }
  }
  ${width &&
  css`
    .react-contextmenu-item {
      width: ${width}px;
    }
  `}
  ${hasIcons &&
  css`
    > .react-contextmenu-item > .react-contextmenu-item__name,
    > .react-contextmenu-submenu > .react-contextmenu-item > .react-contextmenu-item__name {
      margin-left: 24px;
    }
    > .react-contextmenu-item > .react-contextmenu-item__icon,
    > .react-contextmenu-submenu > .react-contextmenu-item > .react-contextmenu-item__icon {
      margin-right: -16px;
    }
  `}
  ${Number.isInteger(customization?.round) &&
  css`
    border-radius: ${customization.round}px;
  `}
  ${customization?.shadow === false &&
  css`
    box-shadow: none;
  `}
  ${customization?.background &&
  css`
    background: ${customization.background};
  `}
`;

export const StyledItem = styled(MenuItem)<IContextMenuItemProps>`
  &,
  &.react-contextmenu-item {
    ${(props) => getItemStyles(props.customization)}
  }

  & .ui-hint-trigger {
    overflow: hidden;
    flex-grow: 1;
  }
`;
export const StyledItemIcon = styled.span<IContextMenuItemProps>`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  max-width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 8px;
  svg {
    color: #54575b; // @icon-color;
  }
`;
export const StyledHotkey = styled.span<IContextMenuItemProps>`
  flex-shrink: 0;
  margin-left: 12px;
  color: #93989e; // @placeholder-color;
`;
/** TODO: Эти классы не применяются */
export const StyledTitle = styled(ContextMenuAdvanceTitle)<ITitleProps>`
  flex: 1;
  min-width: 0;
  user-select: none;
  .ui-hint-trigger {
    font-size: 14px;
  }
`;
/** Menu */
export const StyledMenu = styled(ContextMenu)<TMenuProps>`
  &,
  &${MenuClassName} {
    ${(props) => getMenuStyles(props)}
  }
`;
const SubMenuWithRootClassName: React.FC<IContextSubMenuProps> = (props) => (
  <SubMenu {...props} rootClassName={props.className} />
);
/** SubMenu */
export const StyledSubMenu = styled(SubMenuWithRootClassName)<IContextSubMenuProps>`
  &${ItemClassName}${SubMenuClassName} {
    ${getItemStyles()}
    padding: 0;
    background: none;    
  }

  &${SubMenuClassName} > ${ItemClassName} {
      ${getItemStyles()}
      justify-content: space-between;

      &--active,
      &--selected {
        color: inherit;
      }

      .ui-svg-icon {
        flex-shrink: 0;

        &.arrow-right {
          min-width: 24px;
          margin-left: 8px;
        }
      }
      ${(props) => props.splitPadding && 'padding: 4px 12px 4px 0'}
    }
    ${MenuClassName} {
      ${(props) => getMenuStyles(props as any)}
    }
  }
`;
/** SplitSubMenu */
export const StyledWrapperSplitSubMenu = styled.div`
  display: flex;
`;

export const StyledSplitSubMenuDivider = styled.div`
  margin: 4px 8px;
  width: 1px;
  height: inherit;
  background: ${getThemeValue.paletteLineNormal};
`;

export const StyledHeader = styled.div<{
  width: number;
  customization: IContextMenuHeaderCustomization;
}>`
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  ${({ customization }) => css`
    padding: ${customization?.padding || `6px 16px`};
    color: ${customization?.color};
    background: ${customization?.background || getThemeValue.paletteBgDisabled};
    font-weight: ${customization?.fontWeight || `600`};
    font-size: ${customization?.fontSize}px;
    line-height: ${customization?.lineHeight}px;
  `}
`;
