import * as _ from 'lodash';
import Emitter from '@common/utils/emitter';

export enum EStorageType {
  session = 'session',
  local = 'local',
}
export const StorageTypeMap = {
  [EStorageType.local]: localStorage,
  [EStorageType.session]: sessionStorage,
};

const getItem = <T>(key: string, type: EStorageType = EStorageType.local): T => {
  const value = StorageTypeMap[type].getItem(key);
  if (!value) return undefined;
  try {
    return JSON.parse(value) as T;
  } catch (e) {
    return undefined;
  }
};

const concatString = (name: string, key: any) => `${name}-${key}`;

const setItem = <T>(key: string, value: T, type: EStorageType = EStorageType.local): void => {
  StorageTypeMap[type].setItem(key, JSON.stringify(value));
};

export type TAnyListener = (eventData: any) => void | Promise<void>;
type TAnyNativeListener = EventListenerOrEventListenerObject;

export const StorageManager = <T extends object>(
  name: string,
  type: EStorageType = EStorageType.local,
) => {
  const emitter = new Emitter();
  const get = <K extends keyof T>(key: K): T[K] => getItem(concatString(name, key), type);
  const set = <K extends keyof T>(key: K, value: T[K]) => {
    const conKey = concatString(name, key);
    setItem(conKey, value, type);
    emitter.emit(conKey);
  };
  const update = <K extends keyof T>(key: K, value: T[K], deep = true) => {
    const prevItem = get(key) || {};
    const merged = deep ? _.merge({}, prevItem, value) : { ...prevItem, ...value };
    set(key, merged);
  };
  const anyListeners = new Map<TAnyListener, TAnyNativeListener>();
  const on = <K extends keyof T>(key: K, listener) => {
    const conKey = `${name}-${String(key)}`;
    const nativeListener: TAnyNativeListener = (event: StorageEvent) => {
      if (event.key === conKey && StorageTypeMap[type] === event.storageArea) {
        listener(event);
      }
    };
    emitter.on(conKey, listener);
    window.addEventListener('storage', nativeListener);
    anyListeners.set(listener, nativeListener);
  };
  const off = <K extends keyof T>(key: K, listener) => {
    const conKey = `${name}-${String(key)}`;
    const found = anyListeners.get(listener);
    if (found) {
      window.removeEventListener('storage', found);
      anyListeners.delete(listener);
    }
    emitter.off(conKey, listener);
  };
  const init = (init?: T) => {
    Object.keys(init).forEach((key: any) => {
      const current = get(key);
      if (current == null) set(key, init[key]);
    });
  };

  return {
    init,
    get,
    set,
    update,
    on,
    off,
  };
};

export default {
  getItem,
  setItem,
  StorageManager,
};
