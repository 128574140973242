import * as React from 'react';
import { ITreeItem } from '@common/components/data/tree-list-view/TreeListViewModel';
import { Theme } from '@themes/components/ThemeProvider';
import NavigationPanel from '..';
import { StyledCopyright, StyledWrapper } from './styledWrapper';
import { IPanelWrapperProps } from './types';

const NavigationPanelWrapper: React.FC<IPanelWrapperProps> = (props) => {
  // TODO Сейчас достаём путь из тем. Как подключим кастомные иконки в темы - можно это всё убрать
  // eslint-disable-next-line dot-notation
  const themePath: string = Theme?.themesPath;
  const imagesPath = themePath.replace(/\/themes$/, '/images');

  const parentIcon = `${imagesPath}/ParentIcon.svg`;
  const ChildIcon = `${imagesPath}/ChildIcon.svg`;
  const parentRowHeight = 56;
  const childenRowHeight = 32;

  const {
    selectedKey,
    metadata,
    onSend,
    mainLinkIcon,
    mainLinkIconMini,
    collapsed,
    showIcons = true,
    hideChildIcons = false,
  } = props;
  const { navListItems } = metadata?.navList ?? {};
  // если нет иконок, то панель должна свернуться полностью
  const hidePanel = !showIcons && collapsed;

  const list = React.useMemo(() => {
    // выставляем порядок элементов
    navListItems.sort((a, b) => a.listItemOrd - b.listItemOrd);
    return navListItems.reduce((acc: ITreeItem[], item) => {
      const isParent = item.parentKey === 0;
      const icon = item._listItemIcon || (isParent ? parentIcon : ChildIcon);
      acc.push({
        parent: item.parentKey || '',
        key: item.key,
        icon,
        caption: item.listItemTitle,
        itemSettings: {
          childrenPadding: 0,
          rowHeight: isParent ? parentRowHeight : childenRowHeight,
        },
      });
      return acc;
    }, []);
  }, [navListItems, parentIcon, ChildIcon]);

  const selectItem = React.useCallback(
    (key: number) => {
      onSend(null, { key });
    },
    [onSend],
  );

  const copyright = React.useMemo(() => {
    const currentYear = new Date().getFullYear();
    return `© ${currentYear}  Foresight`;
  }, []);

  return (
    <StyledWrapper hidePanel={hidePanel}>
      <NavigationPanel
        list={list}
        logoIcon={mainLinkIcon}
        logoIconMini={mainLinkIconMini}
        selectItem={selectItem}
        collapsed={collapsed}
        visibleCollapsedButton={false}
        selectedKey={selectedKey}
        hideTreeIcons={!showIcons}
        hideChildIcons={hideChildIcons}
        imagesPath={imagesPath}
      />
      {!collapsed && <StyledCopyright>{copyright}</StyledCopyright>}
    </StyledWrapper>
  );
};

export default NavigationPanelWrapper;
