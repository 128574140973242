import * as React from 'react';
import TooltipTrigger from 'react-popper-tooltip';
import classNames from 'classnames';
import { IHintProps } from '@common/components/popups/hint/types';
import { mainCls, triggerCls } from '@common/components/popups/hint/constants';
import { TOP_ZINDEX } from '@common/constants/layers';

// TODO:
// eslint-disable-next-line import/no-unresolved
import { TriggerTypes } from 'react-popper-tooltip/dist/types';

import './Hint.less';

const preventEvent = (e): void => e.stopPropagation();

const Hint: React.FC<IHintProps> = ({
  children,
  tooltip,
  withArrow,
  triggerClassName,
  tooltipClassName,
  mode,
  color,
  trigger,
  position,
  disabled,
  zoom,
  onTooltipClick,
  showTimeout = 200,
  isBlockStyle,
  isTriggerTextEllipsis,
  shown = false,
  integrated,
  toTop,
  maxWidth,
}) => {
  const [controlledTooltipShow, setControlledTooltipShow] = React.useState(shown);

  if (mode === 'tooltip') {
    position = 'bottom';
    withArrow = true;
  }
  const tooltipClasses = classNames({
    [mainCls]: true,
    [`${mainCls}_${mode}`]: mode,
    [`${mainCls}_${color}`]: color,
    [tooltipClassName]: tooltipClassName,
  });

  const triggerClasses = classNames(triggerCls, {
    [`${triggerCls}__block`]: isBlockStyle,
    [`${triggerCls}__text`]: isTriggerTextEllipsis,
    [`${triggerCls}__integrated`]: integrated,
    [triggerClassName]: triggerClassName,
  });

  const isCorrectTooltip = (tooltip && tooltip !== true) || tooltip === 0;
  const calcTrigger: TriggerTypes = disabled ? 'none' : trigger;

  React.useEffect(() => {
    setControlledTooltipShow(shown);
  }, [shown]);

  const renderTooltip = React.useCallback(
    ({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }): JSX.Element => {
      const tooltipStyle: React.CSSProperties = { zoom, maxWidth };
      if (toTop) {
        tooltipStyle.zIndex = TOP_ZINDEX;
      }
      return (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: tooltipClasses,
            style: tooltipStyle,
          })}
          onClick={onTooltipClick || preventEvent}
        >
          {withArrow && isCorrectTooltip && (
            <div
              {...getArrowProps({
                ref: arrowRef,
                className: `${mainCls}__arrow`,
                'data-placement': placement,
              })}
            />
          )}
          {tooltip}
        </div>
      );
    },
    [tooltipClasses, zoom, toTop, withArrow, isCorrectTooltip, tooltip, onTooltipClick],
  );

  const renderTooltipTrigger = React.useCallback(
    ({ getTriggerProps, triggerRef }): JSX.Element => {
      const triggerProps = getTriggerProps({
        ref: triggerRef,
        className: triggerClasses,
      });
      return <span {...triggerProps}>{children}</span>;
    },
    [triggerClasses, children],
  );

  // На кнопках с атрибутом disabled не срабатывает onMouseLeave,
  // поэтому используем другие функции для отслеживания действий
  const childrenJsxElement = children && (children as JSX.Element);
  const isControlledComponent =
    childrenJsxElement &&
    childrenJsxElement.props &&
    childrenJsxElement.props.type === 'button' &&
    childrenJsxElement.props.disabled;

  const renderControlledTooltipTrigger = React.useCallback(
    ({ getTriggerProps, triggerRef }): JSX.Element => {
      let delayShow;
      const onPointerEnter = () => {
        delayShow = setTimeout(() => setControlledTooltipShow(true), showTimeout);
      };
      const onPointerLeave = () => {
        if (shown) {
          return;
        }
        clearTimeout(delayShow);
        setControlledTooltipShow(false);
      };
      return (
        <span
          {...getTriggerProps({
            ref: triggerRef,
            className: triggerClasses,
            onPointerEnter,
            onPointerLeave,
          })}
        >
          {children}
        </span>
      );
    },
    [triggerClasses, children, showTimeout],
  );

  return isControlledComponent ? (
    <TooltipTrigger
      tooltipShown={controlledTooltipShow}
      trigger={calcTrigger}
      placement={position}
      tooltip={renderTooltip}
    >
      {renderControlledTooltipTrigger}
    </TooltipTrigger>
  ) : (
    <TooltipTrigger
      delayShow={showTimeout}
      trigger={calcTrigger}
      placement={position}
      tooltip={renderTooltip}
    >
      {renderTooltipTrigger}
    </TooltipTrigger>
  );
};

Hint.defaultProps = {
  position: 'top',
  withArrow: false,
  color: 'black',
  mode: 'hint',
  disabled: false,
  maxWidth: '320px',
};

export default Hint;
