import styled from 'styled-components';
import { getThemeValue } from '@themes/utils/getters';

export const StyledWrapper = styled.div<{ hidePanel: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ hidePanel }) => (hidePanel ? '0' : '100%')};
  overflow: hidden;

  .ui-tree-list-view__wrapper {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;

    .tree-list-view-item_root {
      border-top: 1px solid ${getThemeValue.paletteLineNormal};
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const StyledCopyright = styled.div`
  height: 48px;
  width: 100%;
  flex: none;
  color: #aeaeae;
  padding: 18px;
`;
