import ButtonBase from '@common/components/controls/ButtonBase';
import { Icon } from '@themes';
import { EThemeControlSize } from '@themes/styles/size';
import { getThemeValue } from '@themes/utils/getters';
import { paletteGetters } from '@themes/utils/getters/palette';
import {
  disabledBackground,
  disabledColor,
  disableIconButton,
  focusColor,
} from '@themes/utils/pseudo-classes';
import styled, { css } from 'styled-components';
import Box from '@common/components/layout/box';
import { IStyledButtonProps } from './types';

const createSizeStyles = () => css<IStyledButtonProps>`
  min-width: ${(props) => getThemeValue.sizeControl(props, props.size)};
  height: ${(props) => getThemeValue.sizeControl(props, props.size)};
  line-height: ${(props) => getThemeValue.sizeControl(props, props.size)};
  // TYPO
  ${(props) => props.theme.typography?.button[props.size]}
  // WIDTH
  ${(props) => {
    if (props.width === 'full') {
      return css`
        width: 100%;
      `;
    }
    if (Number.isInteger(props.width)) {
      return css`
        width: ${props.width}px;
      `;
    }
    return undefined;
  }}
  // HEIGHT
  ${(props) => {
    if (props.height === 'full') {
      return css`
        height: 100%;
      `;
    }
    if (Number.isInteger(props.height)) {
      return css`
        height: ${props.height}px;
      `;
    }
    return undefined;
  }}

  ${(props) =>
    props.size === EThemeControlSize.S &&
    css`
      padding: 0 8px;
    `}
  ${(props) =>
    props.size === EThemeControlSize.M &&
    css`
      padding: 0 12px;
    `}
  ${(props) =>
    props.size === EThemeControlSize.L &&
    css`
      padding: 0 16px;
    `}
`;

const createVariantStyles = () => css<IStyledButtonProps>`
  ${(props) =>
    props.variant === 'primary' &&
    css<IStyledButtonProps>`
      color: ${getThemeValue.paletteColors.white};
      background-color: ${getThemeValue.paletteColors.main};
      &:hover {
        background-color: ${getThemeValue.paletteBgShapeHover};
      }
      &:active {
        background-color: ${getThemeValue.paletteBgShapeActive};
      }
      &:disabled {
        background-color: ${getThemeValue.paletteColorsWithBright.main[-8]} !important;
      }
      ${focusColor}
    `}

  ${(props) =>
    props.variant === 'secondary' &&
    css<IStyledButtonProps>`
      color: ${getThemeValue.paletteTextPrimary};
      background-color: ${getThemeValue.paletteColorsWithBright.dark[-7]};
      &:hover {
        background-color: ${getThemeValue.paletteColorsWithBright.dark[-5]};
      }
      &:active {
        background-color: ${getThemeValue.paletteColorsWithBright.dark[-2]};
      }
      &:disabled {
        background-color: ${getThemeValue.paletteColorsWithBright.dark[-9]} !important;
      }
      ${disabledColor}
      ${focusColor}
    `}

  ${(props) =>
    props.variant === 'ghost' &&
    css<IStyledButtonProps>`
      color: ${paletteGetters.paletteTextPrimary};
      &:hover {
        background-color: ${getThemeValue.paletteColorsWithBright.dark[-5]};
        color: ${getThemeValue.paletteTextPrimary};
        &:disabled {
          background-color: transparent;
        }
      }
      &:active {
        background-color: ${getThemeValue.paletteColorsWithBright.dark[-2]};
        color: ${getThemeValue.paletteTextPrimary};
      }
      ${disabledColor}
      ${focusColor}
    `}

  ${(props) =>
    props.variant === 'success' &&
    css<IStyledButtonProps>`
      color: ${getThemeValue.paletteColors.white};
      background-color: ${getThemeValue.paletteColorsWithBright.green[1]};
      &:hover {
        background-color: ${getThemeValue.paletteColorsWithBright.green[2]};
      }
      &:active {
        background-color: ${getThemeValue.paletteColorsWithBright.green[4]};
      }
      &:disabled {
        background-color: ${getThemeValue.paletteColorsWithBright.green[-7]} !important;
      }
      ${focusColor}
    `}

  ${(props) =>
    props.variant === 'icon' &&
    css<IStyledButtonProps>`
      color: ${paletteGetters.paletteTextPrimary};

      &:hover {
        color: ${getThemeValue.paletteIconHover};
      }
      &:active {
        color: ${getThemeValue.paletteIconActive};
      }
      ${disableIconButton}

      ${props.active &&
      css`
        color: ${getThemeValue.paletteIconHover};
        ${disableIconButton}
      `}
      
      ${props.pressed &&
      css`
        color: ${getThemeValue.paletteIconPressed};
        ${disableIconButton}
      `}
    `}

  ${(props) =>
    props.variant === 'toggle' &&
    css<IStyledButtonProps>`
      color: ${paletteGetters.paletteIconNormal};

      &:hover {
        background-color: ${getThemeValue.paletteBgMenuHover};
        color: ${getThemeValue.paletteIconHover};
      }
      &:active {
        background-color: ${getThemeValue.paletteBgMenuHover};
        color: ${getThemeValue.paletteIconPressed};
        ${disabledBackground}
      }
      &:disabled {
        background-color: transparent !important;
      }
      ${disableIconButton}

      ${props.active &&
      css`
        background-color: ${getThemeValue.paletteBgMenuActive};
        color: ${getThemeValue.paletteIconActive};
        ${disableIconButton}
      `}
    `}

  ${(props) =>
    props.variant === 'outline' &&
    css<IStyledButtonProps>`
      border: 1px solid ${paletteGetters.paletteBorderDefault};
      color: ${getThemeValue.paletteTextPrimary};

      &:hover {
        border-color: ${paletteGetters.paletteBorderHover};
      }
      &:active {
        background-color: ${getThemeValue.paletteColorsWithBright.dark[-2]};
        border-color: ${paletteGetters.paletteBorderDefault};
      }
      &:disabled {
        background-color: ${getThemeValue.paletteColorsWithBright.dark[-9]};
        border-color: ${getThemeValue.paletteBorderDisabled};
        color: ${getThemeValue.paletteTextDisabled};
      }

      ${focusColor}
    `}
`;

const indentMap = {
  [EThemeControlSize.L]: 8,
  [EThemeControlSize.M]: 8,
  [EThemeControlSize.S]: 4,
  [EThemeControlSize.XS]: 4,
};

export const StyledButton = styled(ButtonBase)<IStyledButtonProps>`
  // CONTENT
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;

  // SIZE
  ${createSizeStyles()}
  // COLORS
  ${createVariantStyles()}
  
  // ICON
  ${(props) =>
    props.onlyIcon &&
    css`
      padding: 0;
    `}

  // indent
  ${(props) =>
    css`
      column-gap: ${indentMap[props.size]}px;
    `}
`;

export const StyledButtonLabel = styled.span<{ iconCentering?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  // центрируем иконки
  ${(props) =>
    props.iconCentering &&
    css`
      flex-grow: initial;
    `}
  flex-shrink: 1;
  font-family: inherit; // Наследуем шрифт, чтобы можно было менять через проп style.
`;

export const StyledButtonIconWrapper = styled.span`
  display: block;
  flex-shrink: 0;
  line-height: 0;
`;

export const StyledButtonContentWrapper = styled(Box)`
  flex-shrink: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const StyledIcon = styled(Icon)``;
