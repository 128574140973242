import * as React from 'react';
import { Body, Head, Wrapper } from './styled';
import Logo from './logo';
import List from './list';
import { useClasses } from './classes';
import { INavigationPanelProps } from './types';

const NavigationPanel: React.FC<INavigationPanelProps> = (props) => {
  const {
    collapsed,
    list,
    selectItem,
    logoIcon,
    logoIconMini,
    nonClickableLogo,
    visibleCollapsedButton,
    selectedKey,
    hideTreeIcons = false,
    hideChildIcons = false,
    imagesPath,
    integrated = true,
  } = props;
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(collapsed);

  const slots = useClasses(props);

  React.useEffect(() => {
    if (!visibleCollapsedButton) {
      setIsCollapsed(collapsed);
    }
  }, [collapsed]);

  // const handleCollapsed = React.useCallback(() => {
  //   setIsCollapsed(!isCollapsed);
  // }, [setIsCollapsed, isCollapsed]);

  return (
    <Wrapper className={slots.root} collapsed={isCollapsed} integrated={integrated}>
      <Head className={slots.head}>
        <Logo
          className={slots.logo}
          isCollapsed={isCollapsed}
          logo={logoIcon}
          logoCollapsed={logoIconMini}
          onClick={nonClickableLogo ? null : selectItem}
        />
      </Head>
      <Body className={slots.body} collapsed={isCollapsed}>
        <List
          className={slots.list}
          isCollapsed={isCollapsed}
          list={list}
          selectItem={selectItem}
          selectedKey={selectedKey}
          hideTreeIcons={hideTreeIcons}
          hideChildIcons={hideChildIcons}
          imagesPath={imagesPath}
        />
      </Body>
      {/* Пока на кнопку нет дизайна */}
      {/* {visibleCollapsedButton && (
        <Footer className={slots.footer}>
          <CollapseButton
            className={slots.button}
            onClick={handleCollapsed}
            size={EThemeControlSize.L}
            onlyIcon
            variant="ghost"
            width="full"
            caption={!isCollapsed ? 'Свернуть' : 'Развернуть'}
            iconLeft={!isCollapsed ? EIcon.ActionsArrowLeft : EIcon.ActionsArrowRight}
          />
        </Footer>
      )} */}
    </Wrapper>
  );
};

export default NavigationPanel;
