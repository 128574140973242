import * as React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@themes';

const contructorImgSmall = require('./logo-mini.png');
const contructorImgFull = require('./logo-full.png');

interface IProps {
  isCollapsed: boolean;
  logoCollapsed?: string;
  logo?: string;
  className?: string;
  onClick?: (key: number) => void;
}

const StyledLogo = styled.div<{ nonClickable: boolean }>`
  font-style: normal;
  height: 100%;

  ${({ nonClickable }) =>
    nonClickable &&
    css`
      pointer-events: none;
    `}

  &:hover {
    cursor: pointer;
  }
`;

const StyledLogoIcon = styled(Icon)<{ visible: boolean }>`
  display: block;
  ${(props) => !props.visible && 'display: none;'}
`;

const Logo: React.FC<IProps> = ({ isCollapsed, logo, logoCollapsed, className, onClick }) => {
  const collapsedSrc = logoCollapsed || contructorImgSmall;
  const expandedSrc = logo || contructorImgFull;
  const logoSrc = isCollapsed ? collapsedSrc : expandedSrc;

  const handleClick = React.useCallback(() => {
    onClick?.(-1);
  }, [onClick]);

  return (
    <StyledLogo className={className} onClick={handleClick} nonClickable={Boolean(!onClick)}>
      <StyledLogoIcon icon={logoSrc} visible />
    </StyledLogo>
  );
};
export default Logo;
