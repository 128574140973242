import * as React from 'react';
import cn from 'classnames';
import { waiterCls } from './constants';
import './Waiter.less';

export interface IProps {
  className?: string;
  /** показать / скрыть */
  visible?: boolean;
  relative?: boolean;
  style?: React.CSSProperties;
  /** задержка перед отображением (в мс) */
  delay?: number;
}

const css = (props: IProps): React.CSSProperties => ({
  position: props.relative ? 'relative' : 'absolute',
  ...props.style,
});

const Waiter: React.FC<IProps> = ({ className, visible, relative, style, delay = 1000 }) => {
  const waiterTimeout = React.useRef(null);

  const [isVisible, setIsVisible] = React.useState(false);

  const setWaiterTimeout = () => {
    waiterTimeout.current = setTimeout(() => setIsVisible(true), delay);
  };

  const clearWaiterTimeout = () => {
    clearTimeout(waiterTimeout.current);
    waiterTimeout.current = null;
    setIsVisible(false);
  };

  React.useEffect(() => {
    if (visible && !waiterTimeout.current) setWaiterTimeout();
    if (!visible && waiterTimeout.current) clearWaiterTimeout();
    return () => {
      clearTimeout(waiterTimeout.current);
    };
  }, [visible]);

  return isVisible ? (
    <div className={cn(waiterCls, className)} style={css({ relative, style })} />
  ) : null;
};

export default Waiter;
