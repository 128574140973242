import { EThemeControlSize } from '@themes';
import classNames from 'classnames';
import * as React from 'react';
import { prefix, useClasses } from './classes';
import StyledButton from './StyledButton';
import { IIconButtonProps } from './types';

const IconButton: React.FC<IIconButtonProps> = (props) => {
  const size = props.size ?? EThemeControlSize.M;
  const slotClasses = useClasses();
  const classes = classNames(prefix, slotClasses.root, {
    [slotClasses.pressed]: props.pressed,
    [slotClasses.withBackground]: props.useButtonBackground,
  });
  return <StyledButton className={classes} {...props} size={size} />;
};

IconButton.defaultProps = {
  title: '',
};

export default IconButton;
