import * as React from 'react';
import useButtonMenu from '@common/components/controls/button-dropdown/useButtonMenu';
import ContextMenuAdvance from '@common/components/popups/context-menu-advance';
import { componentName, useClasses } from '@common/components/controls/button-dropdown/classes';
import cn from 'classnames';
import {
  StyledBox,
  StyledButton,
  StyledArrowButton,
  StyledDividedModeButton,
} from '@common/components/controls/button-dropdown/StyledButtonDropdown';
import withComponentThemeProps from '@themes/utils/props/withComponentThemeProps';
import { EButtonDropdownVariant, IButtonDropdownProps } from './types';

export const ButtonDropdownComponent: React.FC<IButtonDropdownProps> = (props) => {
  const {
    className,
    buttonClassName,
    inlineFlex,
    isDividedMode,
    onClick,
    contextMenuWidth,
    customization,
    hideOnLeave,
    saveFocus,
    testId,
    ...btnProps
  } = props;
  const slots = useClasses(props);
  delete btnProps.onItemClick;
  delete btnProps.onShow;
  delete btnProps.onHide;
  delete btnProps.onItemTypeClick;
  const { isMenuOpened, handleClick, leaveHandler, ...menu } = useButtonMenu(props, slots);

  return (
    <StyledBox className={cn(slots.root, className)} ref={menu.container} inlineFlex={inlineFlex}>
      {isDividedMode && onClick ? (
        <StyledDividedModeButton isDefThemeVariant={menu.isDefThemeVariant}>
          <StyledButton
            {...btnProps}
            onClick={onClick}
            onMouseLeave={leaveHandler}
            activeColor={customization?.background}
            active={isMenuOpened}
            testId={testId}
          />
          <StyledArrowButton
            caption={menu.menuButtonCaption}
            testId={testId && `${testId}-arrow-button`}
            variant={btnProps.variant}
            size={btnProps.size}
            onlyIcon
            className={cn(slots.button, buttonClassName)}
            onClick={handleClick}
            onMouseOver={menu.handleMouseOver}
            {...menu.buttonIcon}
          />
        </StyledDividedModeButton>
      ) : (
        <StyledButton
          {...btnProps}
          className={cn(slots.button, buttonClassName)}
          onClick={handleClick}
          onMouseOver={menu.handleMouseOver}
          onMouseLeave={leaveHandler}
          {...menu.buttonIcon}
          activeColor={customization?.background}
          active={isMenuOpened}
          testId={testId}
        />
      )}
      <ContextMenuAdvance
        id={menu.menuId}
        className={menu.uniqueMenuClass}
        data={menu.menuData}
        onShow={menu.showHandler}
        width={contextMenuWidth}
        header={menu.menuHeader}
        customization={customization}
        onHide={menu.hideHandler}
        hideOnLeave={hideOnLeave}
        saveFocus={saveFocus}
      />
    </StyledBox>
  );
};

ButtonDropdownComponent.defaultProps = {
  displayVariant: EButtonDropdownVariant.variant1,
  position: 'bottom',
};

const ButtonDropdown = withComponentThemeProps(componentName)(ButtonDropdownComponent);
export default ButtonDropdown;
