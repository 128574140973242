import { generateClass } from '@themes/utils/classes/index';

export default function composeClasses<ClassKey extends string>(
  slots: Record<ClassKey, ReadonlyArray<string | false | undefined | null>>,
  prefix: string,
  classes: Record<string, string> | undefined = {},
): Record<ClassKey, string> {
  const output: Record<ClassKey, string> = {} as any;

  Object.keys(slots).forEach((slot: ClassKey) => {
    output[slot] = slots[slot]
      .reduce((acc, key) => {
        if (key) {
          acc.push(generateClass(prefix, key));
          if (classes && classes[key]) {
            acc.push(classes[key]);
          }
        }
        return acc;
      }, [] as string[])
      .join(' ');
  });

  return output;
}
