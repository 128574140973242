import { showMenu as _showMenu, hideMenu as _hideMenu } from 'react-contextmenu-fsght';
import ContextMenuAdvanceItem from '@common/components/popups/context-menu-advance/ContextMenuAdvanceItem';
import ContextMenuAdvanceWrapper from '@common/components/popups/context-menu-advance/ContextMenuAdvanceWrapper';
import ContextMenuAdvanceSubMenu from '@common/components/popups/context-menu-advance/ContextMenuAdvanceSubMenu';
import { IContextMenuAdvanceItem as IContextMenuAdvanceItemE } from '@common/components/popups/context-menu-advance/types';
import ContextMenuAdvance from './ContextMenuAdvance';

export type IContextMenuAdvanceItem<T = number, K = {}, A = {}> = IContextMenuAdvanceItemE<T, K, A>;

ContextMenuAdvance.Wrapper = ContextMenuAdvanceWrapper;
ContextMenuAdvance.Item = ContextMenuAdvanceItem;
ContextMenuAdvance.SubMenu = ContextMenuAdvanceSubMenu;
export const showMenu = (...args) => _showMenu(...args);
export const hideMenu = (...args) => _hideMenu(...args);

export default ContextMenuAdvance;
