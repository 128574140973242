enum RelayStatus {
  Success = 'success',
  Pending = 'pending',
  Error = 'error',
}

type RelayState<T> = {
  status: RelayStatus;
  data: T;
  errorInfo: any;
};

/** Работа Suspense заключается в отслеживании исключения (в основе лежит ErrorBoundary). а механизм общения - RelayAPI.
 * Suspense отображает "fallback" до тех пор пока дочерние компоненты не перестанут "слать исключения".
 * Дочерний компонент может отправить через SEH (throw) одно из двух типов исключений:
 1. Promise
 2. Объект ошибки
 * */

export type RelayAPI = {
  /** Данный метод используется для общения с Suspense */
  checkRelayStatus(): any;
};

// Создает обертку RelayAPI над промисом
export const getRelayAPI = <T>(promise: Promise<T>): RelayAPI => {
  const sourcePromise: Promise<T> & { relayAPI?: RelayAPI } = promise;

  if (!sourcePromise.relayAPI) {
    const relayState: RelayState<T> = {
      status: RelayStatus.Pending,
      data: null,
      errorInfo: null,
    };
    sourcePromise.then((data) => {
      relayState.status = RelayStatus.Success;
      relayState.data = data;
    });
    sourcePromise.catch((error) => {
      relayState.status = RelayStatus.Success;
      relayState.errorInfo = error;
    });
    sourcePromise.relayAPI = {
      checkRelayStatus() {
        const { status, data, errorInfo } = relayState;
        if (status === RelayStatus.Pending) throw sourcePromise;
        else if (status === RelayStatus.Success) return data;
        else if (status === RelayStatus.Error) throw errorInfo;
        else return null;
      },
    };
  }

  return sourcePromise.relayAPI;
};
