import * as React from 'react';
import { Typography } from '@themes';
import Hint from '@common/components/popups/hint/Hint';

export interface ITitleProps {
  title: React.ReactChild;
  tooltip?: string;
  mainClass: string;
}

/**
 * TODO: Перевести на 'TruncatedHint'
 * При переводе на 'TruncatedHint', падают тесты в "common/components/panels/menu/__tests__/Menu.int.spec.tsx"
 * из-за вызова cb на 'requestAnimationFrame'
 */
const ContextMenuAdvanceTitle: React.FC<ITitleProps> = ({ title, tooltip, mainClass }) =>
  typeof title === 'string' ? (
    <Hint tooltip={tooltip || title} triggerClassName={`${mainClass}name`}>
      <Typography variant="textNormal" noWrap>
        {title}
      </Typography>
    </Hint>
  ) : (
    <span>{title}</span>
  );

export default ContextMenuAdvanceTitle;
