import { IMap } from '@common/types/map';
import { ITheme } from '@themes/styles';
import storage from '@common/utils/storage';
import { EPaletteMode } from '@themes/styles/palette';

export enum EThemeStorage {
  loadedConfigs = 'loadedConfigs',
  myConfigs = 'myConfigs',
  selected = 'selected',
  mode = 'mode',
}
export interface IThemeStorage {
  selected: string;
  mode: EPaletteMode;
  loadedConfigs: IMap<ITheme>;
  myConfigs: IMap<ITheme>;
}

const ThemeStorage = storage.StorageManager<IThemeStorage>('platform-theme');

export default ThemeStorage;
