import { SlotMap, withHocSlots, WithSlot } from '@themes/utils/classes';
import { ITreeListViewItemProps, ITreeListViewProps } from '../TreeListViewModel';

export const componentName = 'TreeListView';
export const componentNameItem = 'TreeListViewItem';
export const treeListViewClassPrefix = `Ui${componentName}`;
export const treeListViewItemClassPrefix = `Ui${componentNameItem}`;
const slots = ['root', 'dndClone'] as const;
const itemSlots = ['root'] as const;

const slotsMap: SlotMap<typeof slots, ITreeListViewProps> = {
  root: ['root'],
  dndClone: ['dndClone'],
};
const slotsItemMap: SlotMap<typeof itemSlots, ITreeListViewItemProps> = {
  root: ['root'],
};

export const withSlotClasses = withHocSlots(slotsMap, treeListViewClassPrefix);
export const withSlotItemClasses = withHocSlots(slotsItemMap, treeListViewItemClassPrefix);
export type WithTreeListViewSlot = WithSlot<typeof slots>;
export type WithTreeListViewItemSlot = WithSlot<typeof itemSlots>;
