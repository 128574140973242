import { IMap } from '@common/types/map';
import { ITheme as Theme } from '@themes/styles';

export enum IThemeVariant {
  /** fap10 */
  Base = 'base',
  /** mo */
  Old = 'old',
}

export interface ITheme {
  name: string;
  styles: Theme;
}

export interface IThemeConfig {
  current: string;
  list: IMap<ITheme>;
}

export enum EIcon {
  NotImage = 'not-image',
  LogoRu = 'logo-ru',
  LogoEn = 'logo-en',
  LogoSidebarRu = 'logo-sidebar-ru',
  LogoSidebarCollapsedRu = 'logo-sidebar-collapsed-ru',
  LogoSidebarEn = 'logo-sidebar-en',
  LogoSidebarCollapsedEn = 'logo-sidebar-collapsed-en',
  PageBackground1 = 'PageBackground1',
  Favicon = 'Favicon',

  Warning = 'ActionsWarning',
  Success = 'ActionsSuccess',
  Error = 'ActionsError',
  Custom = 'ActionsCustom',
  Info = 'ActionsInfo-1',

  Edit = 'Edit',
  Arrow = 'Arrow',
  Attach = 'Attach',
  CloseBig = 'Close-big',
  Converter = 'Converter',
  Datplus = 'Datplus',
  Delete = 'Delete',
  Destination = 'Destination',
  Enter = 'Enter',
  Exit = 'Exit',
  Help = 'Help',
  Linked = 'Linked',
  LinkedAuto = 'Linked-auto',
  Metabase = 'Metabase',
  Open = 'Open',
  Other = 'Other',
  Rds = 'Rds',
  Separation = 'Separation',
  Settings = 'Settings',
  Source = 'Source',
  Undatplus = 'Undatplus',
  Unlinked = 'Unlinked',
  Up = 'Up',
  AppointmentsConsumer = 'AppointmentsConsumer',
  AppointmentsConverter = 'AppointmentsConverter',
  AppointmentsNone = 'AppointmentsNone',
  AppointmentsOther = 'AppointmentsOther',
  AppointmentsProvider = 'AppointmentsProvider',
  CreationZoneConverter = 'CreationZoneConverter',
  CreationZoneDestination = 'CreationZoneDestination',
  CreationZoneOther = 'CreationZoneOther',
  CreationZoneSource = 'CreationZoneSource',
  CodeBlock = 'CodeBlock',
  Constant = 'Constant',
  Excel = 'Excel',
  FixedCodeBlock = 'FixedCodeBlock',
  KeyGenerator = 'KeyGenerator',
  MdFilter = 'MDFilter',
  ModuleForeWithHandler = 'ModuleForeWithHandler',
  DimensionPositionRows = 'DimensionPositionRows',
  DimensionPositionColumns = 'DimensionPositionColumns',
  DimensionPositionFixed = 'DimensionPositionFixed',
  DimensionPositionFilter = 'DimensionPositionFilter',
  DimensionPositionToRows = 'DimensionPositionToRows',
  DimensionPositionToColumns = 'DimensionPositionToColumns',
  PlainDataAccessConsumer = 'PlainDataAccessConsumer',
  PlainDataAccessProvider = 'PlainDataAccessProvider',
  PlainDataCopy = 'PlainDataCopy',
  PlainDataCubeConsumer = 'PlainDataCubeConsumer',
  PlainDataDbaseConsumer = 'PlainDataDbaseConsumer',
  PlainDataDbaseProvider = 'PlainDataDbaseProvider',
  PlainDataDeduplicate = 'PlainDataDeduplicate',
  PlainDataDestination = 'PlainDataDestination',
  PlainDataExcelConsumer = 'PlainDataExcelConsumer',
  PlainDataExcelConsumerEx = 'PlainDataExcelConsumerEx',
  PlainDataExcelProvider = 'PlainDataExcelProvider',
  PlainDataExcelProviderEx = 'PlainDataExcelProviderEx',
  PlainDataFilter = 'PlainDataFilter',
  PlainDataFixedUser = 'PlainDataFixedUser',
  PlainDataFixedUserConsumer = 'PlainDataFixedUserConsumer',
  PlainDataFixedUserProvider = 'PlainDataFixedUserProvider',
  PlainDataGroup = 'PlainDataGroup',
  PlainDataJoin = 'PlainDataJoin',
  PlainDataJsonConsumer = 'PlainDataJsonConsumer',
  PlainDataJsonProvider = 'PlainDataJsonProvider',
  PlainDataMapper = 'PlainDataMapper',
  PlainDataMetabaseConsumer = 'PlainDataMetabaseConsumer',
  PlainDataMetabaseProvider = 'PlainDataMetabaseProvider',
  PlainDataOleDbConsumer = 'PlainDataOleDbConsumer',
  PlainDataOleDbProvider = 'PlainDataOleDbProvider',
  PlainDataParadoxProvider = 'PlainDataParadoxProvider',
  PlainDataRdsConsumer = 'PlainDataRdsConsumer',
  PlainDataRdsProvider = 'PlainDataRdsProvider',
  PlainDataRestProvider = 'PlainDataRestProvider',
  PlainDataSort = 'PlainDataSort',
  PlainDataSource = 'PlainDataSource',
  PlainDataSplit = 'PlainDataSplit',
  PlainDataSqlCommandConsumer = 'PlainDataSqlCommandConsumer',
  PlainDataTextConsumer = 'PlainDataTextConsumer',
  PlainDataTextProvider = 'PlainDataTextProvider',
  PlainDataUnion = 'PlainDataUnion',
  PlainDataUser = 'PlainDataUser',
  PlainDataUserConsumer = 'PlainDataUserConsumer',
  PlainDataUserProvider = 'PlainDataUserProvider',
  PlainDataVfpConsumer = 'PlainDataVfpConsumer',
  PlainDataVfpProvider = 'PlainDataVfpProvider',
  PlainDataWebProvider = 'PlainDataWebProvider',
  PlainDataXmlConsumer = 'PlainDataXmlConsumer',
  PlainDataXmlParser = 'PlainDataXmlParser',
  PlainDataXmlProvider = 'PlainDataXmlProvider',
  PlainLink = 'PlainLink',
  Relation = 'Relation',
  RoundGrey = 'RoundGrey',
  SqlCommandBlock = 'SQLCommandBlock',
  ToolbarAlignCenter = 'ToolbarAlignCenter',
  ToolbarAngledLine = 'ToolbarAngledLine',
  ToolbarArrowLine = 'ToolbarArrowLine',
  ToolbarCollapsedArrows = 'ToolbarCollapsedArrows',
  ToolbarCurlyArrows = 'ToolbarCurlyArrows',
  ToolbarCursor = 'ToolbarCursor',
  ToolbarCursor2 = 'ToolbarCursor2',
  ToolbarCursorHand = 'ToolbarCursorHand',
  ToolbarCurvedLine = 'ToolbarCurvedLine',
  ToolbarDisplayMode = 'ToolbarDisplayMode',
  ToolbarDottedLine = 'ToolbarDottedLine',
  ToolbarEllipse = 'ToolbarEllipse',
  ToolbarGrid = 'ToolbarGrid',
  ToolbarGroup = 'ToolbarGroup',
  ToolbarGroup2 = 'ToolbarGroup2',
  ToolbarHexagon = 'ToolbarHexagon',
  ToolbarKinkedLine = 'ToolbarKinkedLine',
  ToolbarStraightLine = 'ToolbarLine',
  ToolbarLine = 'ToolbarLine',
  ToolbarOctagon = 'ToolbarOctagon',
  ToolbarParams = 'ToolbarParams',
  ToolbarPentagon = 'ToolbarPentagon',
  ToolbarQuadrangle = 'ToolbarQuadrangle',
  ToolbarRectangle = 'ToolbarRectangle',
  ToolbarRoundedRectangle = 'ToolbarRoundedRectangle',
  ToolbarTriangle = 'ToolbarTriangle',
  ToolbarUngroup = 'ToolbarUngroup',
  ToolbarBoldDashedBorder = 'ToolbarBoldDashedBorder',
  ToolbarBoldDottedBorder = 'ToolbarBoldDottedBorder',
  ToolbarBoldSolidBorder = 'ToolbarBoldSolidBorder',
  ToolbarBorders = 'ToolbarBorders',
  ToolbarDashedBorder = 'ToolbarDashedBorder',
  ToolbarDashedDottedBorder1 = 'ToolbarDashedDottedBorder1',
  ToolbarDashedDottedBorder2 = 'ToolbarDashedDottedBorder2',
  ToolbarDottedBorder = 'ToolbarDottedBorder',
  ToolbarLineWidth = 'ToolbarLineWidth',
  ToolbarRename = 'ToolbarRename',
  ToolbarSolidBorder = 'ToolbarSolidBorder',
  ToolbarText = 'ToolbarText',
  ToolbarThinDashedBorder = 'ToolbarThinDashedBorder',
  ToolbarThinDottedBorder = 'ToolbarThinDottedBorder',
  ToolbarThinSolidBorder = 'ToolbarThinSolidBorder',

  FigureLine = 'FigureLine',
  FigureArrow = 'FigureArrow',
  FigureArrowDouble = 'FigureArrowDouble',
  FigureCircle = 'FigureCircle',

  Branching = 'Branching',
  Branch = 'Branch',
  Else = 'Else',

  NavigatorComboList = 'NavigatorComboList',
  NavigatorLargeIcons = 'NavigatorLargeIcons',
  NavigatorList = 'NavigatorList',
  NavigatorMiddleIcons = 'NavigatorMiddleIcons',
  NavigatorSmallIcons = 'NavigatorSmallIcons',
  NavigatorTable = 'NavigatorTable',

  DataTypes1 = 'DataTypes1',
  DataTypes2 = 'DataTypes2',
  DataTypes3 = 'DataTypes3',
  DataTypes4 = 'DataTypes4',
  DataTypes5 = 'DataTypes5',
  DataTypes6 = 'DataTypes6',
  DataTypes7 = 'DataTypes7',
  DataTypes8 = 'DataTypes8',
  DataTypesUser = 'DataTypesUser',

  ParamTypesSelection = 'ParamTypesSelection',

  // ACTIONS
  ActionActiveComponents = 'ActionActiveComponents',
  ActionAgreement = 'ActionAgreement',
  ActionSelectComponent = 'ActionSelectComponent',
  ActionsAction = 'ActionsAction',
  ActionsAction40 = 'ActionsAction40',
  ActionsAddAttribute = 'ActionsAdd-attribute',
  ActionsAddBlock = 'ActionsAdd-block',
  ActionsAddBreak = 'ActionsAddBreak',
  ActionsAddChart = 'ActionsAdd-chart',
  ActionsAddColumn = 'ActionsAdd_column',
  ActionsAddComment = 'ActionsAddComment',
  ActionsAddFolder = 'ActionsAdd-folder',
  ActionsAddGrid = 'ActionsAdd-grid',
  ActionsAddIndex = 'ActionsAdd-index',
  ActionsAddLaneAbove = 'ActionsAddLaneAbove',
  ActionsAddLaneBelow = 'ActionsAddLaneBelow',
  ActionsAddObject = 'ActionsAdd-object',
  ActionsAddRow = 'ActionsAdd_row',
  ActionsAddTable = 'ActionsAdd-table',
  ActionsAddToRefresh = 'ActionsAddToRefresh',
  ActionsAdjustment = 'ActionsAdjustment',
  ActionsAdministration = 'ActionsAdministration',
  ActionsAdvancedSearch = 'ActionsAdvanced_search',
  ActionsAlignCenter = 'ActionsAlign-center',
  ActionsAlignGeneral = 'ActionsAlign-general',
  ActionsAlignJustify = 'ActionsAlign-justify',
  ActionsAlignLeft = 'ActionsAlign-left',
  ActionsAlignRight = 'ActionsAlign-right',
  ActionsArrowBreadcrumb = 'ActionsArrowBreadcrumb',
  ActionsArrowCornerBottomRight = 'ActionsArrow-corner-bottom-right',
  ActionsArrowDown = 'ActionsArrow-down',
  ActionsArrowDownLong = 'ActionsArrow-down-long',
  ActionsArrowDownSlim = 'ActionsArrow-down-slim',
  ActionsArrowDownSlimSmall = 'ActionsArrow-down-slim-small',
  ActionsArrowLeft = 'ActionsArrow-left',
  ActionsArrowLeftLong = 'ActionsArrow-left-long',
  ActionsArrowLeftSlim = 'ActionsArrow-left-slim',
  ActionsArrowRight = 'ActionsArrow-right',
  ActionsArrowRightLong = 'ActionsArrow-right-long',
  ActionsArrowRightSlim = 'ActionsArrow-right-slim',
  ActionsArrowSelect = 'ActionsArrowSelect', // В МО и ФАП 10 разные иконки стрелок у селекта
  ActionsArrowSettings = 'ActionsArrow_settings',
  ActionsArrowSettingsActive = 'ActionsArrow_settings_active',
  ActionsArrowUp = 'ActionsArrow-up',
  ActionsArrowUpLong = 'ActionsArrow-up-long',
  ActionsArrowUpSlim = 'ActionsArrow-up-slim',
  ActionsAssembly = 'ActionsAssembly',
  ActionsAttach = 'ActionsAttach',
  ActionsAttach2 = 'ActionsAttach2',
  ActionsAutoFillC = 'ActionsAutoFillC',
  ActionsAutoFillF = 'ActionsAutoFillF',
  ActionsAutoFillInc = 'ActionsAutoFillInc',
  ActionsAutoFillMenu = 'ActionsAuto-fill-menu',
  ActionsAutoRefresh = 'ActionsAutoRefresh',
  ActionsAutoSuccess = 'ActionsAutoSuccess',
  ActionsAutoUpdate = 'ActionsAutoupdate',
  ActionsAutoUpdateSmall = `ActionsAutoupdateSmall`,
  ActionsBack = 'ActionsBack',
  ActionsBind = 'ActionsBind',
  ActionsBlock = 'ActionsBlock',
  ActionsBorderAll = 'ActionsBorder-all',
  ActionsBorderAll2 = 'ActionsBorderAll2',
  ActionsBorderBottom = 'ActionsBorder-bottom',
  ActionsBorderDashed = 'ActionsBorder-dashed',
  ActionsBorderDashedBlack = 'ActionsBorder-dashed_black',
  ActionsBorderDashedBlackSm = 'ActionsBorder-dashed_black-sm',
  ActionsBorderDashedBold = 'ActionsBorder-dashed_bold',
  ActionsBorderDashedBoldSm = 'ActionsBorder-dashed_bold-sm',
  ActionsBorderDashedSm = 'ActionsBorder-dashed-sm',
  ActionsBorderDotted = 'ActionsBorder-dotted',
  ActionsBorderDottedBlack = 'ActionsBorder-dotted_black',
  ActionsBorderDottedBlackSm = 'ActionsBorder-dotted_black-sm',
  ActionsBorderDottedBold = 'ActionsBorder-dotted_bold',
  ActionsBorderDottedBoldSm = 'ActionsBorder-dotted_bold-sm',
  ActionsBorderDottedSm = 'ActionsBorder-dotted-sm',
  ActionsBorderDouble = 'ActionsBorder-double',
  ActionsBorderDoubleSm = 'ActionsBorder-double-sm',
  ActionsBorderHorizontal = 'ActionsBorder-horizontal',
  ActionsBorderInner = 'ActionsBorder-inner',
  ActionsBorderLeft = 'ActionsBorder-left',
  ActionsBorderLine = 'ActionsBorder-line',
  ActionsBorderLineBlack = 'ActionsBorder-line_black',
  ActionsBorderLineBlackSm = 'ActionsBorder-line_black-sm',
  ActionsBorderLineBm = 'ActionsBorder-line-sm',
  ActionsBorderLineBold = 'ActionsBorder-line_bold',
  ActionsBorderLineBoldSm = 'ActionsBorder-line_bold-sm',
  ActionsBorderLineSm = 'ActionsBorder-line-sm',
  ActionsBorderLineType = 'ActionsBorder-line-type',
  ActionsBorderLongDashedBold = 'ActionsBorderLongDashed_bold',
  ActionsBorderLongDashedBoldSm = 'ActionsBorderLongDashed_bold-sm',
  ActionsBorderLongDashedDotBold = 'ActionsBorderLongDashedDot_bold',
  ActionsBorderLongDashedDotBoldSm = 'ActionsBorderLongDashedDot_bold-sm',
  ActionsBorderLongLongDashedBold = 'ActionsBorderLongLongDashed_bold',
  ActionsBorderLongLongDashedBoldSm = 'ActionsBorderLongLongDashed_bold-sm',
  ActionsBorderLongLongDashedDotBold = 'ActionsBorderLongLongDashedDot_bold',
  ActionsBorderLongLongLongDashedBold = 'ActionsBorderLongLongLongDashed_bold',
  ActionsBorderLongLongLongDashedBoldSm = 'ActionsBorderLongLongLongDashed_bold-sm',
  ActionsBorderLongLongLongDashedDotBold = 'ActionsBorderLongLongLongDashedDot_bold',
  ActionsBorderNone = 'ActionsBorder-none',
  ActionsBorderOutline = 'ActionsBorder-outline',
  ActionsBorderRight = 'ActionsBorder-right',
  ActionsBorderTop = 'ActionsBorder-top',
  ActionsBorderVertical = 'ActionsBorder-vertical',
  ActionsBranch = 'ActionsBranch',
  ActionsBreaks = 'ActionsBreaks',
  ActionsCaseSensitive = 'ActionsCaseSensitive',
  ActionsCellErrorConvert = 'ActionsCellErrorConvert',
  ActionsCellErrorSkip = 'ActionsCellErrorSkip',
  ActionsCheck = 'ActionsCheck',
  ActionsCheckAllChild = 'ActionsCheck_all-child',
  ActionsCheckBig = 'ActionsCheckBig',
  ActionsCheckCircle = 'ActionsCheckCircle',
  ActionsCheckCircleFill = 'ActionsCheckCircleFill',
  ActionsCheckMulti = 'ActionsCheck-multi',
  ActionsCheckParentAllChild = 'ActionsCheck_parent-all-child',
  ActionsCheckParentPartChild = 'ActionsCheck_parent-part-child',
  ActionsCheckPartChild = 'ActionsCheck_part-child',
  ActionsCircle = 'ActionsCircle',
  ActionsCircleCheck = 'ActionsCircle_check',
  ActionsCircleMinus = 'ActionsCircle_minus',
  ActionsCirclePlus = 'ActionsCircle_plus',
  ActionsCircleBorder = 'ActionsCircle_border',
  ActionsCircleFill = 'ActionsCircle_fill',
  ActionsClassInspector = 'ActionsClassInspector',
  ActionsClear = 'ActionsClear',
  ActionsClearFilter = 'ActionsClear-filter',
  ActionsClearTable = 'ActionsClear-table',
  ActionsClock = 'ActionsClock',
  ActionsClose = 'ActionsClose',
  ActionsCloseInCircle = 'ActionsClose_in_circle',
  ActionsCloseLg = 'ActionsClose_lg',
  ActionsCloseM = 'ActionsClose_m',
  ActionsCloseRed = 'ActionsClose-red',
  ActionsCloseSmall = 'ActionsClose-small',
  ActionsCode = 'ActionsCode',
  ActionsCollapse = 'ActionsCollapse',
  ActionsCompile = 'ActionsCompile',
  ActionsCondition = 'ActionsCondition',
  ActionsCondition40 = 'ActionsCondition40',
  ActionsConditionalFormatting = 'ActionsConditionalFormatting',
  ActionsControlPanel = 'ActionsControlPanel',
  ActionsCopy = 'ActionsCopy',
  ActionsCopyNoData = 'ActionsCopyNoData',
  ActionsCopyStyle = 'ActionsCopyStyle',
  ActionsCopyWithData = 'ActionsCopyWithData',
  ActionsCornerXs = 'ActionsCorner_xs',
  ActionsCubeCaching = 'ActionsCubeCaching',
  ActionsCut = 'ActionsCut',
  ActionsCut2 = 'ActionsCut2',
  ActionsDashLine4 = 'ActionsDash-line4',
  ActionsDashLine4Sm = 'ActionsDash-line4-sm',
  ActionsDashLine5 = 'ActionsDash-line5',
  ActionsDashLine5Sm = 'ActionsDash-line5-sm',
  ActionsDecision = 'ActionsDecision',
  ActionsDecision40 = 'ActionsDecision40',
  ActionsDecisionButtonPanel = 'ActionsDecision-buttonPanel',
  ActionsDecor = 'ActionsDecor',
  ActionsDecreaseDigitNum = 'ActionsDecreaseDigitNum',
  ActionsDefSettings = 'ActionsDefSettings',
  ActionsDelete = 'ActionsDelete',
  ActionsDeleteBreak = 'ActionsDeleteBreak',
  ActionsDownload = 'ActionsDownload',
  ActionsDownload2 = 'ActionsDownload2',
  ActionsDraggable = 'ActionsDraggable',
  ActionsDragging = 'ActionsDragging',
  ActionsDrillDown = 'ActionsDrillDown',
  ActionsDrillUp = 'ActionsDrillUp',
  ActionsEdit = 'ActionsEdit',
  ActionsEditSettings = 'ActionsEditSettings',
  ActionsEditShortcut = 'ActionsEditShortcut',
  ActionsEllipsis = 'ActionsEllipsis',
  ActionsEllipsisVertical = 'ActionsEllipsis-vertical',
  ActionsError = 'ActionsError',
  ActionsEvaluateExpression = 'ActionsEvaluateExpression',
  ActionsExactly = 'ActionsExactly',
  ActionsExecute = `ActionsExecute`,
  ActionsExecuteBack = 'ActionsExecuteBack',
  ActionsExecuteForward = 'ActionsExecuteForward',
  ActionsExit = 'ActionsExit',
  ActionsExpand = 'ActionsExpand',
  ActionsExpand2 = 'ActionsExpand_2',
  ActionsExpandRevert = 'ActionsExpand-revert',
  ActionsExport = 'ActionsExport',
  ActionsExport2 = 'ActionsExport_new',
  ActionsEye = 'ActionsEye',
  ActionsFavorites = 'ActionsFavorites',
  ActionsFigure = 'ActionsFigure',
  ActionsFill = 'ActionsFill',
  ActionsFilter = 'ActionsFilter',
  ActionsFilterFilled = 'ActionsFilter-filled',
  ActionsFilterS = 'ActionsFilter_s',
  ActionsFindAndReplace = 'ActionsFindAndReplace',
  ActionsFitAll = 'ActionsFit-all',
  ActionsFitObject = 'ActionsFit-object',
  ActionsFolder = 'ActionsFolder',
  ActionsFooterPanelMenu = 'ActionsMenu-footerPanel',
  ActionsForm = 'ActionsForm',
  ActionsFormCode = 'ActionsFormCode',
  ActionsFormatting = 'ActionsFormatting',
  ActionsFormula = 'ActionsFormula',
  ActionsFormulaDescription = 'ActionsFormulaDescription',
  ActionsFormulaWithAsterisk = 'ActionsFormulaWithAsterisk',
  ActionsFreezeArea = 'ActionsFreeze-area',
  ActionsFreezeAreas = 'ActionsFreeze-areas',
  ActionsFreezeFirstColumn = 'ActionsFreeze-first-column',
  ActionsFreezeTopRow = 'ActionsFreeze-top-row',
  ActionsHat = 'ActionsHat',
  ActionsHelp = 'ActionsHelp',
  ActionsHelpBlue = 'ActionsHelp-blue',
  ActionsHelpWhite = 'ActionsHelp-white',
  ActionsHidden = 'ActionsHidden',
  ActionsHide = 'ActionsHide',
  ActionsHideCol = 'ActionsHideCol',
  ActionsHideRow = 'ActionsHideRow',
  ActionsHistory = 'ActionsHistory',
  ActionsHistoryBack = 'ActionsHistoryBack',
  ActionsHistoryForward = 'ActionsHistoryForward',
  ActionsIconThreeDotBlue = 'ActionsIcon_three_dot-blue',
  ActionsImage = 'ActionsImage',
  ActionsImport = 'ActionsImport',
  ActionsIncreaseDigitNum = 'ActionsIncreaseDigitNum',
  ActionsInfo = 'ActionsInfo',
  ActionsInfo1 = 'ActionsInfo-1',
  ActionsInfo2 = 'ActionsInfo_2',
  ActionsInsert = 'ActionsInsert',
  ActionsInsert2 = 'ActionsInsert_2',
  ActionsInsertColumnLeft = 'ActionsInsertColumnLeft',
  ActionsInsertColumnRight = 'ActionsInsertColumnRight',
  ActionsInsertImage = 'ActionsInsertImage',
  ActionsInsertRowDown = 'ActionsInsertRowDown',
  ActionsInsertRowUp = 'ActionsInsertRowUp',
  ActionsInsertShape = 'ActionsInsertShape',
  ActionsKey = 'ActionsKey',
  ActionsLink = 'ActionsLink',
  ActionsLinkOn = 'ActionsLinkOn',
  ActionsLinks = 'ActionsLinks',
  ActionsList = 'ActionsList',
  ActionsListAll = 'ActionsList-all',
  ActionsListChecked = 'ActionsList-checked',
  ActionsLock = 'ActionsLock',
  ActionsLogo = 'ActionsLogo',
  ActionsMap = 'ActionsMap',
  ActionsMargins = 'ActionsMargins',
  ActionsMenu = 'ActionsMenu',
  ActionsMerge = 'ActionsMerge',
  ActionsMiniPin = 'ActionsMiniPin',
  ActionsMinus = 'ActionsMinus',
  ActionsModalTitle = 'ActionsModalTitle',
  ActionsMonitoring = 'ActionsMonitoring',
  ActionsBackToInstances = 'ActionsBackToInstances',
  ActionsMoreVert = 'ActionsMore_vert',
  ActionsMouse = `ActionsMouse`,
  ActionsMoveDown = 'ActionsMoveDown',
  ActionsMoveInDown = 'ActionsMove-in-down',
  ActionsMoveInUp = 'ActionsMove-in-up',
  ActionsMoveToUp = 'ActionsMoveToUp',
  ActionsMoveUp = 'ActionsMoveUp',
  ActionsNamedRegion = 'ActionsNamedRegion',
  ActionsNavigator = 'ActionsNavigator',
  ActionsNoOptions = 'ActionsNo-options',
  ActionsNumberline = 'ActionsNumberline',
  ActionsObjectsInMemory = 'ActionsObjectsInMemory',
  ActionsOpenIn = 'ActionsOpenIn',
  ActionsOptions = 'ActionsOptions',
  ActionsOrderBack = 'ActionsOrderBack',
  ActionsOrderBackward = 'ActionsOrderBackward',
  ActionsOrderForward = 'ActionsOrderForward',
  ActionsOrderFront = 'ActionsOrderFront',
  ActionsOrientation = 'ActionsOrientation',
  ActionsPanelLeft = 'ActionsPanelLeft',
  ActionsPanelRight = 'ActionsPanelRight',
  ActionsParams = 'ActionsParams',
  ActionsPaste = 'ActionsPaste',
  ActionsPause = `ActionsPause`,
  ActionsPauseLoop = 'ActionsPause_loop',
  ActionsPauseRing = `ActionsPauseRing`,
  ActionsPencil = 'ActionsPencil',
  ActionsPencilEdit = 'ActionsPencil-edit',
  ActionsPicture = 'ActionsPicture',
  ActionsPlay = 'ActionsPlay',
  ActionsPlay2 = 'ActionsPlay2',
  ActionsPlus = 'ActionsPlus',
  ActionsPlusOutline = 'ActionsPlus_outline',
  ActionsPreview = 'ActionsPreview',
  ActionsPrint = 'ActionsPrint',
  ActionsPrint2 = 'ActionsPrint_new',
  ActionsProcesses = 'ActionsProcesses',
  ActionsQuestion = 'ActionsQuestion',
  ActionsRedo = 'ActionsRedo',
  ActionsRefresh = 'ActionsRefresh',
  ActionsRefreshButtonPanel = 'ActionsRefresh-buttonPanel',
  ActionsRejectData = 'ActionsReject-data',
  ActionsRename = 'ActionsRename',
  ActionsReplace = 'ActionsReplace',
  ActionsReplaceAll = 'ActionsReplaceAll',
  ActionsReplacementMode = 'ActionsReplacement_mode',
  ActionsResetBlockStyle = 'ActionsReset-block-style',
  ActionsResetBreaks = 'ActionsResetBreaks',
  ActionsResizeColumnWidth = 'ActionsResizeColumnWidth',
  ActionsResizeColumnWidthNew = 'ActionsResizeColumnWidth_new',
  ActionsResizeRowHeight = 'ActionsResizeRowHeight',
  ActionsResizeRowHeightNew = 'ActionsResizeRowHeight_new',
  ActionsResourceImage = 'ActionsResourceImage',
  ActionsRestart = 'ActionsRestart',
  ActionsRotate1 = 'ActionsRotate1',
  ActionsRotate2 = 'ActionsRotate2',
  ActionsRotate3 = 'ActionsRotate3',
  ActionsRotate4 = 'ActionsRotate4',
  ActionsRotate5 = 'ActionsRotate5',
  ActionsRun = 'ActionsRun',
  ActionsRunDebug = 'ActionsRunDebug',
  ActionsRunDots = 'ActionsRun_dots',
  ActionsRunLoop = 'ActionsRun_loop',
  ActionsRunRing = 'ActionsRun_ring',
  ActionsSave = 'ActionsSave',
  ActionsSaveData = 'ActionsSave-data',
  ActionsSaveLock = 'ActionsSave-lock',
  ActionsSearch = 'ActionsSearch',
  ActionsSegments = 'ActionsSegments',
  ActionsSetBlockStyle = 'ActionsSet-block-style',
  ActionsSettings = 'ActionsSettings',
  ActionsSettings2 = 'ActionsSettings_2',
  ActionsSettings3 = 'ActionsSettings_3',
  ActionsSettings32 = 'ActionsSettings32',
  ActionsSettings32Hovered = 'ActionsSettings32-hovered',
  ActionsSettingsWhite = 'ActionsSettings-white',
  ActionsShelf = 'ActionsShelf',
  ActionsShowCol = 'ActionsShowCol',
  ActionsShowPanel = 'ActionsShowPanel',
  ActionsShowRegions = 'ActionsShowRegions',
  ActionsShowRow = 'ActionsShowRow',
  ActionsSize = 'ActionsSize',
  ActionsSortAsc = 'ActionsSort-asc',
  ActionsSortDesc = 'ActionsSort-desc',
  ActionsSortNone = 'ActionsSort-none',
  ActionsSortingAsc = 'ActionsSorting-asc',
  ActionsSortingDesc = 'ActionsSorting-desc',
  ActionsSortingDisabled = 'ActionsSorting-disabled',
  ActionsSortingOff = 'ActionsSorting-off',
  ActionsSource = 'ActionsSource',
  ActionsSources = 'ActionsSources',
  ActionsSpecialPaste = 'ActionsSpecialPaste',
  ActionsSplitter = 'ActionsSplitter',
  ActionsSplitter40 = 'ActionsSplitter40',
  ActionsStepIn = 'ActionsStepIn',
  ActionsStepOut = 'ActionsStepOut',
  ActionsStepOver = 'ActionsStepOver',
  ActionsStopLoop = 'ActionsStop_loop',
  ActionsStopRing = 'ActionsStop_ring',
  ActionsSuccess = 'ActionsSuccess',
  ActionsTable = 'ActionsTable',
  ActionsTableResize = 'ActionsTableResize',
  ActionsTextBold = 'ActionsText-bold',
  ActionsTextColor = 'ActionsTextColor',
  ActionsTextItalic = 'ActionsText-italic',
  ActionsTextLineThrough = 'ActionsText-line-through',
  ActionsTextUnderline = 'ActionsText-underline',
  ActionsTextWrapping = 'ActionsTextWrapping',
  ActionsTextWrappingByWords = 'ActionsTextWrappingByWords',
  ActionsTextWrappingNone = 'ActionsTextWrappingNone',
  ActionsThreeHorizontalDots = 'ActionsThree-horizontal-dots',
  ActionsThreeVerticalDots = 'ActionsThree-vertical-dots',
  ActionsToDown = 'ActionsTo-down',
  ActionsToMiddle = 'ActionsTo-middle',
  ActionsToUp = 'ActionsTo-up',
  ActionsTop = 'ActionsTop',
  ActionsTranspon = 'ActionsTranspon',
  ActionsTranspose = 'ActionsTranspose',
  ActionsTree = 'ActionsTree',
  ActionsTriangleBottom = 'ActionsTriangle-bottom',
  ActionsTypeError = 'ActionsTypeError',
  ActionsUnMerge = 'ActionsUnMerge',
  ActionsUndo = 'ActionsUndo',
  ActionsUnfreezeAreas = 'ActionsUnfreeze-areas',
  ActionsUnlinked = 'ActionsUnlinked',
  ActionsUpload = 'ActionsUpload',
  ActionsUpload2 = 'ActionsUpload2',
  ActionsUploadXL = 'ActionsUploadXL',
  ActionsUserClassInspector = 'ActionsUserClassInspector',
  ActionsUserOptions = 'ActionsUserOptions',
  ActionsValidationError = 'ActionsValidationError',
  ActionsViewOptions = 'ActionsView-options',
  ActionsVisibilityOn = 'ActionsVisibility-on',
  ActionsWarning = 'ActionsWarning',
  ActionsWholeWord = 'ActionsWholeWord',
  ActionsXPDL = 'ActionsXPDL',
  InfoHint = 'InfoHint',
  Time = 'Time',

  // Checkbox
  CheckboxDefault = 'CheckboxDefault',
  CheckboxDefaultXS = 'CheckboxDefaultXS',
  CheckboxDefaultPartial = 'CheckboxDefaultPartial',
  CheckboxEye = 'CheckboxEye',
  CheckboxEyeChecked = 'CheckboxEyeChecked',

  // Иконки компонентов
  IconLabel = 'icon-label',
  IconButton = 'icon-button',
  IconInput = 'icon-input',
  IconDimensionCombo = 'icon-dimensionCombo',
  IconDimensionTree = 'icon-dimensionTree',
  IconCheckBox = 'icon-checkBox',
  IconRadioButton = 'icon-radioButton',
  IconCombobox = 'icon-combobox',
  IconReportBox = 'icon-reportBox',
  IconTreeCombo = 'icon-treeCombo',
  IconTreeList = 'icon-treeList',
  IconPanel = 'icon-panel',
  IconFileOpenDialog = 'icon-fileOpenDialog',
  IconFileSaveDialog = 'icon-fileSaveDialog',
  IconTextArea = 'icon-textarea',

  // NumberInput (специальная иконка)
  NumberInputArrow = 'NumberInputArrow',

  // DataPicker
  DateTable = 'DateTable',
  DateTableXS = 'DateTableXS',

  // Итоги
  Totals = 'Totals',

  // Пользовательские кнопки
  UserButtonsDialog = 'UserButtonsDialog',
  UserButtonDefault = 'UserButtonDefault',

  TopPosition = 'TopPosition',
  LeftPosition = 'LeftPosition',

  // Platform
  // Metabase Icons
  MB0 = '0',
  MB4 = '4',
  MB513 = '513',
  MB769 = '769',
  MB770 = '770',
  MB771 = '771',
  MB777 = '777',
  MB778 = '778',
  MB780 = '780',
  MB1024 = '1024',
  MB1025 = '1025',
  MB1026 = '1026',
  MB1027 = '1027',
  MB1028 = '3076',
  MB1035 = '3329',
  MB1281 = '1281',
  MB1282 = '1282',
  MB1283 = '1283',
  MB1284 = '1284',
  MB1286 = '1286',
  MB1287 = '1287',
  MB1296 = '1296',
  MB1537 = '1537',
  MB1538 = '1538',
  MB1539 = '1539',
  MB1540 = '1540',
  MB1793 = '1793',
  MB2049 = '2049',
  MB2561 = '2561',
  MB2562 = '2562',
  MB2563 = '2563',
  MB2822 = '2822',
  MB2827 = '2827',
  MB3076 = '3076',
  MB3077 = '3077',
  MB3329 = '3329',
  MB3330 = '3330',
  MB3331 = '3331',
  MB3332 = '3332',
  MB3841 = '3841',
  MB3842 = '3842',
  MB3843 = '3843',
  MB4097 = '4097',
  MB4353 = '4353',
  MB5121 = '5121',
  MB5123 = '5123',
  MB5378 = '5378',
  MB8193 = '8193',
  MB8448 = '8448',
  MB8449 = '8449',
  MB8450 = '8450',
  MB8961 = '8961',
  MB9473 = '9473',
  MB9216 = '9216',
  MB9217 = '9217',
  MB268033 = '268033',
  MBBUSINESS_APPLICATION = 'BUSINESS_APPLICATION',
  MBCOMPONENT = 'COMPONENT',
  MBP10002_PROCESS = 'P10002_PROCESS',
  MBCALC_LINER_OPTIM_BLOCK = 'CALC_LINER_OPTIM_BLOCK',
  MBCLS_FUNC_BLOCK = 'CLS_FUNC_BLOCK',
  MBCLS_VALID_BLOCK = 'CLS_VALID_BLOCK',
  MBDATA_ENTRY_FORM_CLASS = 'DATA_ENTRY_FORM_CLASS',
  MBDECISION_TREE_CLASS = 'DECISION_TREE_CLASS',
  MBObjectShortcut = 'Object-shortcut',
  MBP10002_AGGR_BLOCK = 'P10002_AGGR_BLOCK',
  MBP10002_CALC_ALG = 'P10002_CALC_ALG',
  MBP10002_CALC_ALG_UNCOLORED = 'P10002_CALC_ALG_UNCOLORED',
  MBP10002_CLS_CALCBLOCK = 'P10002_CLS_CALCBLOCK',
  MBShortcut = 'Shortcut',
  MBCustomClassEmpty = 'MBCustomClassEmpty',

  ParameterField = 'ParameterField',
  ParameterList = 'ParameterList',

  /** BPMN */
  // Действия
  Action = 'Action',
  ActionForeMethod = 'ActionForeMethod',
  ActionEtl = 'ActionEtl',
  ActionEtlAuto = 'ActionEtlAuto',
  ActionEmailAuto = 'ActionEmailAuto',
  ActionUser = 'ActionUser',
  ActionUserForm = 'ActionUserForm',
  ActionUserParent = 'ActionUserParent',
  ActionManual = 'ActionManual',
  ActionService = 'ActionService',
  ActionForeMethodAuto = 'ActionForeMethodAuto',
  ActionSubprocess = 'ActionSubprocess',
  ActionSubprocessAuto = 'ActionSubprocessAuto',
  ActionCalculation = 'ActionCalculation',
  ActionCalculationAuto = 'ActionCalculationAuto',
  // События
  EventEmail = `EventEmail`,
  EventFinal = `EventFinal`,
  EventFinalError = `EventFinalError`,
  EventFinalErrorNoBorder = `EventFinalErrorNoBorder`,
  EventFinalTerminate = `EventFinalTerminate`,
  EventFinalMessage = `EventFinalMessage`,
  EventIntermediate = `EventIntermediate`,
  EventIntermediateAbsoluteTimer = `EventIntermediateAbsoluteTimer`,
  EventIntermediateTimer = `EventIntermediateTimer`,
  EventIntermediateCondition = `EventIntermediateCondition`,
  EventIntermediateMessageCatch = `EventIntermediateMessageCatch`,
  EventIntermediateMessageThrow = `EventIntermediateMessageThrow`,
  EventStart = `EventStart`,
  EventStartCondition = `EventStartCondition`,
  EventStartMessage = `EventStartMessage`,
  EventStartTimer = `EventStartTimer`,
  // Линии
  LinkAssociation = `LinkAssociation`,
  LinkAssociationWithDirection = `LinkAssociationWithDirection`,
  LinkFlow = `LinkFlow`,
  LinkFlowDefault = `LinkFlowDefault`,
  LineMessageFlow = `LineMessageFlow`,
  // Шлюзы
  GateExclusive = `GateExclusive`,
  GateInclusive = `GateInclusive`,
  GateParallel = `GateParallel`,
  Group = `Group`,
  NotSetConditions = 'NotSetConditions',
  // Данные
  DataObject = `DataObject`,
  DataStore = `DataStore`,
  // Прочее
  Annotation = 'Annotation',
  Lane = 'Lane',
  Pool = 'Pool',
  Stage = 'Stage',
  Email = 'Email',
  Equally = 'Equally',
  ControlRight = 'ControlRight',

  /** Процессы */
  EventIntermediateProcess = `EventIntermediateProcess`,
  EventStartProcess = `EventStartProcess`,

  // Среда разработки
  DevenvBrokenLink = 'DevenvBrokenLink',
  DevenvLinks = 'DevenvLinks',
  DevenvClass = 'DevenvClass',
  DevenvClassPrivate = 'DevenvClassPrivate',
  DevenvClassProtected = 'DevenvClassProtected',
  DevenvClassFriend = 'DevenvClassFriend',
  DevenvClassProtectedFriend = 'DevenvClassFriend',

  DevenvConst = 'DevenvConst',
  DevenvConstPrivate = 'DevenvConstPrivate',
  DevenvConstProtected = 'DevenvConstProtected',
  DevenvConstFriend = 'DevenvConstFriend',
  DevenvConstProtectedFriend = 'DevenvConstFriend',

  DevenvDelegate = 'DevenvDelegate',
  DevenvDelegatePrivate = 'DevenvDelegatePrivate',
  DevenvDelegateProtected = 'DevenvDelegateProtected',
  DevenvDelegateFriend = 'DevenvDelegateFriend',
  DevenvDelegateProtectedFriend = 'DevenvDelegateFriend',

  DevenvEnum = 'DevenvEnum',
  DevenvEnumPrivate = 'DevenvEnumPrivate',
  DevenvEnumProtected = 'DevenvEnumProtected',
  DevenvEnumFriend = 'DevenvEnumFriend',
  DevenvEnumProtectedFriend = 'DevenvEnumFriend',

  DevenvEvent = 'DevenvEvent',
  DevenvEventPrivate = 'DevenvEventPrivate',
  DevenvEventProtected = 'DevenvEventProtected',
  DevenvEventFriend = 'DevenvEventFriend',
  DevenvEventProtectedFriend = 'DevenvEventFriend',

  DevenvFunction = 'DevenvFunction',
  DevenvFunctionPrivate = 'DevenvFunctionPrivate',
  DevenvFunctionProtected = 'DevenvFunctionProtected',
  DevenvFunctionFriend = 'DevenvFunctionFriend',
  DevenvFunctionProtectedFriend = 'DevenvFunctionFriend',

  DevenvInterface = 'DevenvInterface',
  DevenvInterfacePrivate = 'DevenvInterfacePrivate',
  DevenvInterfaceProtected = 'DevenvInterfaceProtected',
  DevenvInterfaceFriend = 'DevenvInterfaceFriend',
  DevenvInterfaceProtectedFriend = 'DevenvInterfaceFriend',

  DevenvModule = 'DevenvModule',
  DevenvModulePrivate = 'DevenvModulePrivate',
  DevenvModuleProtected = 'DevenvModuleProtected',
  DevenvModuleFriend = 'DevenvModuleFriend',
  DevenvModuleProtectedFriend = 'DevenvModuleFriend',

  DevenvNamespace = 'DevenvNamespace',
  DevenvNamespacePrivate = 'DevenvNamespacePrivate',
  DevenvNamespaceProtected = 'DevenvNamespaceProtected',
  DevenvNamespaceFriend = 'DevenvNamespaceFriend',
  DevenvNamespaceProtectedFriend = 'DevenvNamespaceFriend',

  DevenvProperty = 'DevenvProperty',
  DevenvPropertyPrivate = 'DevenvPropertyPrivate',
  DevenvPropertyProtected = 'DevenvPropertyProtected',
  DevenvPropertyFriend = 'DevenvPropertyFriend',
  DevenvPropertyProtectedFriend = 'DevenvPropertyFriend',

  DevenvSub = 'DevenvFunction',
  DevenvSubPrivate = 'DevenvFunctionPrivate',
  DevenvSubProtected = 'DevenvFunctionProtected',
  DevenvSubFriend = 'DevenvFunctionFriend',
  DevenvSubProtectedFriend = 'DevenvFunctionFriend',

  DevenvVar = 'DevenvVar',
  DevenvVarPrivate = 'DevenvVarPrivate',
  DevenvVarProtected = 'DevenvVarProtected',
  DevenvVarFriend = 'DevenvVarFriend',
  DevenvVarProtectedFriend = 'DevenvVarFriend',

  DevenvWebFormComponentSettings = 'DevenvWebFormComponentSettings',

  DevenvToggleBreakpoint = 'DevenvToggleBreakpoint',
  DevenvEvaluateExpression = 'DevenvEvaluateExpression',

  IndicatorOff = 'IndicatorOff',
  IndicatorOn = 'IndicatorOn',

  Folder = 'Folder',
  Folders = 'Folders',

  /** SHARTS */
  BarsGeneric = 'BarsGeneric',

  /** Таблица стилей */
  StyleSheetItem = 'StyleSheetItem',
  TabTableDefaultGreen = 'TabTableDefaultGreen',
  TabTableDefaultGrey = 'TabTableDefaultGrey',
  TabTableDefaultLilac = 'TabTableDefaultLilac',
  TabTableDefaultRed = 'TabTableDefaultRed',
  TabTableDefaultBlue = 'TabTableDefaultBlue',
  TabTableDefaultYellow = 'TabTableDefaultYellow',

  AboutRu = 'About-ru',
  AboutEn = 'About-en',

  /** Иконки MIME типов */
  MIMEApplication = 'MIMEApplication',
  MIMEAudio = 'MIMEAudio',
  MIMEImage = 'MIMEImage',
  MIMEMessage = 'MIMEMessage',
  MIMEModel = 'MIMEModel',
  MIMEMultipart = 'MIMEMultipart',
  MIMEText = 'MIMEText',
  MIMEVideo = 'MIMEVideo',
  MIMEOtherFiles = 'MIMEOtherFiles',

  History = 'History',

  /** Навигационная панель КБП */
  NavigationPanelParent = 'NavigationPanelParent',
  NavigationPanelChild = 'NavigationPanelChild',
}
