import * as React from 'react';
import { treeItemClassNamesMap } from '@common/components/data/tree-list-view/constants';
import { IItemIcon } from '@common/components/data/tree-list-view/TreeListViewModel';
import { StyledTreeItemContentIcon } from '@common/components/data/tree-list-view/styled/StyledTreeItem';

const ItemIcon: React.FC<IItemIcon> = ({ item, isIconExists }) => {
  const { icon, iconSize } = item;
  const { itemLabelIcon: itemLabelIconClassName } = treeItemClassNamesMap;

  if (!isIconExists) {
    return null;
  }

  return typeof icon === 'string' ? (
    <StyledTreeItemContentIcon
      icon={icon}
      className={itemLabelIconClassName}
      width={iconSize}
      height={iconSize}
    />
  ) : (
    icon
  );
};

export default ItemIcon;
