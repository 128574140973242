import { getThemeValue } from '@themes/utils/getters';
import { BaseThemedCssFunction, css, DefaultTheme } from 'styled-components';

export const disabled = <T extends { disabled?: boolean }>(
  style: ReturnType<BaseThemedCssFunction<DefaultTheme>>,
) => css<T>`
  &:disabled {
    ${style}
  }

  ${(props) => props.disabled && style}
`;

export const disabledTextColors = disabled(css`
  color: ${getThemeValue.paletteTextDisabled};
`);

export const disabledBackground = css`
  &:disabled {
    background-color: ${getThemeValue.paletteBgDisabled};
  }
`;
export const disabledColor = css`
  &:disabled {
    color: ${getThemeValue.paletteTextDisabled};
  }
`;
export const disableIconButton = css`
  &:disabled {
    color: ${getThemeValue.paletteIconDisabled};
  }
`;
export const focusColor = css`
  &:focus {
    outline: 2px solid ${getThemeValue.paletteBorderFocus};
    outline-offset: -2px;
    -moz-outline-radius: 2px;
    ${disabledBackground}

    // Изменено для работы полифила focus-visible
    // https://github.com/WICG/focus-visible
    &:not(.focus-visible):not([data-focus-visible-added]) {
      outline: none;
      ${disabledBackground}
    }
  }
`;
