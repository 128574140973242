import { IThemeTypography } from '@themes/styles/typography';
import { IPropsWithTheme } from '@themes/utils/getters/types';

export const currentTypography = <T extends IPropsWithTheme>(props: T): IThemeTypography =>
  props?.theme?.typography;

export const typographyGetters = {
  typographyFontFamily: (props: IPropsWithTheme) => currentTypography(props)?.fontFamily,
  typographyFontSize: (props: IPropsWithTheme) => currentTypography(props)?.fontSize,
  typographyLineHeight: (props: IPropsWithTheme) => currentTypography(props)?.lineHeight,
  typographyFontWeight: (props: IPropsWithTheme) => currentTypography(props)?.fontWeight,
  typographyH1: (props: IPropsWithTheme) => currentTypography(props)?.h1,
  typographyH2: (props: IPropsWithTheme) => currentTypography(props)?.h2,
  typographyH3: (props: IPropsWithTheme) => currentTypography(props)?.h3,
  typographyH4: (props: IPropsWithTheme) => currentTypography(props)?.h4,
  typographySubtitle1: (props: IPropsWithTheme) => currentTypography(props)?.subtitle1,
  typographySubtitle2: (props: IPropsWithTheme) => currentTypography(props)?.subtitle2,
  typographyLabel: (props: IPropsWithTheme) => currentTypography(props)?.label,
  typographyTextLean: (props: IPropsWithTheme) => currentTypography(props)?.text?.lean,
  typographyTextNormal: (props: IPropsWithTheme) => currentTypography(props)?.text?.normal,
  typographyTextMedium: (props: IPropsWithTheme) => currentTypography(props)?.text?.medium,
  typographyTextBold: (props: IPropsWithTheme) => currentTypography(props)?.text?.bold,
};
