import { IMap } from '@common/types/map';
import { MouseEvent } from '@common/types/mouseEvent';
import { DragUpdate, DropResult } from 'react-beautiful-dnd';
import * as React from 'react';
import { EIcon } from '@themes/types';
import {
  IStyledDndProps,
  ITreeListViewCustomization,
  ITreeListViewVirtualize,
  ITreeListViewVisibleRange,
} from '@common/components/data/tree-list-view/types/types';
import { IControlStyle as IControlStyleType } from '@common/types/control-style';
import { ICheckCanDrop } from '@common/types/drag-and-drop';
import { ECursor } from '@common/enums/cursor';

export enum ETreeListViewItemSelected {
  NOT_SELECTED = 'not-selected',
  SELECTED = 'selected',
  PART_CHILD = 'selected-partchild',
  PARENT_PART_CHILD = 'selected-parent-partchild',
}

export enum ETreeItemLocationType {
  DESTINATION = 'destination',
  COMBINE = 'combine',
}

export type TreeListViewItemKey = number | string;
export type TreeListViewDragEnd = (which: TreeListViewItemKey[], where: ITreeItemLocation) => void;

export type IControlStyle = Pick<IControlStyleType, 'size'>;

/** Интерфейс вариантов темизации, используется для ДнД */
export enum ITreeListViewVariant {
  /** Компактный вариант */
  Variant1 = 'variant1',
  /** Широкий вариант */
  Variant2 = 'variant2',
}

export interface ITreeListViewProps<T = any> {
  /** Массив итемов */
  data: ITreeItem<T>[];
  /**
   * @deprecated
   * Для стилизации использовать "styled"
   */
  className?: string;
  /** флаг, указывающий на то, учитывать ли частичное выделение потомков в стилях */
  parentsPartialSelection?: boolean;
  /** Ключ фокусного элемента. */
  focusedKey?: TreeListViewItemKey;
  /** Активация и настройки DND для TLV */
  dragAndDrop?: ITreeListViewDragAndDrop;
  /** Автоматическое управление активными и свернутыми элементами */
  autoSelectedAndCollapsed?: ITreeListViewAutoSelectedAndCollapsed;
  /** Виртуализация отображения дерева */
  virtualize?: boolean | ITreeListViewVirtualize;
  /** Скрывать иконки айтемов */
  hideIcons?: boolean;
  /** Функция вызова при клике на итем */
  onItemClick?: (
    key: TreeListViewItemKey,
    keys: TreeListViewItemKey[],
    event: MouseEvent,
    item: ITreeItem<T>,
  ) => void;
  /** Функция вызова при двойном клике на итем */
  onItemDoubleClick?: (key: TreeListViewItemKey) => void;
  /** Функция вызова при клике на стрелочку */
  onItemToggle?: (key: TreeListViewItemKey) => void;
  /** Функция вызова при клике правой кнопкой на итем */
  onItemRightClick?: (key: TreeListViewItemKey, object?: T) => void;
  /** Функция вызова при наведении мышки на итем */
  onItemHover?: (key: TreeListViewItemKey) => void;
  /** Функция вызова при уходе мышки на итем */
  onItemLeave?: (key: TreeListViewItemKey) => void;
  /** Функция вызова при нажатии на стрелки клавиатуры. Если задана, активирует навигацию стрелками */
  onKeyboardArrowDown?: (key: TreeListViewItemKey) => void;
  /**
   * Возвращает индексы видимых элементов в данный момент времени. Работает
   * только с включенным флагом virtualize.
   */
  onVisibleRangeChange?: (range: ITreeListViewVisibleRange) => void;
  /** Уникальный идентификатор для тестов. */
  testId?: string;
  /** Итем, к которому надо скроллить */
  scrollToItem?: ITreeItem;
  /** Текст сообщения отображаемого если в списке нет элементов */
  emptyText?: string;
  /** Кастомизация дерева */
  customization?: ITreeListViewCustomization;
  /** Показывать иконку экспандера справа */
  rightToggleIcon?: boolean;
  /** Флаг, скрывать ли иконки дочерних объектов. По умолчанию false - не скрывать. */
  hideChildIcons?: boolean;
  /**
   * Устанавливает родителям выбранного элемента selected в PART_CHILD
   * если в data лежат элементы, у которых selected в true или false, но хотелось бы еще видеть у каких именно родителей выбраны дети
   */
  calcDedicatedParents?: boolean;
  /** Вариант отображения */
  displayVariant?: ITreeListViewVariant;
  /** Отключаем горячие клавиши */
  disableHotkeys?: boolean;
}

export const defaultTreeListViewProps: Partial<ITreeListViewProps> = {
  displayVariant: ITreeListViewVariant.Variant1,
};

interface ITreeListItem {
  key: number;
}

export interface ITreeListState {
  elems: Array<ITreeListItem>;
  scrollToItem: ITreeListItem;
  scrollElem: ITreeListItem | null;
  scroll: boolean;
}

export interface ITreeListViewImplProps extends ITreeListViewProps {
  buildTreeData?: IBuiltTreeData;
  itemRenderer?: (item: IBuiltTreeItem) => JSX.Element;
}

export interface ITreeListViewDragAndDrop {
  /* Включить или выключить Drag And Drop */
  enabled?: boolean;
  /* Если больше одного дерева на странице или реализуется свой DnD context,
   * тогда необходимо для каждого TLV задать уникальное значение */
  uniqueDragAndDropId?: string;
  /* Если реализуется свой DnD context, то указываем этот флаг.
   * Он отключает внутренююю реализацию DragAndDrop в TLV */
  customDragAndDropContext?: boolean;
  /* Если нужно отключить "дроп" элемента на это дерево,
   * то сюда передать false. По умолчанию = true */
  dropEnabled?: boolean;
  /* Позволяет вставлять элементы в другие элементы как дочерние */
  embedEnabled?: boolean;
  /*  Автоматически определение того, какие элементы можно
   * тащить, а какие нет. Если нужно, чтобы DND ориентировался на поле
   * isDraggable, то сюда передать false */
  autoIsDraggable?: boolean;
  /* Если реализуется свой DnD context, то нужно в каждый TLV
   * передавать информацию об обновлении или окочании drag'а */
  dragUpdate?: DragUpdate;
  dragEnd?: DropResult;
  /* Вызывается когда пользователь завершил Drag And Drop */
  onDragEnd?: TreeListViewDragEnd;
  /* Функция для проверки может ли итем (который тащим) быть брошен над итемом (над которым протаскиваем) */
  checkCanDrop?: ICheckCanDrop;
  /* Флаг: выполнять dnd только для выбранных элементов (по умолчанию true) */
  dragBySelected?: boolean;
  /** Колбэк для возврата ключа, над которым производится ДнД. */
  getUpdateKey?: (key: TreeListViewItemKey) => void;
  /** Признак того, что при ДнД доступна только вставка внутрь элемента */
  onlyCombine?: boolean;
  /** Курсор, который применяется по умолчанию к дереву, когда включен ДнД */
  defaultDndCursor?: ECursor;
  /** Производить сдвиг элемента относительно курсора */
  isDndCloneOffset?: boolean;
  /** Тип анимации возврата элемента */
  animationType?: EDragAndDropAnimationType;
}

export enum EDragAndDropAnimationType {
  /** Анимация отменяется */
  None,
  /** Элемент возвращается на свою позицию */
  Default,
  /** Элемент перемещается туда, где его отпустили */
  Parent,
}

export interface ITreeListViewAutoSelectedAndCollapsed {
  /** Флаг: включить или выключить автоматическое управление активными и свернутыми элементами */
  enabled: boolean;
  /** Флаг: доступен ли мультивыбор или можно выбрать только 1 элемент */
  multiple?: boolean;
  /** Флаг: загружается ли дерево со свернутыми нодами */
  initiallyCollapsed?: boolean;
  /** Вызывается для проверки можно ли выбрать итем */
  canSelectItem?: (key: TreeListViewItemKey) => boolean;
}

export interface ITreeListViewItemAction {
  actionId: string;
  icon: EIcon | string;
  title?: string;
  disabled?: boolean;
  onClick?: (itemKey: TreeListViewItemKey, actionId: string, event: MouseEvent) => void;
}

export interface ITreeListViewItemProps<T = any>
  extends Pick<ITreeListViewProps, 'customization' | 'rightToggleIcon' | 'displayVariant'> {
  /* Объект-айтем */
  item: IBuiltTreeItem<T>;
  /* Ссылка на айтем в DOM */
  itemRef?: React.RefObject<HTMLDivElement>;
  /**
   * @deprecated
   * Для стилизации использовать "styled"
   */
  className?: string;
  /** Виртуализация отображения дерева */
  virtualize?: boolean;
  /** Скрывать иконку айтема */
  hideIcon?: boolean;
  /** Функция вызова при клике на итем */
  onItemClick: (key: TreeListViewItemKey, event: MouseEvent, item: ITreeItem) => void;
  /** Функция вызова при двойном клике на итем */
  onItemDoubleClick: (key: TreeListViewItemKey) => void;
  /** Функция вызова при клике на стрелочку */
  onItemToggle: (key: TreeListViewItemKey) => void;
  /** Функция вызова при клике правой кнопкой на итем */
  onItemRightClick: (key: TreeListViewItemKey, object?: T) => void;
  /** Функция вызова при наведении мышки на итем */
  onItemHover: (key: TreeListViewItemKey) => void;
  /** Функция вызова при уходе мышки на итем */
  onItemLeave: (key: TreeListViewItemKey) => void;
  /** Объект стилей для dnd */
  styledDndProps?: IStyledDndProps;
  /** Уникальный идентификатор для тестов. */
  testId?: string;
  cssStyle?: React.CSSProperties;
}

/**
 * Настройки отступов и высоты элемента дерева
 */
export interface ITreeListCommonSettings {
  childrenPadding?: number;
  childrenOffset?: number;
  rowHeight?: number;
  fontSize?: number;
  /** Вырезанный элемент */
  isCut?: true;
}

/**
 * Общие поля для элемента дерева и для собранного целиком дерева
 */
export interface ITreeListCommon<T = any> {
  key: TreeListViewItemKey;
  caption: any;
  parent: TreeListViewItemKey;
  collapsed?: boolean;
  icon?: string | EIcon | JSX.Element;
  object?: T;
  labelClass?: string;
  contentClass?: string;
  /**
   * @deprecated
   * Для стилизации использовать "styled"
   */
  className?: string;
  isDraggable?: boolean;
  index?: number;
  itemSettings?: ITreeListCommonSettings;
  /** Размер иконки */
  iconSize?: number;
}

/**
 * Элемент дерева
 */
export interface ITreeItem<T = any> extends ITreeListCommon<T> {
  children?: ITreeItem[];
  selected?: boolean | ETreeListViewItemSelected;
  hasChildren?: boolean;
  levelIndex?: number;
  selectable?: boolean;
  isDivider?: boolean;
}

/**
 * Элемент построенного дерева из data
 */
export interface IBuiltTreeItem<T = any> extends ITreeListCommon<T> {
  children?: IBuiltTreeItem[];
  selected: ETreeListViewItemSelected;
  selectable?: boolean;
  /** уровень вложенности элемента */
  nesting?: number;
  parentElement?: IBuiltTreeItem;
  draggableId?: string;
  isDivider?: boolean;
}

export interface IBuiltTreeData {
  tree: IBuiltTreeItem[];
  flat: IBuiltTreeItem[];
  selectedItems: IMap<IBuiltTreeItem>;
  draggableItems: IMap<IBuiltTreeItem>;
  itemByIndex: IMap<IBuiltTreeItem>;
  itemByDraggableId: IMap<IBuiltTreeItem>;
}

export interface ITreeItemLocation {
  type: ETreeItemLocationType;
  key: TreeListViewItemKey;
  parent: TreeListViewItemKey;
  index: number;
  uniqueDragAndDropId: string;
}

export type TreeIterator<T extends ITreeListCommon> = (
  value: T,
  index: TreeListViewItemKey,
  collection: T[],
) => void;

export type IItemIcon = Pick<ITreeListViewItemProps, 'item'> & { isIconExists: boolean };
