import * as React from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';
import { IContextMenuWrapperProps } from '@common/components/popups/context-menu-advance/types';

const integratedMenuStyles: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

export const ContextMenuAdvanceWrapper: React.FC<IContextMenuWrapperProps> = ({
  className,
  attributes,
  integrated,
  ...props
}: IContextMenuWrapperProps) => {
  const style = React.useMemo((): React.CSSProperties => {
    const attributesStyle = attributes?.style;
    if (integrated) {
      return { ...integratedMenuStyles, ...attributesStyle };
    }
    return attributesStyle;
  }, [integrated, attributes]);

  const newAttributes = React.useMemo((): React.HTMLAttributes<HTMLElement> => {
    const result = { ...attributes };
    if (style) {
      result.style = style;
    }
    if (className) {
      result.className = className;
    }
    return result;
  }, [className, attributes, style]);

  return <ContextMenuTrigger {...props} attributes={newAttributes} />;
};

export default ContextMenuAdvanceWrapper;
