import styled, { css } from 'styled-components';
import { getThemeValue } from '@themes/utils/getters';
import { IDividerProps } from '@common/components/data/divider/type';

const getSize = (props: IDividerProps) => {
  const { isVertical, size } = props;
  if (isVertical)
    return css`
      width: 1px;
      ${size && `height: ${size}`}
    `;
  return css`
    height: 1px;
    ${size && `width: ${size}`}
  `;
};

const getMargin = (props: IDividerProps) => {
  const { useFullMargin, margin, isVertical } = props;
  if (!margin) return undefined;
  if (useFullMargin)
    return css`
      margin: ${margin};
    `;
  if (isVertical)
    return css`
      margin: ${margin} 0;
    `;
  return css`
    margin: 0 ${margin};
  `;
};

const getPosition = (props: IDividerProps) => {
  const { isVertical, isAbsolutePosition, top, right, left, bottom } = props;
  if (!isAbsolutePosition) return undefined;
  if (isVertical)
    return css`
      position: absolute;
      top: 0;
      bottom: 0;
      ${right !== undefined && `right: ${right}px`}
      ${left !== undefined && `left: ${left}px`}
    `;
  return css`
    position: absolute;
    right: 0;
    left: 0;
    ${top !== undefined && `top: ${top}px`}
    ${bottom !== undefined && `bottom: ${bottom}px`}
  `;
};

export const StyledDivider = styled.div<IDividerProps>`
  display: flex;
  flex-shrink: 0;
  background: ${getThemeValue.paletteLineNormal};
  user-select: none;

  /** Вычисление размеров */
  ${getSize}

  /** Вычисление 'margin' */
  ${getMargin}
  
  /** Вычисление размеров для абсолютного позиционирования */
  ${getPosition}
`;
