import * as React from 'react';

import convert from 'react-from-dom';

import { canUseDOM, InlineSVGError, isSupportedEnvironment } from './helpers';

export interface IProps {
  baseURL?: string;
  cacheRequests?: boolean;
  children?: React.ReactNode;
  loader?: React.ReactNode;
  innerRef?: React.Ref<HTMLElement>;
  onError?: (error: InlineSVGError | IFetchError) => void;
  onLoad?: (src: string, isCached: boolean) => void;
  src: string;
  [key: string]: any;
}

export interface IState {
  element: React.ReactNode;
  hasCache: boolean;
  status: string;
}

export interface IFetchError extends Error {
  code: string;
  errno: string;
  message: string;
  type: string;
}

export interface IStorageItem {
  element: React.ReactNode;
  queue: any[];
  status: string;
}

export const STATUS = {
  FAILED: 'failed',
  LOADING: 'loading',
  PENDING: 'pending',
  READY: 'ready',
  UNSUPPORTED: 'unsupported',
};

const cacheStore: { [key: string]: IStorageItem } = Object.create(null);

export default class InlineSVG extends React.PureComponent<IProps, IState> {
  private _isMounted = false;

  private readonly hash: string;

  public static defaultProps = {
    cacheRequests: true,
  };

  constructor(props: IProps) {
    super(props);

    const cacheSvg = cacheStore[props.src];
    if (!!props.cacheRequests && !!cacheSvg && cacheSvg.status === STATUS.READY) {
      this.state = {
        element: cacheSvg.element,
        hasCache: true,
        status: STATUS.READY,
      };
    } else if (props.src.indexOf('<svg') >= 0) {
      this.getElement(props.src, true);
    } else {
      this.state = {
        element: null,
        hasCache: false,
        status: STATUS.PENDING,
      };
    }
  }

  public componentDidMount() {
    this._isMounted = true;

    if (!canUseDOM()) {
      this.handleError(new InlineSVGError('No DOM'));
      return;
    }

    const { status } = this.state;
    const { src } = this.props;

    try {
      if (status === STATUS.PENDING) {
        if (!isSupportedEnvironment()) {
          throw new InlineSVGError('Browser does not support SVG');
        }
        if (!src) {
          throw new InlineSVGError('Missing src');
        }
        this.load();
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (!canUseDOM()) {
      return;
    }

    const { hasCache, status } = this.state;
    const { onLoad, src } = this.props;

    if (prevState.status !== STATUS.READY && status === STATUS.READY) {
      onLoad?.(src, hasCache);
    }

    if (prevProps.src !== src) {
      if (!src) {
        this.handleError(new InlineSVGError('Missing src'));
        return;
      }

      if (src.indexOf('<svg') >= 0) {
        this.getElement(src);
        return;
      }

      const cacheSvg = cacheStore[src];
      if (cacheSvg) {
        this.updateStateFromCachedSvg(cacheSvg.element);
      } else {
        this.load();
      }
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private getNode(svgText: string): SVGSVGElement {
    let svg: SVGSVGElement;
    try {
      const node = convert(svgText, { nodeOnly: true });

      if (!node || !(node instanceof SVGSVGElement)) {
        throw new InlineSVGError('Could not convert the src to a DOM Node');
      }

      svg = node as SVGSVGElement;
    } catch (error) {
      this.handleError(error);
    }
    return svg;
  }

  private getElementNode(svgText: string) {
    const node = this.getNode(svgText) as Node;
    return convert(node);
  }

  private getElement(svgText: string, start = false) {
    try {
      const element = this.getElementNode(svgText);

      if (!element || !React.isValidElement(element)) {
        throw new InlineSVGError('Could not convert the src to a React element');
      }

      const state: IState = {
        hasCache: false,
        element,
        status: STATUS.READY,
      };
      if (start) {
        this.state = state;
      } else {
        this.setState(state);
      }
    } catch (error) {
      this.handleError(new InlineSVGError(error.message));
    }
  }

  private handleLoad = (content: string) => {
    if (!this._isMounted) {
      return;
    }
    this.getElement(content);
  };

  private handleError = (error: InlineSVGError | IFetchError) => {
    const { onError } = this.props;
    const status =
      error.message === 'Browser does not support SVG' ? STATUS.UNSUPPORTED : STATUS.FAILED;

    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
    }

    if (this._isMounted) {
      this.setState({ status }, () => {
        if (typeof onError === 'function') {
          onError(error);
        }
      });
    }
  };

  private request = () => {
    const { cacheRequests, src } = this.props;
    let result: Promise<void>;
    try {
      if (cacheRequests) {
        cacheStore[src] = { element: null, status: STATUS.LOADING, queue: [] };
      }

      result = fetch(src, {
        credentials: 'include',
      })
        .then((response) => {
          const contentType = response.headers.get('content-type');
          const [fileType] = (contentType || '').split(/ ?; ?/);

          if (response.status > 299) {
            throw new InlineSVGError('Not Found');
          }

          if (!['image/svg+xml', 'text/plain'].some((d) => fileType.indexOf(d) >= 0)) {
            throw new InlineSVGError(`Content type isn't valid: ${fileType}`);
          }

          return response.text();
        })
        .then((content) => {
          this.handleLoad(content);

          if (cacheRequests) {
            const cache = cacheStore[src];

            if (cache) {
              cache.element = this.getElementNode(content);
              cache.status = STATUS.READY;

              cache.queue = cache.queue.filter((cb: (content: string) => void) => {
                cb(content);

                return false;
              });
            }
          }
        })
        .catch((error) => {
          if (cacheRequests) {
            delete cacheStore[src];
          }
          this.handleError(error);
        });
    } catch (error) {
      this.handleError(new InlineSVGError(error.message));
    }
    return result;
  };

  private updateStateFromCachedSvg(element: React.ReactNode) {
    this.setState({
      hasCache: true,
      element,
      status: STATUS.READY,
    });
  }

  private load() {
    if (!this._isMounted) {
      return;
    }
    this.setState(
      {
        element: null,
        status: STATUS.LOADING,
      },
      () => {
        const { cacheRequests, src } = this.props;
        const cache = cacheRequests && cacheStore[src];

        if (cache) {
          if (cache.status === STATUS.LOADING) {
            cache.queue.push(this.handleLoad);
          }
          return;
        }

        const dataURI = src.match(/data:image\/svg[^,]*?(;base64)?,(.*)/);
        let inlineSrc;

        if (dataURI) {
          inlineSrc = dataURI[1] ? atob(dataURI[2]) : decodeURIComponent(dataURI[2]);
        } else if (src.indexOf('<svg') >= 0) {
          inlineSrc = src;
        }

        if (inlineSrc) {
          this.handleLoad(inlineSrc);
          return;
        }

        this.request();
      },
    );
  }

  public render() {
    if (!canUseDOM()) {
      return null;
    }

    const { element, status } = this.state;
    const { children = null, innerRef, loader = null, ...rest } = this.props;
    delete rest.cacheRequests;
    delete rest.src;

    if (element) {
      return React.cloneElement(element as React.ReactElement, { ref: innerRef, ...rest });
    }

    if ([STATUS.UNSUPPORTED, STATUS.FAILED].indexOf(status) > -1) {
      return children;
    }

    return loader;
  }
}
