import * as React from 'react';
import useSelected from './useSelected';
import Tree from './tree/Tree';
import Flat from './flat/Flat';
import { StyledDataWrapper } from '../styled';
import { IListProps } from './types';

const List: React.FC<IListProps> = ({
  isCollapsed,
  list,
  selectItem,
  selectedKey,
  hideTreeIcons,
  hideChildIcons,
  imagesPath,
  className,
}) => {
  const { selectMenuItem, collapsed, selected, handleCollapsed } = useSelected({
    selectItem,
    selectedKey,
    list,
  });
  return (
    <StyledDataWrapper className={className}>
      {!isCollapsed ? (
        <Tree
          list={list}
          selectItem={selectMenuItem}
          selected={selected}
          collapsed={collapsed}
          handleCollapsed={handleCollapsed}
          hideTreeIcons={hideTreeIcons}
          hideChildIcons={hideChildIcons}
          imagesPath={imagesPath}
        />
      ) : (
        <Flat
          list={list}
          selectItem={selectMenuItem}
          hideChildIcons={hideChildIcons}
          selected={selected}
          collapsed={collapsed}
          imagesPath={imagesPath}
        />
      )}
    </StyledDataWrapper>
  );
};

export default List;
