import { ITypography } from '@themes/styles/typography';
import * as React from 'react';
import styled from 'styled-components';

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'subtitle1'
  | 'subtitle2'
  | 'textNormal'
  | 'textMedium'
  | 'textBold'
  | 'label';

type ComponentType = 'h1' | 'h2' | 'h3' | 'h4' | 'span' | 'div' | 'p';

const variantThemeMapping = (themeTypo: Partial<ITypography> = {}) => ({
  h1: themeTypo.h1,
  h2: themeTypo.h2,
  h3: themeTypo.h3,
  h4: themeTypo.h4,
  subtitle1: themeTypo.subtitle1,
  subtitle2: themeTypo.subtitle2,
  textNormal: themeTypo.text?.normal,
  textMedium: themeTypo.text?.medium,
  textBold: themeTypo.text?.bold,
  label: themeTypo.label,
});

const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  subtitle1: 'h6',
  subtitle2: 'h6',
  textNormal: 'p',
  textMedium: 'p',
  textBold: 'p',
  label: 'span',
};

interface IProps {
  className?: string;
  /**
   * Вариант типографии из темы
   */
  variant?: Variant;
  /**
   * Компонент для которого будет применяться стиль
   * по умолчанию берется из мапы defaultVariantMapping
   */
  component?: ComponentType;
  /**
   * Запрет перевода текста на новую строку
   */
  noWrap?: boolean;
  /**
   * Выравнивание текста
   */
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  /**
   * При обрезания текста не отображать многоточие
   */
  noEllipsis?: boolean;
  /**
   * Кастомные настройки стиля, которые перетирают заданные.
   */
  style?: React.CSSProperties;
}

const TypographyRoot: React.FC<IProps> = (props) => {
  const { variant, component, className, style, children } = props;
  const componentTag = component || defaultVariantMapping[variant] || 'p';
  return React.createElement(componentTag, { className, style }, children);
};

TypographyRoot.defaultProps = {
  variant: 'textNormal',
  align: 'inherit',
  noWrap: false,
  noEllipsis: false,
};

const Typography = styled(TypographyRoot)<IProps>`
  ${(props) => ({
    margin: 0,
    ...variantThemeMapping(props.theme.typography)[props.variant],
    ...(props.align && {
      textAlign: props.align,
    }),
    ...(props.noWrap &&
      !props.noEllipsis && {
        textOverflow: 'ellipsis',
      }),
    ...(props.noWrap && {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }),
  })};
`;

export default Typography;
