import { IButtonProps } from '@common/components/controls/Button/types';
import ButtonBase from '@common/components/controls/ButtonBase';
import { getThemeValue } from '@themes/utils/getters';
import { paletteGetters } from '@themes/utils/getters/palette';
import { disabledColor, disableIconButton, focusColor } from '@themes/utils/pseudo-classes';
import { StyledSvg } from '@themes/components/Icon';
import styled, { css } from 'styled-components';
import { IIconButtonProps } from './types';

const createButtonBackground = () => css<IIconButtonProps>`
  ${(props) =>
    props?.useButtonBackground &&
    css`
      background-color: ${getThemeValue.paletteBgMenuHover};
    `}
`;

const createColorsStyles = () => css<IButtonProps>`
  ${() =>
    css<IIconButtonProps>`
      color: ${paletteGetters.paletteIconNormal};
      ${disabledColor}
      &:hover {
        color: ${getThemeValue.paletteIconHover};
        ${createButtonBackground()}
        ${disabledColor}
      }
      &:active {
        color: ${getThemeValue.paletteIconPressed};
        ${createButtonBackground()}
        ${disabledColor}
      }
      &:focus {
        outline: 2px solid ${getThemeValue.paletteBorderFocus};

        // Изменено для работы полифила focus-visible
        // https://github.com/WICG/focus-visible
        &:not(.focus-visible) {
          outline: none;
        }
      }
      ${(props) =>
        props.pressed &&
        css`
          ${props.useButtonBackground
            ? css`
                color: ${getThemeValue.paletteIconActive};
              `
            : css`
                color: ${getThemeValue.paletteBgShapeActive};
              `}
          background-color: ${getThemeValue.paletteBgMenuActive};
          ${disabledColor}
        `}
    `}
`;

const paddings = {
  xs: '1px',
  s: '2px',
  m: '3px',
  l: '6px',
};
const createSizeStyles = () => css<IButtonProps & { noPaddings: boolean }>`
  min-width: ${(props) => !props.noPaddings && getThemeValue.sizeControl(props, props.size)};
  height: ${(props) => !props.noPaddings && getThemeValue.sizeControl(props, props.size)};
  padding: ${(props) => !props.noPaddings && paddings[props.size]};
  ${StyledSvg} {
    width: 100%;
    height: 100%;
  }
`;

const createIconSizeStyles = () => css<IIconButtonProps>`
  min-width: ${(props) => getThemeValue.sizeIcon(props, props.iconSize)};
  max-height: ${(props) => getThemeValue.sizeIcon(props, props.iconSize)};
  ${StyledSvg} {
    width: 100%;
    height: 100%;
  }
`;

const createIconColorStyles = () => css<IButtonProps>`
  ${() =>
    css<IIconButtonProps>`
      color: ${paletteGetters.paletteIconNormal};
      ${disableIconButton}

      &:hover {
        color: ${getThemeValue.paletteIconHover};
        ${(props) =>
          props.useIconBackground &&
          css`
            background-color: ${getThemeValue.paletteBgMenuHover};
          `}
        ${disableIconButton}
      }
      ${(props) =>
        props.pressed &&
        css`
          color: ${getThemeValue.paletteIconPressed};
          ${props.useIconBackground &&
          css`
            background-color: ${getThemeValue.paletteBgMenuActive};
          `}
          ${disableIconButton}
        `}
    `}
`;

const StyledIconButton = styled(ButtonBase)<IIconButtonProps>`
  ${(props) => {
    /**
     * TODO: Доработать флаг
     * Флаг 'iconSize' отвечает за размер кнопки, при встраивании в компонент.
     * Нап. 'NumberInput'
     * Так как при встраивании необходимы размеры, находящиеся в EThemeIconSize
     */
    if (props.iconSize)
      return css`
        // SIZE
        ${createIconSizeStyles()}
        // COLORS
        ${createIconColorStyles()}
        border: none;
        ${focusColor}
      `;
    return css`
      // SIZE
      ${createSizeStyles()}
      // COLORS
      ${createColorsStyles()}
      ${focusColor}
    `;
  }}

  padding: 0;
`;

export default StyledIconButton;
