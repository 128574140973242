import * as React from 'react';
import { getDisplayName } from '@common/utils/getDisplayName';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { withSuspense } from '@common/hocs/withSuspense';
import { RelayAPI } from '@common/utils/suspenseUtils';
import {
  INsMap,
  WithTranslation,
  WithTranslationExOptions,
} from '@common/locales/utils/withLocales/types';
import utils from './i18next-utils';

function withLocales(
  nsMap: INsMap,
  defaultNs?: string | string[],
  options?: WithTranslationExOptions,
) {
  return <PS extends WithTranslation>(
    Component: React.ComponentType<PS>,
  ): React.ComponentType<Omit<PS, 'i18n' | 'tReady' | 't'>> => {
    const WrapperComponent: React.FC<PS> = (props: PS) => {
      const relayApiRef = React.useRef<RelayAPI>(null);

      // Инициализируем загрузчик локалей
      const loadLocale = React.useMemo(
        () =>
          utils.getLocalesLoader(nsMap, i18next.language, (newRelayApiInstance: RelayAPI) => {
            // как только загрузка локали будет инициирована, то экземпляр RelayApi обновится
            relayApiRef.current = newRelayApiInstance;
          }),
        [],
      );

      // Управляем подписками
      React.useEffect(() => {
        i18next.on('languageChanged', loadLocale);

        return () => {
          i18next.off('languageChanged', loadLocale);
          utils.removeAllCallbacks(nsMap);
        };
      }, []);

      relayApiRef.current?.checkRelayStatus();

      return <Component {...props} />;
    };
    WrapperComponent.displayName = `WithLocales(${getDisplayName(Component)})`;
    return withTranslation(
      defaultNs,
      options,
    )(withSuspense(WrapperComponent, options?.fallback, true));
  };
}

export default withLocales;
