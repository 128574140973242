import * as React from 'react';
import TreeDragAndDrop from '@common/components/data/tree-list-view/components/TreeDragAndDrop';
import TreeSelectedAndCollapsed from '@common/components/data/tree-list-view/components/TreeSelectedAndCollapsed';
import {
  ITreeListViewProps,
  ITreeListViewVariant,
} from '@common/components/data/tree-list-view/TreeListViewModel';
import TreeVirtualized from '@common/components/data/tree-list-view/components/TreeVirtualized';
import Tree from '@common/components/data/tree-list-view/components/Tree';
import { withComponentThemeProps } from '@themes';
import { componentName, withSlotClasses, WithTreeListViewSlot } from './styled/classes';

type IProps = ITreeListViewProps & WithTreeListViewSlot;

const TreeListView: React.FC<IProps> = (props): JSX.Element => {
  const enabledDND = props?.dragAndDrop?.enabled ?? false;
  const enableVirtualization = props?.virtualize ?? false;
  const enabledAutoSelectedAndCollapsed = props?.autoSelectedAndCollapsed?.enabled ?? false;
  const data = React.useMemo(
    () =>
      props.data?.length > 0 || !props?.emptyText
        ? props.data
        : [
            {
              caption: props?.emptyText,
              key: 0,
              parent: -1,
              selectable: false,
            },
          ],
    [props.data, props?.emptyText],
  );

  if (enabledAutoSelectedAndCollapsed) {
    return <TreeSelectedAndCollapsed {...props} data={data} />;
  }

  if (enabledDND) {
    return <TreeDragAndDrop {...props} data={data} />;
  }

  if (enableVirtualization) {
    return <TreeVirtualized {...props} data={data} />;
  }

  return <Tree {...props} data={data} />;
};

TreeListView.defaultProps = {
  data: [],
  displayVariant: ITreeListViewVariant.Variant1,
  onItemToggle: () => {
    //
  },
  onItemClick: () => {
    //
  },
  onItemHover: () => {
    //
  },
  onItemLeave: () => {
    //
  },
  onItemDoubleClick: () => {
    //
  },
  onItemRightClick: () => {
    //
  },
};

export default withComponentThemeProps(componentName, 'common')(withSlotClasses(TreeListView));
