import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import {
  space,
  color,
  layout,
  flexbox,
  position,
  LayoutProps,
  SpaceProps,
  ColorProps,
  FlexboxProps,
  PositionProps,
  BordersProps,
  borders,
  FontSizeProps,
  fontSize,
  fontFamily,
  FontFamilyProps,
  system,
} from 'styled-system';

const addingProperties = system({
  whiteSpace: true,
});

export type IBoxProps = {
  id?: string;
} & LayoutProps &
  SpaceProps &
  ColorProps &
  FlexboxProps &
  PositionProps &
  BordersProps &
  FontSizeProps &
  FontFamilyProps &
  React.HTMLProps<HTMLDivElement> &
  Pick<CSSProperties, 'whiteSpace'>;
const StyledBox = styled.div<IBoxProps>(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  space,
  color,
  layout,
  flexbox,
  position,
  borders,
  fontSize,
  fontFamily,
  addingProperties,
);

const Box: React.FC<IBoxProps> = StyledBox;

export default Box;
