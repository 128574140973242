import * as React from 'react';
import classNames from 'classnames';
import * as _ from 'lodash';
import memoizeOne from 'memoize-one';
import {
  Combine,
  DraggableId,
  DraggableLocation,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DraggingStyle,
  DragUpdate,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult,
  Position,
} from 'react-beautiful-dnd';
import { pluralForms } from '@common/utils/pluralForms';
import { IMap } from '@common/types/map';
import Item from '@common/components/data/tree-list-view/components/Item';
import Tree from '@common/components/data/tree-list-view/components/Tree';
import {
  EDragAndDropAnimationType,
  ETreeItemLocationType,
  IBuiltTreeData,
  IBuiltTreeItem,
  ITreeItem,
  ITreeItemLocation,
  ITreeListViewDragAndDrop,
  ITreeListViewImplProps,
  ITreeListViewVariant,
  TreeListViewItemKey,
} from '@common/components/data/tree-list-view/TreeListViewModel';
import { MouseEvent } from '@common/types/mouseEvent';
import { treeBuilder, treeListItemClick } from '@common/components/data/tree-list-view/utils';
import { IStyledDndProps } from '@common/components/data/tree-list-view/types/types';
import MultiDrag from '@common/components/data/multi-drag/MultiDrag';
import DndContext from '@common/components/data/dnd-context/DndContext';
import { ECursor } from '@common/enums/cursor';
import {
  TREE_LIST_VIEW_ROOT_ID,
  TREE_LIST_VIEW_UNIQUE_DND_ID,
  treeClassNamesMap,
  treeItemClassNamesMap,
} from '@common/components/data/tree-list-view/constants';
import { StyledCloneContainer } from './styled';
import { WithTreeListViewSlot } from '../styled/classes';
import DraggableArea from './DraggableArea';
import { StyledMultiCloneContainer } from '../styled/StyledTreeItem';

interface IState {
  dragUpdate: DragUpdate;
  dragEnd: DropResult;
  dragNotSelected: TreeListViewItemKey[];
}

type IProps = ITreeListViewImplProps & WithTreeListViewSlot;

export class TreeDragAndDrop extends React.Component<IProps, IState> {
  private buildTree = memoizeOne(
    (
      data: ITreeItem[],
      parentsPartialSelection: boolean,
      uniqueDragAndDropId: string,
      autoIsDraggable: boolean,
      dragBySelected: boolean,
      calcDedicatedParents?: boolean,
    ): IBuiltTreeData =>
      treeBuilder(
        data,
        parentsPartialSelection,
        {
          uniqueDragAndDropId,
          autoIsDraggable,
          dragBySelected,
        },
        calcDedicatedParents,
      ),
  );

  private updateDragObject = memoizeOne((dragObject: DragUpdate | DropResult):
    | DragUpdate
    | DropResult => {
    const { combine, destination, source } = dragObject;
    const srcIndex = source?.index;
    // смотрим направление ДнД. Если направление вверх дерева - то правим объект dragObject, чтобы выделение драга шло в верном порядке.
    if (combine) {
      const combineProps = this.byCombine(combine);
      const dstIndex = combineProps?.index;
      const dragDown = dstIndex > srcIndex;
      // определяем направление драга
      if (!dragDown) {
        dragObject.destination = { droppableId: combine.droppableId, index: dstIndex };
        dragObject.combine = null;
      }
    } else {
      const destinationProps = this.byLocation(destination);
      const dstIndex = destinationProps?.index;
      const dragDown = dstIndex > srcIndex;

      if (!dragDown) {
        dragObject.combine = {
          droppableId: destination?.droppableId,
          draggableId: destinationProps?.draggableId,
        };
        dragObject.destination = null;
      }
    }
    return dragObject;
  });

  private getDraggableKeys = memoizeOne((dragObject: DragUpdate): TreeListViewItemKey[] => {
    if (this.props.dragAndDrop?.dragBySelected) {
      return [];
    }
    const srcIndex = dragObject.source.index;
    const draggableKey = this.flat()[srcIndex].key;
    const selectedKeys = Object.keys(this.selectedItems());
    const isDraggableSelected = selectedKeys.includes(String(draggableKey));
    // Если перетягиваемый элемент в списке выделеных - то считаем что драгаем все выделеные. Иначе - драгаем только тот, который схватили.
    if (isDraggableSelected) {
      return selectedKeys;
    }
    return [draggableKey];
  });

  private onDragUpdate = memoizeOne((dragUpdate: DragUpdate): void => {
    const correctDragUpdate = this.updateDragObject(dragUpdate);
    const newDragNotSelected = this.getDraggableKeys(correctDragUpdate);

    this.setState({
      dragUpdate: correctDragUpdate,
      dragEnd: null,
      dragNotSelected: newDragNotSelected,
    });
  });

  // позиция курсора перемещаемого объекта
  protected refPosition: React.MutableRefObject<Position>;

  protected setRefPosition = memoizeOne((result: Position) => {
    this.refPosition.current = result;
  });

  public constructor(props: ITreeListViewImplProps) {
    super(props);
    this.state = {
      dragUpdate: null,
      dragEnd: null,
      dragNotSelected: [],
    };
    this.refPosition = React.createRef();
  }

  private dragAndDrop = (): ITreeListViewDragAndDrop => {
    const { dragAndDrop } = this.props;
    const { dragUpdate, dragEnd } = this.state;

    const customDragAndDropContext = dragAndDrop?.customDragAndDropContext ?? false;

    return {
      enabled: dragAndDrop?.enabled ?? false,
      uniqueDragAndDropId: dragAndDrop?.uniqueDragAndDropId ?? TREE_LIST_VIEW_UNIQUE_DND_ID,
      customDragAndDropContext,
      embedEnabled: dragAndDrop?.embedEnabled ?? true,
      dropEnabled: dragAndDrop?.dropEnabled ?? true,
      autoIsDraggable: dragAndDrop?.autoIsDraggable ?? true,
      dragUpdate: customDragAndDropContext ? dragAndDrop?.dragUpdate : dragUpdate,
      dragEnd: customDragAndDropContext ? dragAndDrop?.dragEnd : dragEnd,
      onDragEnd: dragAndDrop?.onDragEnd ?? null,
      checkCanDrop: dragAndDrop?.checkCanDrop ?? null,
      dragBySelected: dragAndDrop?.dragBySelected ?? true,
      getUpdateKey: dragAndDrop?.getUpdateKey ?? null,
      onlyCombine: dragAndDrop?.onlyCombine ?? false,
      defaultDndCursor: dragAndDrop?.defaultDndCursor ?? ECursor.Grab,
      isDndCloneOffset: !!dragAndDrop?.isDndCloneOffset,
      animationType: dragAndDrop?.animationType ?? EDragAndDropAnimationType.Parent,
    };
  };

  private which = (): TreeListViewItemKey[] => {
    const { dragEnd, dragBySelected } = this.dragAndDrop();
    const source = this.byLocation(dragEnd.source);
    if (source && dragBySelected) {
      return this.draggableItemsKeys();
    }
    if (!dragBySelected && this.state.dragNotSelected.length) {
      return this.state.dragNotSelected;
    }
    return null;
  };

  private where = (): ITreeItemLocation => {
    const { dragEnd, uniqueDragAndDropId } = this.dragAndDrop();

    const destination = this.byLocation(dragEnd.destination);
    if (destination) {
      return {
        type: ETreeItemLocationType.DESTINATION,
        key: destination?.key,
        parent: destination?.parent ?? TREE_LIST_VIEW_ROOT_ID,
        index: destination?.index ?? -1,
        uniqueDragAndDropId,
      };
    }

    const combine = this.byCombine(dragEnd.combine);
    if (combine) {
      return {
        type: ETreeItemLocationType.COMBINE,
        key: combine?.key,
        parent: combine?.parent,
        index: 0,
        uniqueDragAndDropId,
      };
    }

    return null;
  };

  public componentDidUpdate = (prevProps: Readonly<ITreeListViewImplProps>): void => {
    const { dragEnd, onDragEnd } = this.dragAndDrop();

    if (!dragEnd || _.isEqual(dragEnd, prevProps.dragAndDrop?.dragEnd)) {
      return;
    }

    const which: TreeListViewItemKey[] = this.which();
    const where: ITreeItemLocation = this.where();
    if (onDragEnd && (which || where)) {
      onDragEnd(which, where);
      this.onDragEnd(null);
    }
  };

  private treeData = (): IBuiltTreeData => {
    const { data, parentsPartialSelection, calcDedicatedParents } = this.props;
    const { uniqueDragAndDropId, autoIsDraggable, dragBySelected } = this.dragAndDrop();

    return this.buildTree(
      data,
      parentsPartialSelection,
      uniqueDragAndDropId,
      autoIsDraggable,
      dragBySelected,
      calcDedicatedParents,
    );
  };

  private selectedItems = (): IMap<IBuiltTreeItem> => this.treeData()?.selectedItems;

  private selectedCount = (): number => Object.keys(this.selectedItems()).length;

  private draggableItems = (): IMap<IBuiltTreeItem> => this.treeData()?.draggableItems;

  private draggableItemsKeys = (): TreeListViewItemKey[] => _.map(this.draggableItems(), 'key');

  private draggableCount = (): number => Object.keys(this.draggableItems()).length;

  private flat = (): IBuiltTreeItem[] => this.treeData()?.flat;

  private byDraggableId = (draggableId: string): IBuiltTreeItem =>
    this.treeData()?.itemByDraggableId?.[draggableId];

  private byCombine = (combine: Combine): IBuiltTreeItem => {
    const { uniqueDragAndDropId } = this.dragAndDrop();
    if (combine?.droppableId !== uniqueDragAndDropId) {
      return null;
    }

    return this.byDraggableId(combine?.draggableId);
  };

  private byLocation = (location: DraggableLocation): IBuiltTreeItem => {
    const { uniqueDragAndDropId } = this.dragAndDrop();
    if (location?.droppableId !== uniqueDragAndDropId) {
      return null;
    }

    return this.treeData()?.itemByIndex?.[location?.index];
  };

  private onClick = (key: TreeListViewItemKey, event: MouseEvent, item: ITreeItem): void => {
    (event?.currentTarget as HTMLElement)?.focus();
    const { data, focusedKey, onItemClick } = this.props;
    treeListItemClick(key, event, data, focusedKey, onItemClick, this.treeData().tree, item);
  };

  private handleItemClick = (
    key: TreeListViewItemKey,
    _keys: TreeListViewItemKey[],
    event: MouseEvent,
    item: ITreeItem,
  ): void => {
    this.onClick(key, event, item);
  };

  private renderItem = (
    item: IBuiltTreeItem,
    ghost = false,
    clone = false,
    isDragging?: boolean,
  ): JSX.Element => {
    const {
      testId,
      onItemHover,
      onItemLeave,
      onItemDoubleClick,
      onItemToggle,
      onItemRightClick,
      displayVariant,
    } = this.props;

    const {
      uniqueDragAndDropId,
      dragUpdate: rawDragUpdate,
      checkCanDrop,
      getUpdateKey,
      onlyCombine,
      defaultDndCursor,
    } = this.dragAndDrop();

    if (!item) {
      return <></>;
    }

    let result = {
      canDropAsSibling: true,
      canDropAsChild: true,
    };

    const dragUpdate = rawDragUpdate && { ...rawDragUpdate };
    // Для режима вставки "только внутрь" правим dragUpdate
    if (onlyCombine && dragUpdate && dragUpdate.destination) {
      const destinationIndex = dragUpdate.destination.index;
      const draggableId = this.flat()[destinationIndex]?.draggableId;
      dragUpdate.combine = {
        droppableId: dragUpdate.destination.droppableId,
        draggableId,
      };
      dragUpdate.destination = null;
    }

    if (dragUpdate) {
      const from = this.treeData()?.itemByIndex?.[dragUpdate.source.index]?.key;
      const to = item.key;

      if (
        dragUpdate.destination?.index === item.index ||
        dragUpdate.combine?.draggableId === item.draggableId
      ) {
        getUpdateKey?.(to);
        result = checkCanDrop ? checkCanDrop(from, to) : result;
      }
    }

    const sourceDroppableId = dragUpdate?.source?.droppableId;
    const sourceIndex = dragUpdate?.source?.index;

    const destinationDroppableId = dragUpdate?.destination?.droppableId;
    const destinationIndex = dragUpdate?.destination?.index;

    const combineDroppableId = dragUpdate?.combine?.droppableId;
    const combineDraggableId = dragUpdate?.combine?.draggableId;

    const itemIndex = item.index;

    const source = sourceDroppableId === uniqueDragAndDropId && sourceIndex === itemIndex;
    const destination =
      result.canDropAsSibling &&
      destinationDroppableId === uniqueDragAndDropId &&
      destinationIndex === itemIndex;
    const combine =
      result.canDropAsChild &&
      combineDroppableId === uniqueDragAndDropId &&
      combineDraggableId === item?.draggableId;

    const { item: itemClassName, itemDnd: itemDndClassName } = treeItemClassNamesMap;
    const itemClasses = classNames(itemClassName, {
      [itemDndClassName]: item.isDraggable,
      [`${itemDndClassName}-clone`]: clone,
      [`${itemDndClassName}-ghost`]: ghost,
      [`${itemDndClassName}-source`]: source,
      [`${itemDndClassName}-destination`]: destination,
      [`${itemDndClassName}-combine`]: combine,
    });

    const styledProps: IStyledDndProps = {
      isDragging,
      clone,
      ghost,
      source,
      destination,
      combine,
      cursor: defaultDndCursor,
    };

    return (
      <Item
        item={item}
        className={itemClasses}
        onItemClick={this.onClick}
        onItemRightClick={onItemRightClick}
        onItemDoubleClick={onItemDoubleClick}
        onItemToggle={onItemToggle}
        onItemHover={onItemHover}
        onItemLeave={onItemLeave}
        testId={testId}
        styledDndProps={styledProps}
        displayVariant={displayVariant}
      />
    );
  };

  private renderMultiDraggableItems = (caption: JSX.Element): JSX.Element => (
    <StyledMultiCloneContainer>{caption}</StyledMultiCloneContainer>
  );

  private renderDraggable = (draggingId: DraggableId, isDragging: boolean) => (
    item: IBuiltTreeItem,
  ): JSX.Element => {
    const selectedKeys = Object.keys(this.selectedItems());
    const isMultiDrag =
      !!draggingId && selectedKeys?.length > 1 && selectedKeys.includes(item?.key?.toString?.());

    if (draggingId === item?.draggableId || isMultiDrag) {
      return this.renderItem(item, true, false, isDragging);
    }
    const renderedItem = this.renderItem(item, false, false, isDragging);
    const { displayVariant } = this.props;
    const isDefaultVariant = displayVariant === ITreeListViewVariant.Variant1;
    return (
      <DraggableArea
        item={item}
        RenderedItem={renderedItem}
        isDefaultVariant={isDefaultVariant}
        draggingId={draggingId}
        handlerBeforeCapture={this.setRefPosition}
      />
    );
  };

  private renderMultiDrag = (count: number): JSX.Element => {
    const { displayVariant } = this.props;
    const isDefaultVariant = displayVariant === ITreeListViewVariant.Variant1;
    if (isDefaultVariant) {
      return <MultiDrag count={count} />;
    }
    return (
      <span>
        <strong>{count}</strong>
        &nbsp;
        <span>{pluralForms(count, 'элемент', 'элемента', 'элементов')}</span>
      </span>
    );
  };

  private getDraggableStyle = (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
    const { isDndCloneOffset, animationType } = this.dragAndDrop();
    const draggableStyle = provided.draggableProps.style as DraggingStyle;
    if (draggableStyle.opacity) {
      draggableStyle.opacity = 1;
    }

    /** Если DnD завершен */
    if (snapshot?.isDropAnimating) {
      /** Корректируем координаты перемещения у анимации */
      const { dropAnimation } = snapshot;
      const { scale: dropScale, moveTo } = dropAnimation;
      const scale = dropScale ? ` scale(${dropScale})` : '';

      /** Возврат элемента на исходную позицию */
      if (animationType === EDragAndDropAnimationType.Default)
        draggableStyle.transform = `translate(0, 0)${scale}`;

      /** Возвращение элемента на родительский элемент, например перемещение объекта в папку в навигаторе */
      if (animationType === EDragAndDropAnimationType.Parent)
        draggableStyle.transform = `translate(0, ${moveTo.y}px)${scale}`;

      /** Отключение анимации возврата элемента, напрмер в отчёте при перетаскивании источника на табшит */
      if (animationType === EDragAndDropAnimationType.None) {
        draggableStyle.opacity = 0;
        (draggableStyle.transition as any) = 'transform 0.001s';
      }

      return draggableStyle;
    }

    /** Смещениие элемента в правый нижний угол, относительно курсора */
    if (isDndCloneOffset && this.refPosition.current) {
      const { x, y } = this.refPosition.current;
      draggableStyle.left = x;
      draggableStyle.top = y;
    }

    return draggableStyle;
  };

  private renderClone = (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    rubric: DraggableRubric,
  ): JSX.Element => {
    const isDragging = snapshot?.isDragging ?? false;
    const draggableId = rubric?.draggableId ?? '';

    const draggable: IBuiltTreeItem = this.byDraggableId(draggableId);
    const draggableCount = this.draggableCount();
    const { dragBySelected } = this.dragAndDrop();

    if (!isDragging || draggableCount <= 0 || !draggable) {
      return null;
    }

    const { displayVariant } = this.props;
    const isDefaultVariant = displayVariant === ITreeListViewVariant.Variant1;
    const draggableStyle = this.getDraggableStyle(provided, snapshot);
    return (
      <StyledCloneContainer
        isDefaultVariant={isDefaultVariant}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={draggableStyle}
      >
        {draggableCount <= 1 || !dragBySelected
          ? this.renderItem(draggable, false, true, true)
          : this.renderMultiDraggableItems(this.renderMultiDrag(draggableCount))}
      </StyledCloneContainer>
    );
  };

  private onDragEnd = (dragEnd: DropResult): void => {
    if (!dragEnd) {
      this.setState({
        dragUpdate: null,
        dragEnd,
      });
      return;
    }
    const correctDragEnd = this.updateDragObject(dragEnd);
    this.setState({
      dragUpdate: null,
      dragEnd: correctDragEnd as DropResult,
    });
  };

  private renderTree = (): JSX.Element => {
    const { embedEnabled, dropEnabled, uniqueDragAndDropId } = this.dragAndDrop();
    const { className, testId } = this.props;

    return (
      <Droppable
        isDropDisabled={!dropEnabled}
        droppableId={uniqueDragAndDropId}
        isCombineEnabled={embedEnabled}
        mode="standard"
        renderClone={this.renderClone}
      >
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot): JSX.Element => {
          const dragging = !!snapshot.draggingFromThisWith || snapshot.isDraggingOver;

          const { tree: treeClassName, treeDnd: treeDndClassName } = treeClassNamesMap;
          const classes = classNames(treeClassName, treeDndClassName, {
            [`${treeDndClassName}-dragging-over`]: dragging,
            [className]: className,
          });

          return (
            <div
              className={classes}
              ref={provided.innerRef}
              {...provided.droppableProps}
              data-testid={testId}
            >
              <Tree
                itemRenderer={this.renderDraggable(snapshot?.draggingFromThisWith, dragging)}
                buildTreeData={this.treeData()}
                {...this.props}
                onItemClick={this.handleItemClick}
              />
            </div>
          );
        }}
      </Droppable>
    );
  };

  public render(): JSX.Element {
    const { enabled, customDragAndDropContext } = this.dragAndDrop();

    if (!enabled) {
      return null;
    }

    if (customDragAndDropContext) {
      return this.renderTree();
    }

    return (
      <DndContext
        onDragUpdate={this.onDragUpdate}
        onDragEnd={this.onDragEnd}
        selectedCount={this.selectedCount()}
      >
        {this.renderTree()}
      </DndContext>
    );
  }
}

export default TreeDragAndDrop;
