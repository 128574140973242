export enum EPaletteMode {
  Light = 'light',
  Dark = 'dark',
}

export interface BorderColor {
  normal: string;
  hover: string;
  active: string;
  focus: string;
  disabled: string;
  error: string;
}

export interface TypeText {
  // Обычный цвет текста
  normal: string;
  // цвет лейблов
  label: string;
  // Отключенный текст
  disabled: string;
  // Цвет описания
  description: string;
  // Цвет ошибки
  error: string;
  // Цвет ссылки
  link: string;
  // Цвет текста на кнопке
  buttonPrimary: string;
}

export interface TypeIcon {
  // Обычный цвет иконки
  normal: string;
  // цвет наведения
  hover: string;
  // цвет активированной(уже зажатой) иконки
  pressed: string;
  // цвет зажатой мышью иконки
  active: string;
  // Цвет отключенной иконки
  disabled: string;
}

export type TypeDivider = string;
export interface TypeBackground {
  default: string;
  disabled: string;
  panel: string;
  panelSecond: string;
  // shape
  shapeHover: string;
  shapeActive: string;
  // table
  tableHover: string;
  tableActive: string;
  // menu
  menuHover: string;
  menuActive: string;
  // list
  listHover: string;
  listActive: string;
}

export interface TypeLine {
  normal: string;
  hover: string;
  focus: string;
}

export interface IThemePalette {
  border?: Partial<BorderColor>;
  icon?: Partial<TypeIcon>;
  text?: Partial<TypeText>;
  divider?: TypeDivider;
  background?: Partial<TypeBackground>;
  line?: Partial<TypeLine>;
}

export enum EThemePaletteColors {
  main = 'main',
  dark = 'dark',
  white = 'white',
  green = 'green',
  red = 'red',
  blue = 'blue',
  orange = 'orange',
  yellow = 'yellow',
  pink = 'pink',
  teal = 'teal',
  indigo = 'indigo',
  purple = 'purple',
}
export enum EThemePaletteBrightNumber {
  'c9' = 9,
  'c8' = 8,
  'c7' = 7,
  'c6' = 6,
  'c5' = 5,
  'c4' = 4,
  'c3' = 3,
  'c2' = 2,
  'c1' = 1,
  'c0' = 0,
  'c-1' = -1,
  'c-2' = -2,
  'c-3' = -3,
  'c-4' = -4,
  'c-5' = -5,
  'c-6' = -6,
  'c-7' = -7,
  'c-8' = -8,
  'c-9' = -9,
}

export type IThemePaletteColorsWithBright = Partial<
  {
    [k in EThemePaletteColors]: Partial<
      {
        [b in EThemePaletteBrightNumber]: string;
      }
    >;
  }
>;

export type IThemePaletteColors = {
  [k in EThemePaletteColors]: string;
};
