import Box from '@common/components/layout/box';
import styled, { css } from 'styled-components';
import Button from '@common/components/controls/Button';
import { ButtonDropdown } from '@common/components/controls/button-dropdown';
import { StyledButton } from '@common/components/controls/button-dropdown/StyledButtonDropdown';
import { StyledButtonLabel } from '@common/components/controls/Button/StyledButton';
import TreeListView from '@common/components/data/tree-list-view/TreeListView';
import { IButtonDropdownProps } from '@common/components/controls/button-dropdown/types';
import { IPanelCollapsed, IPanelWrapper } from './types';

export const Head = styled(Box)`
  padding: 16px;
  height: 65px;
  position: relative;
  flex-shrink: 0;
`;
export const Body = styled(Box)<IPanelCollapsed>`
  flex-grow: 1;
  overflow-y: ${({ collapsed }) => (collapsed ? 'hidden' : 'auto')};
  overflow-x: hidden;
`;
export const MenuButton = styled(ButtonDropdown)<IButtonDropdownProps>`
  position: relative;
  flex-grow: 1;
  max-width: unset;
  ${StyledButton} {
    border-radius: 0;
    max-width: unset;

    ${(props) =>
      props.pressed &&
      css`
        background: #ebebeb;
      `}

    ${StyledButtonLabel} {
      text-align: left;
    }
  }
`;

export const UserButton = styled(MenuButton)``;
export const CollapseButton = styled(Button)`
  border-radius: 0;
`;
export const Footer = styled(Box)`
  display: flex;
`;

export const StyledDataWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled(Box)<IPanelWrapper>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  height: 100%;
  background-color: #f3f3f3;

  ${({ integrated, collapsed }) => {
    if (integrated) {
      return undefined;
    }
    const width = collapsed ? '56px' : '256px';
    return css`
      width: ${width};
    `;
  }}

  ${Footer} {
    flex-direction: ${({ collapsed }) => (collapsed ? 'column' : 'row')};
    ${({ collapsed }) =>
      !collapsed &&
      css`
        ${CollapseButton} {
          width: 56px;
        }
      `}
  }
`;

export const StyledTreeListView = styled(TreeListView)`
  background-color: transparent;
  padding-left: 16px;
  padding-right: 16px;

  & > *::after {
    border-top: 1px solid #e0e0e0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    content: '';
  }
`;

export const FlatChildItem = styled.div<{ isSelected: boolean }>`
  color: ${({ isSelected }) => (isSelected ? '#268FCB' : '#212529')};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
`;

export const StyledScrollButton = styled(Button)`
  width: 56px;
  height: 24px;
  z-index: 1;
  color: #5f6265;
  border-radius: 0;
  background-color: #f3f3f3;
  &:hover {
    color: #268fcb;
    background-color: #f3f3f3;
  }
`;

export const StyledTransparentBlock = styled.div`
  height: 18px;
  width: 100%;
  background: linear-gradient(180deg, #f3f3f3 0%, rgba(243, 243, 243, 0) 60%);
`;
