import { nanoid } from '@reduxjs/toolkit';
import * as React from 'react';
import cn from 'classnames';
import { EIcon } from '@themes';
import {
  IButtonDropdownProps,
  IPosition,
  EButtonDropdownVariant,
  IconPosition,
  IAlign,
} from '@common/components/controls/button-dropdown/types';
import { showMenu, hideMenu } from '@common/components/popups/context-menu-advance';
import { useClasses } from '@common/components/controls/button-dropdown/classes';
import { IMouseEventHandler } from '@common/types/mouseEvent';
import { IContextMenuAdvancedData } from '@common/components/popups/context-menu-advance/types';

const defaultIcon = EIcon.ActionsArrowSelect;

/** Расчёт координат конт. меню при отображении справа от кнопки
 * @param {number} x x кнопки
 * @param {number} y y кнопки
 * @param {number} width ширина кнопки
 */
const getRightCoordinates = (x: number, y: number, width: number) => {
  const xPos = x + width;
  return { x: xPos, y };
};

/** Расчёт координат конт. меню при отображении под кнопкой
 * @param {number} x x кнопки
 * @param {number} y y кнопки
 * @param {number} width ширина кнопки
 * @param {number} height высота кнопки
 * @param {number} documentWidth ширина документа
 * @param {number} menuWidth ширина меню
 * @param {IAlign} align выравнивание меню
 */
const getBottomCoordinates = (
  x: number,
  y: number,
  width: number,
  height: number,
  documentWidth: number,
  documentHeight: number,
  menuWidth: number,
  menuHeight: number,
  align: IAlign,
) => {
  // проверяем, влазит ли меню в ширину кнопки
  const isMenuOversize = width < menuWidth;
  // проверяем, вылезает ли меню за пределы документа
  const isMenuOverXflow = documentWidth - x - menuWidth < 0;
  const isMenuOverYflow = documentHeight - y - menuHeight < 0;
  const alignX = align === 'right' ? x - menuWidth + width : x;
  const xPos = isMenuOversize && !isMenuOverXflow ? alignX : x + width - menuWidth;
  const yPos = isMenuOverYflow ? y - menuHeight : y + height;
  return { x: xPos, y: yPos };
};

const getCoordinates: Record<IPosition, Function> = {
  left: getBottomCoordinates,
  right: getRightCoordinates,
  top: getBottomCoordinates,
  bottom: getBottomCoordinates,
};

const useButtonMenu = (
  {
    items,
    onItemClick,
    isDividedMode,
    menuButtonCaption,
    showOnHover,
    position,
    caption,
    onlyIcon,
    onShow,
    onHide,
    displayVariant,
    icon,
    iconPosition,
    hideOnLeave,
    onMouseLeave,
    onClick,
    onItemTypeClick,
    align,
  }: IButtonDropdownProps,
  slots: ReturnType<typeof useClasses>,
) => {
  const [isMenuOpened, setMenuOpened] = React.useState(false);
  // уникальный id для меню
  const menuId = React.useMemo(() => nanoid(), []);
  // уникальный
  const menuClassId = slots.menu + menuId;
  const uniqueMenuClass = React.useMemo(() => cn(slots.menu, menuClassId), [menuClassId]);
  const container = React.useRef<HTMLDivElement>(null);

  // метод показа контекстного меню
  const handleShow = React.useCallback(() => {
    const { x, y, width, height } = container.current?.getBoundingClientRect(); // координаты кнопки

    // получаем ширину меню чтобы понять как его спозиционировать относительно кнопки
    const menuElement = document.getElementsByClassName(uniqueMenuClass);

    const { clientWidth: menuWidth = 0, clientHeight: menuHeight = 0 } = menuElement?.[0] ?? {};
    const { clientWidth: docWidth, clientHeight: docHeight } = document.body;

    const coordinates = getCoordinates[position]?.(
      x,
      y,
      width,
      height,
      docWidth,
      docHeight,
      menuWidth,
      menuHeight,
      align,
    );

    showMenu({
      position: coordinates,
      id: menuId,
    });
  }, [container?.current]);

  const handleClick = React.useCallback<IMouseEventHandler<{}, HTMLButtonElement>>(
    (event, args) => {
      !isDividedMode && onClick?.(event, args);
      handleShow();
    },
    [handleShow, onClick],
  );

  const handleMouseOver = React.useCallback(() => {
    if (showOnHover) {
      handleShow();
    }
  }, [showOnHover, handleShow]);

  const menuData = React.useMemo(
    (): IContextMenuAdvancedData => ({ items, onItemClick, onItemTypeClick }),
    [items, onItemClick, onItemTypeClick],
  );

  const menuHeader = onlyIcon && items?.length ? caption : undefined;

  const isDefThemeVariant = displayVariant === EButtonDropdownVariant.variant1;

  const currentIcon = icon || defaultIcon;

  // Если задана позиция иконки - то выставляем её, иначе берём из тем
  const buttonIcon = React.useMemo(() => {
    switch (iconPosition) {
      case IconPosition.LEFT:
        return { iconLeft: currentIcon };
      case IconPosition.RIGHT:
        return { iconRight: currentIcon };
      default:
        return isDefThemeVariant ? { iconRight: currentIcon } : { iconLeft: currentIcon };
    }
  }, [iconPosition, displayVariant, currentIcon]);

  const showHandler = React.useCallback(
    // Тип any из события ContextMenuAdvance
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      onShow?.(event?.detail?.id);
      setMenuOpened(true);
    },
    [onShow, setMenuOpened],
  );

  const hideHandler = React.useCallback(() => {
    onHide?.();
    setMenuOpened(false);
  }, [setMenuOpened]);

  const leaveHandler = React.useCallback<IMouseEventHandler<any, HTMLButtonElement>>(
    (event) => {
      const isHoverMenu = (event.relatedTarget as Element)?.closest?.(`.${menuClassId}`);
      if (!isHoverMenu && hideOnLeave) {
        hideMenu(menuId);
      }
      onMouseLeave?.(event);
    },
    [onMouseLeave, hideOnLeave, menuClassId, menuId, hideMenu],
  );

  return {
    menuId,
    container,
    handleClick,
    handleMouseOver,
    menuData,
    uniqueMenuClass,
    menuHeader,
    isMenuOpened,
    menuButtonCaption,
    hideHandler,
    showHandler,
    buttonIcon,
    isDefThemeVariant,
    leaveHandler,
  };
};

export default useButtonMenu;
