import { IMap } from '@common/types/map';
import { defaultConf } from '@themes/default-theme';
import { ITheme } from '@themes/styles';
import { getThemeValue } from '@themes/utils/getters';
import { merge } from 'lodash';
import flattenObject from '@common/utils/flattenObject';
import { EPaletteMode } from '@themes/styles/palette';
import ThemeStorage, { EThemeStorage } from './Storage';

export const getConfigs = () => {
  const loadedList = ThemeStorage.get(EThemeStorage.loadedConfigs) || {};
  const myList = ThemeStorage.get(EThemeStorage.myConfigs) || {};
  return { ...loadedList, ...myList };
};

/** мерж конфига с функциями доступа */
export const configContextExtend = (config: ITheme) =>
  merge({}, config, {
    mode: ThemeStorage.get(EThemeStorage.mode) || EPaletteMode.Light,
    isFile: !!(ThemeStorage.get(EThemeStorage.loadedConfigs) || {})[config.key],
    palette: {
      current() {
        const mode = ThemeStorage.get('mode');
        return this[mode] || defaultConf.palette[mode];
      },
    },
  });

export const getFullThemesPath = (themesPath: string): string => {
  const { protocol, host, pathname } = window.location;
  let path = `${host}${pathname}`.replace(/\/$/, '');
  if (path.includes('.html')) {
    path = path.split('/').slice(0, -1).join('/');
  }
  const parts = [protocol, '/', path, themesPath];
  return parts.join('/');
};

export const getThemePath = (name: string, themesPath: string): string => {
  const parts = [getFullThemesPath(themesPath), name, 'theme.json'];
  return parts.join('/');
};

const getDeepVariables = (obj, theme) =>
  Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] === 'function') {
      acc[key] = obj[key]({ theme });
    }

    if (typeof obj[key] === 'object') {
      acc[key] = getDeepVariables(obj[key], theme);
    }
    return acc;
  }, {});
export const setThemeTokensToCssVariables = (theme: ITheme) => {
  const variables = getDeepVariables(getThemeValue, theme);
  const flat = flattenObject(variables);
  const newKeys = Object.keys(flat).reduce((acc, key) => {
    const replacedKey = key.replace(/\./g, '-');
    acc[replacedKey] = flat[key];
    return acc;
  }, {});
  setRootCssVariables(newKeys);
};

export const setRootCssVariables = (variables: IMap<string>) => {
  const root = document?.documentElement;
  if (!root) {
    return;
  }
  Object.entries(variables).forEach(([key, value]): void => {
    if (typeof value === 'string') {
      root.style.setProperty(`--${key}`, value);
    }
  });
};
