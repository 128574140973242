/* eslint-disable */
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import * as uuidv1 from 'uuid/v1';
import { ThemeProvider } from "styled-components";
import { defaultConf } from "@themes/default-theme";

type TSelector = { selector: string };
export interface ICreatorApi<P> {
  rerender: (props: P) => void
}

export function create<P>(comp: React.ComponentType<P>) {
  return (config: TSelector & P ) => {
    const api: ICreatorApi<P> = {
      rerender: undefined
    };
    const WrapperComp = (Cmp): React.ComponentType<P> => (newProps: P) => {
      const [props, setProps] = React.useState(newProps);
      React.useEffect(() => {
        api.rerender = setProps
      }, [])
      return <Cmp {...props} />
    }
    const component = React.createElement(WrapperComp(comp), { ...config, key: uuidv1() });

    const withWrappers = React.createElement(ThemeProvider, { theme: defaultConf, children: component  })
    // если есть селектор то сразу рендерим в него
    if (config.selector) {
      ReactDOM.render(
        withWrappers,
        document.querySelector(config.selector),
      );
    }
    // иначе просто создаем элемент
    return api;
  };
}
