import { getDelayedCallback } from '@common/utils/delayedCallback';
// import EmitterNative from './lib'; // - так в jest

export type UnsubscribeFn = () => void;

export type EventName = string | symbol;

// Helper type for turning the passed `EventData` type map into a list of string keys that don't require data alongside the event name when emitting. Uses the same trick that `Omit` does internally to filter keys by building a map of keys to keys we want to keep, and then accessing all the keys to return just the list of keys we want to keep.
export type DatalessEventNames<EventData> = {
  [Key in keyof EventData]: EventData[Key] extends undefined ? Key : never;
}[keyof EventData];

export declare const listenerAdded: unique symbol;
export declare const listenerRemoved: unique symbol;
export type OmnipresentEventData = {
  [listenerAdded]: ListenerChangedData;
  [listenerRemoved]: ListenerChangedData;
};

/**
 Emittery can collect and log debug information.

 To enable this feature set the `DEBUG` environment variable to `emittery` or `*`. Additionally, you can set the static `isDebugEnabled` variable to true on the Emittery class, or `myEmitter.debug.enabled` on an instance of it for debugging a single instance.

 See API for more information on how debugging works.
 */
export type DebugLogger<EventData, Name extends keyof EventData> = (
  type: string,
  debugName: string,
  eventName?: Name,
  eventData?: EventData[Name],
) => void;

/**
 Configure debug options of an instance.
 */
export interface DebugOptions<EventData> {
  /**
   Define a name for the instance of Emittery to use when outputting debug data.

   @default undefined
   */
  readonly name: string;

  /**
   Toggle debug logging just for this instance.

   @default false
   */
  enabled?: boolean;

  /**
   Function that handles debug data.
   */
  logger?: DebugLogger<EventData, keyof EventData>;
}

/**
 Configuration options for Emittery.
 */
export interface Options<EventData> {
  debug?: DebugOptions<EventData>;
}

export interface ListenerChangedData {
  /**
   The listener that was added or removed.
   */
  listener: (eventData?: unknown) => void | Promise<void>;

  /**
   The name of the event that was added or removed if `.on()` or `.off()` was used, or `undefined` if `.onAny()` or `.offAny()` was used.
   */
  eventName?: EventName;
}

export declare class Emittery<
  EventData = Record<string, any>,
  AllEventData = EventData & OmnipresentEventData,
  DatalessEvents = DatalessEventNames<EventData>
> {
  /**
   Toggle debug mode for all instances.

   Default: `true` if the `DEBUG` environment variable is set to `emittery` or `*`, otherwise `false`.
   */
  static isDebugEnabled: boolean;

  /**
   Fires when an event listener was added.

   An object with `listener` and `eventName` (if `on` or `off` was used) is provided as event data.
   */
  static readonly listenerAdded: typeof listenerAdded;

  /**
   Fires when an event listener was removed.

   An object with `listener` and `eventName` (if `on` or `off` was used) is provided as event data.
   */
  static readonly listenerRemoved: typeof listenerRemoved;

  /**
   Debugging options for the current instance.
   */
  debug: DebugOptions<EventData>;

  /**
   Create a new Emittery instance with the specified options.

   @returns An instance of Emittery that you can use to listen for and emit events.
   */
  constructor(options?: Options<EventData>);

  /**
   In TypeScript, it returns a decorator which mixins `Emittery` as property `emitteryPropertyName` and `methodNames`, or all `Emittery` methods if `methodNames` is not defined, into the target class.

   */
  static mixin(
    emitteryPropertyName: string | symbol,
    methodNames?: readonly string[],
  ): <T extends { new (): any }>(klass: T) => T; // eslint-disable-line @typescript-eslint/prefer-function-type

  /**
   Subscribe to one or more events.

   Using the same listener multiple times for the same event will result in only one method call per emitted event.

   @returns An unsubscribe method.
   */
  on<Name extends keyof AllEventData>(
    eventName: Name,
    listener: (eventData: AllEventData[Name]) => void | Promise<void>,
  ): UnsubscribeFn;

  /**
   Get an async iterator which buffers data each time an event is emitted.

   Call `return()` on the iterator to remove the subscription.

   In practice you would usually consume the events using the [for await](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/for-await...of) statement. In that case, to revoke the subscription simply break the loop.

   It accepts multiple event names.
   */
  events<Name extends keyof EventData>(
    eventName: Name | Name[],
  ): AsyncIterableIterator<EventData[Name]>;

  /**
   Remove one or more event subscriptions.
   */
  off<Name extends keyof AllEventData>(
    eventName: Name,
    listener: (eventData: AllEventData[Name]) => void | Promise<void>,
  ): void;

  /**
   Subscribe to one or more events only once. It will be unsubscribed after the first
   event.

   @returns The event data when `eventName` is emitted.
   */
  once<Name extends keyof AllEventData>(eventName: Name): Promise<AllEventData[Name]>;

  /**
   Trigger an event asynchronously, optionally with some data. Listeners are called in the order they were added, but executed concurrently.

   @returns A promise that resolves when all the event listeners are done. *Done* meaning executed if synchronous or resolved when an async/promise-returning function. You usually wouldn't want to wait for this, but you could for example catch possible errors. If any of the listeners throw/reject, the returned promise will be rejected with the error, but the other listeners will not be affected.
   */
  emit<Name extends DatalessEvents>(eventName: Name): Promise<void>;

  emit<Name extends keyof EventData>(eventName: Name, eventData: EventData[Name]): Promise<void>;

  /**
   Same as `emit()`, but it waits for each listener to resolve before triggering the next one. This can be useful if your events depend on each other. Although ideally they should not. Prefer `emit()` whenever possible.

   If any of the listeners throw/reject, the returned promise will be rejected with the error and the remaining listeners will *not* be called.

   @returns A promise that resolves when all the event listeners are done.
   */
  emitSerial<Name extends DatalessEvents>(eventName: Name): Promise<void>;

  emitSerial<Name extends keyof EventData>(
    eventName: Name,
    eventData: EventData[Name],
  ): Promise<void>;

  /**
   Subscribe to be notified about any event.

   @returns A method to unsubscribe.
   */
  onAny(
    listener: (
      eventName: keyof EventData,
      eventData: EventData[keyof EventData],
    ) => void | Promise<void>,
  ): UnsubscribeFn;

  /**
   Get an async iterator which buffers a tuple of an event name and data each time an event is emitted.

   Call `return()` on the iterator to remove the subscription.

   In the same way as for `events`, you can subscribe by using the `for await` statement.
   */
  anyEvent(): AsyncIterableIterator<[keyof EventData, EventData[keyof EventData]]>;

  /**
   Remove an `onAny` subscription.
   */
  offAny(
    listener: (
      eventName: keyof EventData,
      eventData: EventData[keyof EventData],
    ) => void | Promise<void>,
  ): void;

  /**
   Clear all event listeners on the instance.

   If `eventName` is given, only the listeners for that event are cleared.
   */
  clearListeners(eventName?: keyof EventData): void;

  /**
   The number of listeners for the `eventName` or all events if not specified.
   */
  listenerCount(eventName?: keyof EventData): number;

  /**
   Bind the given `methodNames`, or all `Emittery` methods if `methodNames` is not defined, into the `target` object. */
  bindMethods(target: Record<string, unknown>, methodNames?: readonly string[]): void;

  clear(): void;
}

export type EmitterConstructor = new <EventData = Record<string, any>>(
  options?: Options<EventData>,
) => Emittery;

// Кажется, это по разному собирается для разных тестов, и разных проектов.
// import * as Emittery from 'emittery'; - так в cypress
// `TypeError: Emitter is not a constructor`
// import Emittery = require('emittery'); - это, кажется, самый правильный способ, но его не понимает babel.
// TODO снова разобраться почему не работает наш импорт
// const Emitter: EmitterConstructor = EmitterNative as any;
const Emitter: EmitterConstructor = require('emittery');

export default Emitter;

export const getEventWrapper = (emitter: Emittery) => <TArgs = void>(eventName: string) => ({
  emit: (eventData: TArgs) => emitter.emit(eventName, eventData),
  emitDelayed: getDelayedCallback((eventData: TArgs) => emitter.emit(eventName, eventData)),
  subscribe: (handler: (eventData: TArgs) => void | Promise<void>): UnsubscribeFn =>
    emitter.on(eventName, handler),
  wait: () => emitter.once(eventName) as Promise<TArgs>,
});

export type IEventWrapper = ReturnType<typeof getEventWrapper>;
