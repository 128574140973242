/**
 * Функция расчёта смещения скролла от времени
 * @param {number} t Текущее время
 * @param {number} start Начальное значение
 * @param {number} change Изменение скролла
 * @param {number} duration Временной интервал события
 * @returns {number} Возвращает шаг смещения скролла
 */
const easeInOutQuad = (t: number, start: number, change: number, duration: number): number => {
  t /= duration / 2;
  if (t < 1) return (change / 2) * t * t + start;
  t--;
  return (-change / 2) * (t * (t - 2) - 1) + start;
};

/**
 * Функция плавного горизонтального скроллирования
 * @param element Элемент, у которого необходимо выполнить скроллирование
 * @param to Координата, до которой производится скролл
 * @param duration Время выполнения скролла
 */
export const scrollTo = (element: HTMLDivElement, to: number, duration: number) => {
  const start = element.scrollTop;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};
