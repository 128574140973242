import { defaultConf, defaultThemeKey } from '@themes/default-theme';
import * as React from 'react';
import * as StyledComponents from 'styled-components';
import { ITheme } from '@themes/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as _ from 'lodash';
import { EPaletteMode } from '@themes/styles/palette';
import { IThemeConfig } from '@themes/types';
import { tryParseInt } from '@common/utils/number';
import useLoadOverrides from '@themes/components/ThemeProvider/useLoadOverrides';
import {
  configContextExtend,
  getConfigs,
  getFullThemesPath,
  setRootCssVariables,
  setThemeTokensToCssVariables,
} from './utils';
import useLoadTheme from './useLoadTheme';
import ThemeStorage, { EThemeStorage } from './Storage';
import CssBaseline from '../CssBaseline';

/** **********OLD THEME************************ */
export const defaultThemeName = defaultConf.key;
export const oldTheme: IThemeConfig = {
  current: null,
  list: {
    [defaultThemeName]: {
      name: defaultThemeName,
      styles: defaultConf,
    },
  },
};

const themesNames = (): string[] => Object.keys(oldTheme.list) ?? [];

export const oldSelect = (name: number | string): string => {
  const names = themesNames();

  if (typeof name === 'number') {
    const index = tryParseInt(name, 0);
    name = names?.[index - 1];
  }

  const styles = oldTheme.list[name]?.styles;
  if (!styles) {
    return null;
  }
  setRootCssVariables(styles);

  oldTheme.current = name;

  return name;
};

const getThemeName = (): string => oldTheme.current || defaultThemeName;

export const getThemeStyle = (styleName: string): string =>
  oldTheme.list[getThemeName()]?.styles?.[styleName];

/** ************************************** */

type ThemeProviderProps = {
  theme?: string;
  mode?: EPaletteMode;
  // путь для тем
  themesPath?: string;
  // список тем
  themes?: string[];
};
type IThemeListItem = {
  key: string;
  name: string;
  isFile: boolean;
};

export const Theme = {
  defaultKey: defaultThemeKey,
  get: (key?: string): ITheme => {
    const configs = getConfigs();
    // если указано имя, то проверяем есть ли оно в списке и выдаем из списка загружённых конфигов
    if (key && configs[key]) {
      return configs[key];
    }
    const selected = Theme.selected();
    return configs[selected];
  },
  selected: (): string => ThemeStorage.get(EThemeStorage.selected) || defaultConf.key,
  getFull: (key: string): ITheme => _.merge({}, defaultConf, Theme.get(key)),
  list: (): IThemeListItem[] => {
    const loadedList = ThemeStorage.get(EThemeStorage.loadedConfigs) || {};
    const configs = getConfigs();
    return Object.keys(configs).map((key) => ({
      key,
      name: Theme.getFull(key).name,
      isFile: !!loadedList[key],
    }));
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  select: (name: string) => undefined,
  getFolderPath: () => [getFullThemesPath(Theme.themesPath), Theme.selected()].join('/'),
  themesPath: 'themes',
  testMode: false,
};

/** Прокидывание конфига в глобал */
(window as any).Platform = (window as any).Platform || {};
(window as any).Platform.Themes = Theme;

/**
 * Провайдер тем
 * Работает в сочетании со провайдером от styled-components
 * Прокидывает текущий конфиг через его провайдер по всем компонентам
 * также дает возможность смени и загрузки тем по пути из конфига json
 *
 * Провадер также имеет хранилище в качестве localStorage (ThemeStorage)
 */
const ThemeProvider: React.FC<ThemeProviderProps> = ({
  theme,
  mode = EPaletteMode.Light,
  themes = [defaultConf.key],
  themesPath = 'themes',
  children,
}) => {
  const currentTheme = theme ?? themes?.[0] ?? defaultConf.key;

  // название темы
  const [themeConfig, setThemeConfig] = React.useState<ITheme>(undefined);
  // загруженнй конфиг темы если указан путь тем
  const handleLoadTheme = useLoadTheme(themesPath);
  // метод выбора текущей темы
  Theme.select = React.useCallback((name: string) => {
    // new
    ThemeStorage.set(EThemeStorage.selected, name);
    // olds
    oldSelect(name);
    window.location.reload();
  }, []);
  Theme.themesPath = themesPath;
  // при обновлении стораджа
  const onUpdate = React.useCallback(() => {
    // при обновлении списков выставить новую тему в контекст
    const selected = Theme.selected();
    const fullConfig = Theme.getFull(selected);
    const newTheme = fullConfig || defaultConf;
    setThemeConfig(configContextExtend(newTheme));
    setThemeTokensToCssVariables(newTheme);
    oldSelect(selected);
  }, []);
  // загружаем все темы из json
  React.useEffect(() => {
    // обнуляем что уже было загружено из файлов.
    ThemeStorage.set(EThemeStorage.loadedConfigs, {});
    themes.forEach(async (name) => {
      const config = await handleLoadTheme(name);
      const oldConf = _.merge({}, defaultConf, config);
      oldTheme.list[name] = {
        name,
        styles: oldConf,
      };
      ThemeStorage.update(
        EThemeStorage.loadedConfigs,
        { [name]: { key: name, name, ...config } },
        false,
      );
    });
    ThemeStorage.on(EThemeStorage.loadedConfigs, onUpdate);
    ThemeStorage.on(EThemeStorage.myConfigs, onUpdate);
    ThemeStorage.on(EThemeStorage.selected, onUpdate);
    ThemeStorage.on(EThemeStorage.mode, onUpdate);
    // Подгружаем темы от старой реализации
    return () => {
      ThemeStorage.off(EThemeStorage.loadedConfigs, onUpdate);
      ThemeStorage.off(EThemeStorage.myConfigs, onUpdate);
      ThemeStorage.off(EThemeStorage.selected, onUpdate);
      ThemeStorage.off(EThemeStorage.mode, onUpdate);
    };
  }, [onUpdate, handleLoadTheme]);

  // конфиг для провайдера
  React.useEffect(() => {
    ThemeStorage.init({
      selected: currentTheme,
      mode,
      loadedConfigs: {},
      myConfigs: {},
    });
    onUpdate();
  }, [currentTheme, mode, onUpdate]);

  React.useLayoutEffect(() => {
    const allThemeKeys = [
      ...Object.keys(ThemeStorage.get(EThemeStorage.myConfigs) || {}),
      ...themes,
    ];
    if (!allThemeKeys.includes(Theme.selected())) Theme.select(currentTheme);
  }, []);
  useLoadOverrides(themeConfig);
  // TODO подписаться на изменение конфига в localstorage и обновление в провайдере
  if (!themeConfig) {
    return null;
  }
  return (
    <StyledComponents.ThemeProvider theme={themeConfig}>
      <CssBaseline />
      {children}
    </StyledComponents.ThemeProvider>
  );
};

export default ThemeProvider;
