import styled, { css } from 'styled-components';
import { getThemeValue } from '@themes/utils/getters';
import { EThemeControlSize, Icon } from '@themes';
import IconButton from '@common/components/controls/IconButton';
import {
  IStyledContent,
  IStyledTreeItemArrow,
  IStyledTreeItemContainer,
} from '@common/components/data/tree-list-view/types/types';
import {
  ITreeListCommonSettings,
  ITreeListViewVariant,
} from '@common/components/data/tree-list-view/TreeListViewModel';
import { getTreeItemStylesBySize } from '@common/components/data/tree-list-view/styled/utils';
import { ECursor } from '@common/enums/cursor';

/** tree-list-view-item */
export const StyledTreeItemContainer = styled.div<IStyledTreeItemContainer>`
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  outline: none;

  ${({ isItemSelected, customization }) => {
    if (!isItemSelected) {
      return undefined;
    }
    const changeBackground = customization?.changeSelectedBackground ?? true;
    if (changeBackground) {
      return css`
        background: ${getThemeValue.paletteBgListActive};
      `;
    }
    return css`
      color: #268fcb;
    `;
  }}

  ${({ isItemChildSelected }) =>
    isItemChildSelected &&
    css`
      background: ${getThemeValue.paletteBgPanel};
    `}

  /**
   * Если активно перетаскивание - убираем эффект ховера и устанавлием курсор
   */
  ${(props) => {
    const { isDragging, isDraggable, selectable, customization, cursor: defaultDndCursor } = props;

    let cursor = 'pointer';
    let background = customization?.hoverBackground || getThemeValue.paletteBgListHover(props);
    if (!selectable) {
      cursor = 'not-allowed';
      background = 'transparent';
    } else if (isDraggable) {
      cursor = defaultDndCursor;
    }
    if (isDragging) {
      cursor = 'grabbing';
      background = 'transparent';
    }

    return css`
      cursor: ${cursor};
      &:hover {
        background: ${background};
      }
    `;
  }}

  /**
   * Стиль для итема, который остаётся на месте при ДнД.
   */
  ${({ ghost }) =>
    ghost &&
    css`
      opacity: 0.5;
      background: ${getThemeValue.paletteBgDefault} !important;
    `}
    
  ${({ itemSettings = {}, nesting, isChildrenExists, rightToggleIcon, customization }) => {
    const { paddingForCollapseIcon: paddingFromStyles, paddingLeft } =
      getTreeItemStylesBySize(customization?.size) || {};
    const { childrenPadding, childrenOffset } = itemSettings;
    /** Стандартный отступ у элемента */
    const padding = Number.isInteger(childrenPadding) ? childrenPadding : paddingLeft;
    /** Отступ для отображения вложенности */
    const offset = childrenOffset || paddingFromStyles;
    /**
     * К стандартному паддингу прибавляем отступ вложенности, умноженный на уровень вложенности
     */
    let calcPadding = padding + offset * nesting;

    /**
     * Если нет потомков, компенсируем неотображаемую стрелку. Так же некомпенсируем стрелку, если она справа.
     */
    if (!isChildrenExists && !rightToggleIcon) calcPadding += paddingFromStyles;
    const paddingRight = Number.isInteger(customization?.itemPaddingRight)
      ? customization.itemPaddingRight
      : paddingFromStyles;

    return css`
      padding-left: ${calcPadding}px;
      padding-right: ${paddingRight}px;
    `;
  }}

  /**
   * Определение высоты элемента в дереве
   */
  ${({ itemSettings = {}, customization }) => {
    const { rowHeight: childrenRowHeightFromStyles } =
      getTreeItemStylesBySize(customization?.size) || {};
    const { rowHeight } = itemSettings;
    const height = rowHeight || childrenRowHeightFromStyles;
    // если есть перенос по словам - то не устанавливаем только минимальную высоту ячеек
    if (customization?.wordWrap) {
      return css`
        align-items: center;
        min-height: ${height}px;
        padding-top: 12px;
        padding-bottom: 12px; //TODO может отступы тоже брать из кастомизации
      `;
    }
    return css`
      height: ${height}px;
    `;
  }}
  
  /**
   * Определение размера шрифта элемента
   */
  ${({ itemSettings = {}, customization }) => {
    const { fontSize: fontSizeFromStyles } = getTreeItemStylesBySize(customization?.size) || {};
    const { fontSize } = itemSettings;
    const textSize = fontSize || fontSizeFromStyles;
    return (
      textSize &&
      css`
        font-size: ${textSize}px;
      `
    );
  }}
  
  /**
   * Элемент, визуально перемещающийся с помощью dnd
   */
  ${({ clone }) =>
    clone &&
    css`
      text-align: left;
      background: ${getThemeValue.paletteBgDefault};
      box-shadow: 0 6px 12px #00000026;
      cursor: grabbing;
      opacity: 0.7;
    `}
  
  /**
   * Контейнер, внутрь которого можно поместить элемент, взятый c помощью dnd
   */
  ${({ combine, displayVariant }) => {
    if (!combine) {
      return null;
    }
    const borderWidth = displayVariant === ITreeListViewVariant.Variant1 ? 2 : 1;
    return css`
      position: relative;
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        border: ${borderWidth}px solid ${getThemeValue.paletteBorderActive};
      }
    `;
  }}

  /**
    * Источник, с которого берется "clone"
   */
  ${({ source }) =>
    source &&
    css`
      position: relative;
    `}

  /**
   * Элемент,рядом с которым можно поместить элемент, взятый с помощью dnd
   */
  ${({ destination, combine, source, clone, displayVariant }) => {
    const showDndPlace = destination && !combine && !source && !clone;
    if (!showDndPlace) {
      return null;
    }
    const borderWidth = displayVariant === ITreeListViewVariant.Variant1 ? 2 : 1;
    const baseBorder = css`
      position: relative;
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        border-bottom: ${borderWidth}px solid ${getThemeValue.paletteBorderActive};
      }
    `;
    if (displayVariant === ITreeListViewVariant.Variant1) {
      return css`
        .tree-list-view-item__label-content {
          ${baseBorder}
        }
      `;
    }
    return baseBorder;
  }}

  ${({ isDivider, itemSettings, customization }) => {
    if (!isDivider) return undefined;
    /**
     * TODO: Привязать к файлу тем. WI:206739
     */
    const { rowHeight: childrenRowHeightFromStyles } =
      getTreeItemStylesBySize(customization?.size) || {};
    const { rowHeight } = itemSettings || {};
    /** Элемент дивидера размером в половину обычного элемента дерева */
    const height = (rowHeight || childrenRowHeightFromStyles) / 2;
    return css`
      height: ${height}px;
    `;
  }}
  
  ${({ customization }) => `
    text-decoration: ${customization?.customStyle?.textDecoration};
  `}
`;

/** tree-list-view-item__arrow */
export const StyledTreeItemArrow = styled(IconButton)<IStyledTreeItemArrow>`
  z-index: 2;
  user-select: none;

  ${({ collapsed, customization }) => {
    const customRotation = customization?.collapseIcon?.rotate;
    const rotateAngle = Number.isInteger(customRotation)
      ? `${customRotation}deg`
      : getTreeItemStylesBySize(customization?.size)?.rotate;

    return (
      !collapsed &&
      css`
        transform: rotate(${rotateAngle});
      `
    );
  }}

  ${({ customization }) => {
    if (!customization?.wordWrap) {
      return undefined;
    }
    return css`
      margin-top: 8px;
      align-self: flex-start;
    `;
  }}
`;

/** tree-list-view-item__label-content */
export const StyledTreeItemContentContainer = styled.div<
  IStyledContent & Pick<ITreeListCommonSettings, 'isCut'>
>`
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;
  border: none;

  ${({ customization }) => {
    if (!customization?.wordWrap) {
      return undefined;
    }
    return css`
      align-items: flex-start;
    `;
  }}

  ${({ isCut }) =>
    isCut &&
    css`
      opacity: 0.5;
    `}
`;

/** tree-list-view-item__label-icon */
export const StyledTreeItemContentIcon = styled(Icon)`
  align-items: center;
`;

/** tree-list-view-item__content */
export const StyledTreeItemContent = styled.span<
  IStyledContent & Pick<ITreeListCommonSettings, 'isCut'> & { isIconExists: boolean }
>`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ customization }) => {
    if (!customization?.wordWrap) {
      return undefined;
    }
    return css`
      white-space: break-spaces;
      text-overflow: initial;
      align-self: center;
    `;
  }}

  ${({ customization, isIconExists }) => {
    if (!customization?.contentMargin) {
      const marginBySize = getTreeItemStylesBySize(customization?.size)?.contentMargin;
      // Отступ у контента элемента нужен, если есть иконка, либо выбран "не-маленький" режим
      const needContentMargin =
        isIconExists || ![EThemeControlSize.S, EThemeControlSize.XS].includes(customization?.size);
      if (!needContentMargin) return '';
      return css`
        margin-left: ${marginBySize};
      `;
    }
    return css`
      margin-left: ${customization?.contentMargin}px;
    `;
  }}

  ${({ isCut }) =>
    isCut &&
    css`
      color: ${getThemeValue.paletteTextDisabled};
    `}
`;

export const StyledMultiCloneContainer = styled.span`
  max-width: 232px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 16px;
`;

/** tree-list-view-item__draggable-indicator */
export const StyledTreeItemDraggableIndicator = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 0;
  visibility: hidden;
  transform: translate(0, -50%);
  cursor: ${ECursor.Grab};
`;

export const StyledDnDItem = styled.div<IStyledTreeItemContainer>`
  position: relative;
  outline: none;

  /** 
   * Стиль для выделенных элементов, y которых доступен ДнД
  */
  ${({ isItemSelected, isDraggable }) =>
    isDraggable &&
    isItemSelected &&
    css`
      ${StyledTreeItemDraggableIndicator} {
        visibility: visible;
        color: ${getThemeValue.paletteBorderActive};
      }
    `}

  /**
   * В 'Item' он помещается, если элемент поддерживает dnd
   * но отображается только если для всего дерева включен режим dnd
   */
    ${({ isDraggable }) =>
    isDraggable &&
    css`
      &:hover {
        ${StyledTreeItemDraggableIndicator} {
          visibility: visible !important;
        }
      }
    `}
`;
