import * as React from 'react';
import StyledButton from './StyledButton';
import { IButtonBaseProps } from './types';

const ButtonBase: React.FC<IButtonBaseProps> = ({ testId, ...props }) => (
  <StyledButton {...props} data-testid={testId} ref={props.buttonRef} />
);

ButtonBase.defaultProps = {
  type: 'button',
  title: '',
};

export default ButtonBase;
