export type KeyboardEvent<T = HTMLInputElement> = React.KeyboardEvent<T>;

export interface IKeyboardEventHandler {
  (event: KeyboardEvent): void;
}

export enum Keys {
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Backslash = 'Backslash',
  Backspace = 'Backspace',
  Control = 'Control',
  Delete = 'Delete',
  Digit5 = 'Digit5',
  Dot = '.',
  Enter = 'Enter',
  Escape = 'Escape',
  Meta = 'Meta',
  Minus = '-',
  Numpad5 = 'Numpad5',
  Shift = 'Shift',
  Space = ' ',
  Tab = 'Tab',
  F2 = 'F2',
  F3 = 'F3',
  F7 = 'F7',
  F8 = 'F8',
  F9 = 'F9',
  F11 = 'F11',
  B = 'KeyB',
  E = 'KeyE',
  F = 'KeyF',
  K = 'KeyK',
  H = 'KeyH',
  I = 'KeyI',
  L = 'KeyL',
  P = 'KeyP',
  R = 'KeyR',
  S = 'KeyS',
  T = 'KeyT',
  U = 'KeyU',
  W = 'KeyW',
  Y = 'KeyY',
}

// Загатовка на будущие правки горячих клавиш.
export enum EKeys {
  A = 'KeyA',
  C = 'KeyC',
  D = 'KeyD',
  F = 'KeyF',
  F_CODE = 70,
  K = 'KeyK',
  L = 'KeyL',
  M = 'KeyM',
  N = 'KeyN',
  U = 'KeyU',
  H = 'KeyH',
  W = 'KeyW',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Control = 'control',
  ControlCode = 17,
  ControlLeft = 'ControlLeft',
  ControlRight = 'ControlRight',
  AltLeft = 'AltLeft',
  AltRight = 'AltRight',
  ShiftLeft = 'ShiftLeft',
  ShiftRight = 'ShiftRight',
  PageUp = 'PageUp',
  PageDown = 'PageDown',
  Home = 'Home',
  End = 'End',
  F2 = 'F2',
  F5 = 'F5',
  F12 = 'F12',
  NUM_0 = 'Digit0',
  NUM_0_CODE = 48,
  NUM_1 = 'Digit1',
  NUM_2 = 'Digit2',
  NUM_3 = 'Digit3',
  NUM_4 = 'Digit4',
  NUM_5 = 'Digit5',
  NUM_6 = 'Digit6',
  NUM_7 = 'Digit7',
  NUM_8 = 'Digit8',
  NUM_9 = 'Digit9',
  Escape = 'Escape',
}

// Устаревшие (https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode).
// По возможности лучше использовать энам Keys и поле code из KeyboardEvents.
export enum EKeysCodes {
  Backspace = 8,
  Tab = 9,
  Clear = 12,
  Enter = 13,
  CapsLock = 13,
  Shift = 16,
  Control = 17,
  Escape = 27,
  Space = 32,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,
  ArrowLeft = 37,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowDown = 40,
  Insert = 45,
  Delete = 46,
  Meta = 91,
  Dot = 110,
  Minus = 189,

  Key0 = 48,
  Key1 = 49,
  Key2 = 50,
  Key3 = 51,
  Key4 = 52,
  Key5 = 53,
  Key6 = 54,
  Key7 = 55,
  Key8 = 56,
  Key9 = 57,

  Numpad0 = 96,
  Numpad1 = 97,
  Numpad2 = 98,
  Numpad3 = 99,
  Numpad4 = 100,
  Numpad5 = 101,
  Numpad6 = 102,
  Numpad7 = 103,
  Numpad8 = 104,
  Numpad9 = 105,
  NumpadMultiply = 106,
  NumpadAdd = 107,
  NumpadEnter = 108,
  NumpadSubtract = 109,
  NumpadDecimal = 110,
  NumpadDivide = 111,

  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,

  A = 65,
  C = 67,
  S = 83,
  V = 86,
  X = 88,
}
