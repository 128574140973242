import * as React from 'react';
import withAutosize from '@common/hocs/withAutosize/withAutosize';
import { IFlatProps } from '../types';
import useFlatModel from './useFlatModel';
import {
  ScrollButtonDown,
  StyledFlatItems,
  StyledFlatList,
  StyledScrollWrapper,
  StyledUpButton,
} from './styled';

const Flat: React.FC<IFlatProps> = (props) => {
  const { height, width, imagesPath } = props;
  const {
    wrapperRef,
    listRef,
    slots,
    handleScrollUp,
    handleScrollDown,
    visibleUpButton,
    visibleDownButton,
    elements,
  } = useFlatModel(props);

  return (
    <StyledScrollWrapper
      ref={wrapperRef}
      className={slots.flatWrapper}
      height={height}
      width={width}
    >
      <StyledUpButton
        onClick={handleScrollUp}
        imagesPath={imagesPath}
        visible={visibleUpButton}
        className={slots.scrollUp}
      />
      <StyledFlatList className={slots.flatList}>
        <StyledFlatItems ref={listRef} className={slots.flat}>
          {elements}
        </StyledFlatItems>
      </StyledFlatList>
      <ScrollButtonDown
        onClick={handleScrollDown}
        imagesPath={imagesPath}
        visible={visibleDownButton}
        className={slots.scrollDown}
      />
    </StyledScrollWrapper>
  );
};
export default withAutosize(Flat);
