import * as React from 'react';
import {
  BeforeCapture,
  DragDropContext,
  DragStart,
  DropResult,
  Position,
  ResponderProvided,
} from 'react-beautiful-dnd';

interface IDndContextProps {
  onDragUpdate?: (update: DropResult) => void;
  onDragEnd: (result: DropResult) => void;
  /** Используется при мульти-драге. По умолчанию 1. */
  selectedCount?: number;
  onDragStart?: (initial: DragStart, provided: ResponderProvided) => void;
}

const DndContext: React.FC<IDndContextProps> = ({
  onDragEnd,
  onDragUpdate,
  children,
  onDragStart,
  selectedCount = 1,
}) => {
  /** Рефка для хранения координат мыши при ДНД */
  const clientSelectionRef = React.useRef<Position>({ x: 0, y: 0 });

  /** Функция на перемещение мыши */
  const onMousemove = React.useCallback((event: MouseEvent) => {
    const current: Position = {
      x: event.clientX,
      y: event.clientY,
    };
    clientSelectionRef.current = current;
  }, []);

  React.useEffect(() => {
    window.addEventListener('mousemove', onMousemove, { passive: true });
    return () => {
      window.removeEventListener('mousemove', onMousemove);
    };
  }, []);

  const onBeforeCapture = React.useCallback(
    (before: BeforeCapture) => {
      window.dispatchEvent(
        new CustomEvent('onBeforeCapture', {
          detail: { before, clientSelection: clientSelectionRef.current, selectedCount },
        }),
      );
    },
    [selectedCount],
  );

  return (
    <DragDropContext
      onDragUpdate={onDragUpdate}
      onDragEnd={onDragEnd}
      onBeforeCapture={onBeforeCapture}
      onDragStart={onDragStart}
    >
      {children}
    </DragDropContext>
  );
};

export default DndContext;
