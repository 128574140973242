/**
 * Склонение существительных для русского, украинского, белорусского, сербского и хорватского языков. Реализация:
 *
 * - Заканчивается на _1_, кроме _11_ (1, 21, 31, 41, 51, 61, 71, 81, 91, 101, 121, 131, 141, 151, 161, 171, 181, 191, 201, 221, 231, 241, 251, 261, 271, 281, 291, …)
 *
 * - Заканчивается на _2_, _3_ или _4_, кроме чисел _12_, _13_, _14_ (2, 3, 4, 22, 23, 24, 32, 33, 34, 42, 43, 44, 52, 53, 54, 62, 63, 64, 72, 73, 74, 82, 83, 84, 92, 93, 94, 102, 103, 104, 122, 123, 124, 132, 133, 134, 142, 143, 144, 152, 153, 154, 162, 163, 164, 172, 173, 174, 182, 183, …)
 *
 * - Everything else: 0, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 26, 27, 28, 29, 30, 35, 36, 37, 38, 39, 40, 45, 46, 47, 48, 49, 50, 55, 56, 57, 58, 59, 60, 65, 66, 67, 68, 69, 70, 75, 76, 77, …, 112, 113, ..., 212, 213,
 *
 * Описание с сайта [https://developer.mozilla.org/]{@link https://developer.mozilla.org/en-US/docs/Mozilla/Localization/Localization_and_Plurals#Plural_rule_7_(3_forms)}
 *
 * @param {number} number число (количество)
 * @param {string} nominativeSingle единственное число, именительный падеж, например: _элемент_
 * @param {string} nominativePlural единственное число, родительный падеж, например: _элемента_
 * @param {string} genitivePlural множественное число, родительный падеж, например: _элементов_
 *
 * @returns {string} Подходящее склонение
 *
 * @example
 * plural("элемент", "элемента", "элементов", 0); // элементов
 * plural("элемент", "элемента", "элементов", 1); // элемент
 * plural("элемент", "элемента", "элементов", 2); // элемента
 *
 */

export const pluralForms = (
  number: number,
  nominativeSingle: string,
  nominativePlural: string,
  genitivePlural: string,
): string => {
  const positiveNumber = Math.abs(number);
  const units: number = positiveNumber % 10;
  const tens: number = positiveNumber % 100;

  if (units === 1 && tens !== 11) {
    return nominativeSingle;
  }

  if ([2, 3, 4].includes(units) && ![12, 13, 14].includes(tens)) {
    return nominativePlural;
  }

  return genitivePlural;
};
