import NavigationPanelWrapper from '@common/components/panels/navigation-panel/wrapper/NavigationPanelWrapper';
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { Theme } from '@themes/components/ThemeProvider';
import { create } from './componentCreator';

const Common = {
  controls: {
    NavigationPanel: create(NavigationPanelWrapper),
  },
};

declare global {
  interface Window {
    UI: {
      Common: typeof Common;
      React: typeof React;
      ReactDOM: typeof ReactDOM;
      Theme: typeof Theme;
    };
  }
}

export { Common, React, ReactDOM, Theme };
