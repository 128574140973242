import * as React from 'react';
import Waiter from '@common/components/data/waiter/Waiter';
import { getDisplayName } from '@common/utils/getDisplayName';

const defaultFallback = <Waiter visible relative />;

/** HOC оборачивающий компонент в Suspense.
 * @param WrappedComponent оборачиваемый компонент
 * @param fallback компонент отображаемый в качестве вэйтера
 * @param anonyme если "true" то обертка будет "безымянной" (ее название не будет фигурировать в VDOM) */
export const withSuspense = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  fallback: React.ReactNode = defaultFallback,
  anonyme = false,
): React.ComponentType<P> => {
  const SuspenseWrapper: React.FC<P> = (props: P) => (
    <React.Suspense fallback={fallback}>
      <WrappedComponent {...props} />
    </React.Suspense>
  );

  SuspenseWrapper.displayName = !anonyme
    ? `withSuspense(${getDisplayName(WrappedComponent)})`
    : getDisplayName(WrappedComponent);

  return SuspenseWrapper;
};
