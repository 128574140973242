import { Hash } from '@common/types/hash';

/** Фильтрует объект с помощью предиката (возвращает хэш с тем-же или другим набором полей)
 * @param obj исходный объект для фильтоации
 * @param predicate фильтрующий предикат, в который передается пара "ключ: значение"
 * */
export const filterObject = <T extends Hash<any, any> | object>(
  obj: T,
  predicate: <TValue>(key: string, value?: TValue) => boolean,
): Partial<T> => {
  const result = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (predicate(key, value)) result[key] = value;
  });
  return result;
};

/** Маппит элементы массива в хэш
 * @param array исходный массив который нужно преобразовать в хэш
 * @param cb колбэк выполняющий выборку ключей и значений
 * */
export const getObjectFromArray = <TItem, TValue = TItem>(
  array: TItem[],
  cb: (item: TItem) => { key: number; value: TValue },
) => {
  const result = {};
  array.forEach((item) => {
    const { key, value } = cb(item);
    result[key] = value;
  });
  return result;
};
