import * as React from 'react';
import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import Item from '@common/components/data/tree-list-view/components/Item';
import {
  treeBuilder,
  treeListItemClick,
  treeListKeyboardArrowDown,
} from '@common/components/data/tree-list-view/utils';
import { MouseEvent } from '@common/types/mouseEvent';
import {
  IBuiltTreeData,
  IBuiltTreeItem,
  ITreeItem,
  ITreeListState,
  ITreeListViewImplProps,
  TreeListViewItemKey,
} from '@common/components/data/tree-list-view/TreeListViewModel';

import {
  StyledTreeContainer,
  StyledTreeWrapper,
} from '@common/components/data/tree-list-view/styled/StyledTree';
import { IStyledTreeContainerProps } from '@common/components/data/tree-list-view/types/types';
import { treeClassNamesMap } from '@common/components/data/tree-list-view/constants';

class Tree extends React.Component<ITreeListViewImplProps, ITreeListState> {
  private buildTree = memoizeOne(
    (
      data: ITreeItem[],
      parentsPartialSelection: boolean,
      calcDedicatedParents?: boolean,
    ): IBuiltTreeData =>
      treeBuilder(data, parentsPartialSelection, { autoIsDraggable: false }, calcDedicatedParents),
  );

  public itemRef: React.RefObject<HTMLDivElement> = React.createRef();

  public containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: ITreeListViewImplProps) {
    super(props);
    this.state = {
      elems: [],
      scrollToItem: undefined,
      scrollElem: null,
      scroll: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: Readonly<ITreeListViewImplProps>,
    prevState: ITreeListState,
  ) {
    if (
      nextProps.scrollToItem &&
      (prevState.scrollToItem === undefined ||
        prevState.scrollToItem.key !== nextProps.scrollToItem.key)
    ) {
      return {
        elems: nextProps.data,
        scrollToItem: nextProps.scrollToItem,
        scrollElem: nextProps.scrollToItem,
        scroll: true,
      };
    }
    return { ...prevState, elems: nextProps.data };
  }

  public componentDidMount(): void {
    this.scrollContentIfNeed();
    this.containerRef.current.addEventListener('keydown', this.onKeyDown, false);
  }

  public componentDidUpdate(): void {
    this.scrollContentIfNeed();
  }

  public componentWillUnmount(): void {
    this.containerRef.current.removeEventListener('keydown', this.onKeyDown, false);
  }

  private tree = (): IBuiltTreeItem[] => {
    const { data, parentsPartialSelection, calcDedicatedParents } = this.props;
    const treeData =
      this.props.buildTreeData ??
      this.buildTree(data, parentsPartialSelection, calcDedicatedParents);

    return treeData?.tree ?? [];
  };

  private flat = (): IBuiltTreeItem[] => {
    const { data, parentsPartialSelection, calcDedicatedParents } = this.props;
    const treeData =
      this.props.buildTreeData ??
      this.buildTree(data, parentsPartialSelection, calcDedicatedParents);

    return treeData?.flat ?? [];
  };

  private onItemClick = (key: TreeListViewItemKey, event: MouseEvent, item: ITreeItem): void => {
    const { data, focusedKey, onItemClick } = this.props;
    treeListItemClick(key, event, data, focusedKey, onItemClick, this.flat(), item);
  };

  private onKeyDown = (e: KeyboardEvent): void => {
    const { data, onItemClick, onItemToggle, disableHotkeys } = this.props;
    // TODO пока отключил в выпадашках
    if (disableHotkeys || (e.currentTarget as Element).closest('.ui-dropdown__popup')) return;
    treeListKeyboardArrowDown(e, data, this.tree(), onItemClick, onItemToggle);
  };

  private scrollContentIfNeed(): void {
    if (this.itemRef.current && this.state.scroll) {
      this.itemRef.current.scrollIntoView({ block: 'nearest' });
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState((prevState) => ({ ...prevState, scroll: false }));
    }
  }

  private renderItem = (item: IBuiltTreeItem, ref: boolean, index: number): JSX.Element => {
    const {
      testId,
      itemRenderer,
      onItemHover,
      onItemLeave,
      onItemDoubleClick,
      onItemToggle,
      onItemRightClick,
      rightToggleIcon,
      hideIcons,
      customization,
      hideChildIcons,
    } = this.props;

    if (itemRenderer) {
      return itemRenderer(item);
    }
    const hideChildIcon = item.parent && hideChildIcons;
    return (
      <Item
        item={item}
        itemRef={ref ? this.itemRef : undefined}
        onItemClick={this.onItemClick}
        onItemRightClick={onItemRightClick}
        onItemDoubleClick={onItemDoubleClick}
        onItemToggle={onItemToggle}
        onItemHover={onItemHover}
        onItemLeave={onItemLeave}
        testId={testId && `${testId}_${index}`}
        hideIcon={hideIcons || hideChildIcon}
        rightToggleIcon={rightToggleIcon}
        customization={customization}
      />
    );
  };

  private renderChildren = (item: IBuiltTreeItem): JSX.Element => {
    const renderChild = item?.children?.length > 0 && !item?.collapsed;
    return renderChild && this.renderTree(item?.children);
  };

  private renderTree = (items: IBuiltTreeItem[]): JSX.Element => {
    if (items?.length <= 0) return null;

    return (
      <>
        {items.map((item: IBuiltTreeItem, index: number) => {
          const ref = this.state.scrollToItem
            ? this.state.scrollToItem.key === item.key
            : this.state.scrollElem && item.key === this.state.scrollElem.key;
          return (
            <React.Fragment key={`tlv-${index}`}>
              {this.renderItem(item, ref, index)}
              {this.renderChildren(item)}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  public render(): JSX.Element {
    const { className, testId, dragAndDrop, customization } = this.props;

    const { tree: treeClassName, wrapper: wrapperClassName } = treeClassNamesMap;
    const classes = classNames(treeClassName, {
      [className]: className,
    });

    const styledProps: IStyledTreeContainerProps = {
      enabledDnd: dragAndDrop?.enabled,
      size: customization?.size,
      customStyle: customization?.customStyle,
      background: customization?.background,
    };

    return (
      <StyledTreeContainer className={classes} data-testid={testId} {...styledProps}>
        <StyledTreeWrapper
          tabIndex={0}
          className={wrapperClassName}
          wordWrap={customization?.wordWrap}
          disableTextSelection={customization?.disableTextSelection}
          ref={this.containerRef}
        >
          {this.renderTree(this.tree())}
        </StyledTreeWrapper>
      </StyledTreeContainer>
    );
  }
}

export default Tree;
