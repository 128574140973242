import * as React from 'react';
import withLocales from '@common/locales/utils/withLocales';
import { WithTranslation } from '@common/locales/utils/withLocales/types';
import { Typography } from '@themes';
import { StyledMultiCloneLable, StyledCountContainer, StyledCount } from './styled';
import getLocales, { localesList, tFixedMultiDrag } from './locales';

interface IMultiDragProps {
  count: number;
}

const MultiDrag: React.FC<IMultiDragProps & WithTranslation> = ({ count }) => (
  <>
    <StyledMultiCloneLable>
      <Typography variant="textNormal">{`${tFixedMultiDrag('Elements')}`}</Typography>
    </StyledMultiCloneLable>
    <StyledCountContainer>
      <Typography variant="textNormal">
        <StyledCount>{count}</StyledCount>
      </Typography>
    </StyledCountContainer>
  </>
);

const localesMap = {
  [localesList.multiDrag]: getLocales,
};

export default withLocales(localesMap)(MultiDrag);
