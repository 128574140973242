import { ITheme } from '@themes/styles';
import * as _ from 'lodash';
import { defaultConf } from '@themes/default-theme';
import {
  EThemePaletteBrightNumber,
  EThemePaletteColors,
  IThemePalette,
  IThemePaletteColorsWithBright,
} from '@themes/styles/palette';
import { brighten, isColor, rgbToHex } from '@themes/utils/color-manipulator';
import { IPropsWithTheme } from '@themes/utils/getters/types';

export const currentPalette = <T extends IPropsWithTheme>(props: T): IThemePalette => {
  const mode = props?.theme?.mode ?? 'light';
  return props?.theme?.palette?.[mode] || defaultConf?.palette?.[mode] || defaultConf.palette.light;
};

export const currentPaletteColorBright = (
  props: IPropsWithTheme,
  colorWithBright: string,
): string => {
  if (isColor(colorWithBright)) return colorWithBright;
  const [color, bright] = colorWithBright.split('.') as [
    EThemePaletteColors,
    EThemePaletteBrightNumber,
  ];
  if (
    !Object.keys(EThemePaletteColors).includes(color) ||
    Object.values(EThemePaletteBrightNumber).includes(bright)
  ) {
    console.error(`Color ${colorWithBright} in theme is not valid`);
    return '#000';
  }
  return getPaletteColors(props.theme)[color][bright] ?? '#000';
};

const getColorMap = (color: string) => {
  const colorMap = {};
  Object.values(EThemePaletteBrightNumber).forEach((key: number) => {
    if (Number.isInteger(key)) {
      const coefficient = Math.round(key) / 10;
      colorMap[key] = key === 0 ? color : rgbToHex(brighten(color, coefficient));
    }
  });
  return colorMap;
};
export const getPaletteColors = _.memoize(
  (theme: ITheme): IThemePaletteColorsWithBright =>
    Object.keys(EThemePaletteColors).reduce((acc, color) => {
      const themeColor = theme.palette?.colors[color] ?? defaultConf.palette.colors[color];
      acc[color] = getColorMap(themeColor);
      return acc;
    }, {}),
);

type TBaseColorGetter = {
  [C in EThemePaletteColors]: (props: IPropsWithTheme) => string;
};
type TColorBrightGetter = {
  [C in EThemePaletteColors]: {
    [b in EThemePaletteBrightNumber]: (props: IPropsWithTheme) => string;
  };
};
/**
 * Получатель базовых цветов без коэфициентов
 */
const baseColorGetter: TBaseColorGetter = Object.values(EThemePaletteColors).reduce(
  (acc, color: EThemePaletteColors) => ({
    ...acc,
    [color]: (props: IPropsWithTheme) => props.theme.palette?.colors?.[color],
  }),
  {} as TBaseColorGetter,
);
/**
 * Получатель базовых цветов с коэфициентами
 */
const colorBrightGetter: TColorBrightGetter = Object.values(EThemePaletteColors).reduce(
  (acc, color: EThemePaletteColors) => ({
    ...acc,
    [color]: Object.values(EThemePaletteBrightNumber).reduce(
      (acc, coef) => ({
        ...acc,
        ...(Number.isInteger(coef) && {
          [coef]: (props: IPropsWithTheme) => getPaletteColors(props.theme)[color][coef],
        }),
      }),
      {},
    ),
  }),
  {} as TColorBrightGetter,
);

export const paletteGetters = {
  paletteColors: baseColorGetter,
  paletteColorsWithBright: colorBrightGetter,
  // TODO normal
  paletteTextPrimary: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).text.normal),
  paletteTextDescription: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).text.description),
  paletteTextLabel: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).text.label),
  paletteTextDisabled: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).text.disabled),
  paletteTextError: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).text.error),
  paletteTextLink: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).text.link),
  paletteTextButtonPrimary: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).text.buttonPrimary),
  // Icon
  paletteIconNormal: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).icon.normal),
  paletteIconHover: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).icon.hover),
  paletteIconActive: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).icon.active),
  paletteIconPressed: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).icon.pressed),
  paletteIconDisabled: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).icon.disabled),
  // bg
  paletteBgDefault: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.default),
  paletteBgDisabled: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.disabled),
  paletteBgShapeHover: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.shapeHover),
  paletteBgShapeActive: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.shapeActive),
  paletteBgTableHover: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.tableHover),
  paletteBgTableActive: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.tableActive),
  paletteBgMenuHover: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.menuHover),
  paletteBgMenuActive: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.menuActive),
  paletteBgListHover: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.listHover),
  paletteBgListActive: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.listActive),
  paletteBgPanel: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.panel),
  paletteBgPanelSecond: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).background.panelSecond),
  // border
  paletteBorderDefault: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).border.normal),
  paletteBorderHover: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).border.hover),
  paletteBorderActive: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).border.active),
  paletteBorderFocus: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).border.focus),
  paletteBorderDisabled: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).border.disabled),
  paletteBorderError: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).border.error),
  // line
  paletteLineNormal: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).line.normal),
  paletteLineHover: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).line.hover),
  paletteLineFocus: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).line.focus),
  // divider
  paletteDividerNormal: (props: IPropsWithTheme) =>
    currentPaletteColorBright(props, currentPalette(props).divider),
};
