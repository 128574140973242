import styled, { css } from 'styled-components';
import { getThemeValue } from '@themes/utils/getters';

export const StyledCloneContainer = styled.div<{ isDefaultVariant: boolean }>`
  ${({ isDefaultVariant }) =>
    isDefaultVariant &&
    css`
      display: flex;
      background: ${getThemeValue.paletteBgDefault};
      height: 40px !important;
      min-width: 136px !important;
      max-width: 232px !important;
      border-radius: 4px !important;
      outline: none;

      .tree-list-view-item__draggable-clone {
        width: 100%;
        height: 100%;
        opacity: 1;
        padding-left: 16px;
        border-radius: 4px;

        .tree-list-view-item__content {
    
          span {
            align-self: center;
          }
        }

        .tree-list-view-item__arrow {
          display: none;
        }
      `}
`;
