import * as React from 'react';
import { useTheme } from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { merge } from 'lodash';
import { getDisplayName } from '@common/utils/getDisplayName';

/**
 * Получаем пропсы с пропсами замерженными из темы
 * @param componentName
 * @param section
 */
const withComponentThemeProps = (
  componentName: string,
  section: 'common' | 'platform' = 'common',
) => <P extends {}>(Component: React.ComponentType<P>): React.ComponentType<P> => {
  const WrappedComponent = (props: P) => {
    const theme = useTheme();
    Component.defaultProps = React.useMemo(() => {
      const componentTheme = theme?.[section]?.[componentName];
      const themeDefaultProps = componentTheme?.defaultProps ?? ({} as P);
      if (Object.keys(themeDefaultProps).length) {
        return merge(Component.defaultProps, themeDefaultProps);
      }
      return Component.defaultProps;
    }, [componentName, theme]);
    return <Component {...props} />;
  };
  WrappedComponent.displayName = getDisplayName(Component);
  return WrappedComponent;
};

export default withComponentThemeProps;
