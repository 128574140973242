import * as React from 'react';
import { groupBy } from 'lodash';
import { IMouseEventHandler, MouseEvent } from '@common/types/mouseEvent';
import { hideMenu, IContextMenuAdvanceItem } from '@common/components/popups/context-menu-advance';
import {
  ITreeItem,
  TreeListViewItemKey,
} from '@common/components/data/tree-list-view/TreeListViewModel';
import { IContextMenuCustomization } from '@common/components/popups/context-menu-advance/types';
import { FlatChildItem, MenuButton } from '../../styled';
import { scrollTo } from './utils';
import { useClasses } from '../classes';
import { IFlatProps } from '../types';

const BUTTON_WIDTH = 56;
const BUTTON_HEIGHT = 56;
const SCROLL_STEP = BUTTON_HEIGHT;
const ICON_WIDTH = 24;

const buttonCustomization: IContextMenuCustomization = {
  background: '#efefef',
  round: 0,
  shadow: false,
  hoverBackground: '#e8e9eb',
  iconHeight: 16,
  iconWidth: 16,
  wordWrap: true,
  header: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 16,
    padding: '16px',
    color: '#212529',
    background: 'inherit',
  },
};

const useFlatModel = ({
  list,
  selectItem,
  hideChildIcons,
  selected,
  collapsed,
  height,
}: IFlatProps) => {
  /** для обработки закрытия ранее открытого контекстного меню */
  const openedId = React.useRef<string>();
  const listRef = React.useRef<HTMLDivElement>();
  const wrapperRef = React.useRef<HTMLDivElement>();

  const [visibleUpButton, setVisibleUpButton] = React.useState<boolean>(false);
  const [visibleDownButton, setVisibleDownButton] = React.useState<boolean>(false);

  const grouped = React.useMemo(() => groupBy(list, 'parent'), [list]);

  const slots = useClasses();

  const updateScrollButtons = React.useCallback(() => {
    setVisibleUpButton(wrapperRef?.current?.scrollTop > 0);
    // для правки бага в Хромиуме при расчёте позиции скроллирования при масштабе отличающемся от 100% https://bugs.chromium.org/p/chromium/issues/detail?id=890345
    const showDownButton =
      listRef?.current?.offsetHeight -
        (wrapperRef?.current?.offsetHeight + wrapperRef?.current?.scrollTop) >
      1;
    setVisibleDownButton(showDownButton);
  }, [setVisibleUpButton, setVisibleDownButton, wrapperRef?.current, listRef?.current]);

  const setScroll = React.useCallback(
    (yPosition: number) => {
      const isScrolling = listRef?.current?.offsetHeight >= wrapperRef?.current?.offsetHeight;
      if (!isScrolling) {
        return;
      }
      // задержка 100мс для плавной анимации прокрутки
      scrollTo(wrapperRef.current, yPosition, 100);
      setTimeout(updateScrollButtons, 100);
    },
    [wrapperRef.current, updateScrollButtons],
  );

  const onWheel = React.useCallback(
    (event: WheelEvent) => {
      const sign = event.deltaY > 0 ? 1 : -1;
      const newPosition = wrapperRef.current.scrollTop + SCROLL_STEP * sign;
      setScroll(newPosition);
    },
    [wrapperRef.current, setScroll],
  );

  React.useEffect(() => {
    updateScrollButtons();
  }, [height]);

  React.useEffect(() => {
    wrapperRef.current.addEventListener('wheel', onWheel);
    return () => {
      wrapperRef.current.removeEventListener('wheel', onWheel);
    };
  }, []);

  const handleItemClick = React.useCallback<
    IMouseEventHandler<IContextMenuAdvanceItem<number, {}, ITreeItem>>
  >(
    (_event, args) => {
      selectItem(+args.item.additionalData.key);
    },
    [selectItem],
  );

  const handleClick = React.useCallback(
    (event: MouseEvent) => {
      selectItem(+event.currentTarget.id);
    },
    [selectItem],
  );

  const subItems = React.useCallback(
    (key: TreeListViewItemKey) =>
      grouped?.[key]?.map<IContextMenuAdvanceItem>((tk) => ({
        caption: <FlatChildItem isSelected={tk.key === selected}>{tk.caption}</FlatChildItem>,
        payload: tk.key,
        iconPath: !hideChildIcons && (tk.icon as string),
        additionalData: tk,
        className: slots.flatSubItem,
      })) ?? [],
    [grouped, slots, hideChildIcons, selected],
  );

  const showHandler = React.useCallback(
    (id: string) => {
      if (openedId.current !== id) {
        hideMenu({ id: openedId.current });
        openedId.current = id;
      }
    },
    [openedId.current],
  );

  const handleScrollDown = () => {
    const yPosition = wrapperRef.current.scrollTop + SCROLL_STEP;
    setScroll(yPosition);
  };

  const handleScrollUp = () => {
    const yPosition = wrapperRef.current.scrollTop - SCROLL_STEP;
    setScroll(yPosition);
  };

  const elements = React.useMemo(
    () =>
      list
        ?.filter((el) => el.parent === '')
        ?.map((el) => (
          <MenuButton
            key={el.key}
            onlyIcon
            iconLeft={el.icon as string}
            variant="ghost"
            width={BUTTON_WIDTH}
            height={BUTTON_HEIGHT}
            caption={el.caption}
            pressed={el.key === collapsed}
            items={subItems(el.key)}
            id={el.key.toString()}
            onClick={handleClick}
            onItemClick={handleItemClick}
            position="right"
            showOnHover
            onShow={showHandler}
            contextMenuWidth={256}
            customization={buttonCustomization}
            className={slots.flatItem}
            iconHeight={ICON_WIDTH}
            iconWidth={ICON_WIDTH}
            hideOnLeave
          />
        )),
    [list, collapsed, slots, handleClick, handleItemClick, showHandler, subItems],
  );
  return {
    wrapperRef,
    listRef,
    slots,
    handleScrollUp,
    handleScrollDown,
    visibleUpButton,
    visibleDownButton,
    elements,
  };
};

export default useFlatModel;
