import * as React from 'react';
import { IContextSubMenuProps } from '@common/components/popups/context-menu-advance/types';
import Item from './ContextMenuAdvanceItem';
import SubMenu from './ContextMenuAdvanceSubMenu';
import { StyledWrapperSplitSubMenu, StyledSplitSubMenuDivider } from './styled';

export interface IContextSplitSubMenuProps<TAdditionalData>
  extends IContextSubMenuProps<TAdditionalData> {
  /** Горячие клавиши для действия */
  hotkey?: string;
  /** Можно ли отмечать */
  isCheck?: boolean;
  /** Является отмеченным */
  selected?: boolean;
  /** Массив дочерних элементов меню. */
  children?: JSX.Element[];
}

export class ContextMenuAdvanceSplitSubMenu<TAdditionalData = {}> extends React.PureComponent<
  IContextSplitSubMenuProps<TAdditionalData>
> {
  public render(): JSX.Element {
    const { titleClassName, children, disabled, attributes, ...props } = this.props;

    return (
      !attributes.hidden && (
        <StyledWrapperSplitSubMenu>
          <Item {...props} />
          <StyledSplitSubMenuDivider />
          <div>
            <SubMenu titleClassName={titleClassName} splitPadding disabled={disabled} title="">
              {children}
            </SubMenu>
          </div>
        </StyledWrapperSplitSubMenu>
      )
    );
  }
}

export default ContextMenuAdvanceSplitSubMenu;
