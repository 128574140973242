export enum ECursor {
  None = 'None',
  Default = 'default',
  NWSEResize = 'nwse-resize',
  NESWesize = 'nesw-resize',
  NSResize = 'ns-resize',
  EWResize = 'ew-resize',
  Move = 'move',
  Crosshair = 'crosshair',
  Pointer = 'pointer',
  Grab = 'grab',
  Grabbing = 'grabbing',
}
