import i18next from 'i18next';

export enum localesList {
  multiDrag = 'multi-drag',
}
const getLocales = (locale: string, ns: string) =>
  import(
    /* webpackChunkName: "i18n/[request]" */ /* webpackInclude: /\.json$/ */ /* webpackMode: "lazy" */
    `./${locale}/${ns}.json`
  );
export default getLocales;

export const tFixedMultiDrag = i18next.getFixedT(null, localesList.multiDrag);
