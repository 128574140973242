import { capitalize } from '@common/utils/text';
import { generateHookSlots, SlotMap } from '@themes/utils/classes';
import { IButtonProps } from '@common/components/controls/Button/types';

export const componentName = 'Button';
const prefix = `Ui${componentName}`;
const slots = ['root', 'label', 'iconLeft', 'iconRight', 'iconTop', 'iconBottom'] as const;

const slotsMap: SlotMap<typeof slots, IButtonProps> = {
  root: [
    'root',
    ({ disabled }) => disabled && 'disabled',
    ({ variant }) => variant,
    ({ size }) => size && `size${capitalize(size)}`,
    ({ size, variant }) => size && `${variant}Size${capitalize(size)}`,
    ({ iconLeft, iconRight, iconTop, iconBottom }) =>
      (iconLeft || iconRight || iconTop || iconBottom) && 'withIcon',
    ({ onlyIcon }) => onlyIcon && 'onlyIcon',
    ({ width }) => width === 'full' && 'fullWidth',
    ({ pressed }) => pressed && 'pressed',
  ],
  label: ['label'],
  iconLeft: ['iconLeft', ({ size }) => size && `iconSize${capitalize(size)}`],
  iconRight: ['iconRight', ({ size }) => size && `iconSize${capitalize(size)}`],
  iconTop: ['iconTop', ({ size }) => size && `iconSize${capitalize(size)}`],
  iconBottom: ['iconBottom', ({ size }) => size && `iconSize${capitalize(size)}`],
};

export const useClasses = generateHookSlots(slotsMap, prefix);
