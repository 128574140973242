import * as React from 'react';
import classNames from 'classnames';
import { memoize } from 'lodash';
import styled, { css } from 'styled-components';
import { ITheme } from '@themes/styles';
import { getThemeValue } from '@themes/utils/getters';
import { EThemeIconSize } from '@themes/styles/size';
import { getIcon, getLoadedSvgIcon } from '@themes/helpers';
import { EIcon } from '@themes/types';
import SVGIcon from '@common/components/data/svg-icon/SVGIcon';
import { IIconProps, IStyledSvgProps } from './types';
import { useIconThemeProps } from './useIconThemeProps';

const getDefaultSize = memoize((theme: ITheme) =>
  getThemeValue.sizeIcon({ theme }, EThemeIconSize.M),
);

export const StyledSvg = styled(SVGIcon)<IStyledSvgProps>`
  line-height: 0;
  ${(props) =>
    props.color &&
    css`
      color: ${getThemeValue.paletteColors[props.color](props)};
    `}
  ${(props) =>
    props.disabled &&
    css`
      color: ${getThemeValue.paletteTextDisabled};
    `}
  ${(props) => {
    if (!props.minSize) {
      return undefined;
    }
    const defaultSize = getDefaultSize(props.theme);
    return css`
      min-height: ${defaultSize};
      min-width: ${defaultSize};
    `;
  }}
`;

const Icon: React.FC<IIconProps> = (props) => {
  const { loaderSize, width, height } = useIconThemeProps(props);
  const {
    icon,
    disabled,
    className,
    loaderClassName: loaderClassNameFromProps,
    color,
    imgRef,
    minSize,
    onImgLoad,
  } = props;
  const [path, setPath] = React.useState(getLoadedSvgIcon(icon));

  React.useEffect(() => {
    let isMounted = true;
    (async () => {
      const loadedPath = getLoadedSvgIcon(icon);
      if (loadedPath === ' ') {
        const _path = await getIcon(icon as EIcon);
        if (isMounted) setPath(_path);
      } else {
        setPath(loadedPath);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [icon]);

  // проверяем, если строка в base64, то начинается ли она с модификаторов png|jpeg|bmp|gif|ico,
  // либо если это url, заканчивается ли он на расширения растровых изображений
  // либо если это url, запрашиващий изображение через GetBin
  const isImage = /(?:(^data:image\/(?:png|jpeg|bmp|gif|x-icon))|(\.(?:jpeg|jpg|png|bmp|gif|ico)$))|(^(https?):\/\/.*\/GetBin\?)/.test(
    icon,
  );
  const loaderClassName = classNames(loaderClassNameFromProps, className);

  return isImage ? (
    <img
      src={path}
      alt=""
      className={className}
      height={height}
      width={width}
      onLoad={onImgLoad}
      ref={imgRef}
    />
  ) : (
    <StyledSvg
      color={color}
      disabled={disabled}
      src={path}
      className={className}
      loaderClassName={loaderClassName}
      loaderSize={loaderSize}
      height={height}
      width={width}
      minSize={minSize}
    />
  );
};

Icon.defaultProps = {
  className: undefined,
};

export default React.memo(Icon);
