import { generateHookSlots, SlotMap } from '@themes/utils/classes';

export const componentName = 'Divider';
export const classPrefix = `Ui${componentName}`;
const slots = ['root', 'vertical', 'horizontal'] as const;
const slotMap: SlotMap<typeof slots> = {
  root: ['root'],
  vertical: ['vertical'],
  horizontal: ['horizontal'],
};
export const useClasses = generateHookSlots(slotMap, classPrefix);
