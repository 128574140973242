import * as React from 'react';
import {
  ITreeItem,
  TreeListViewItemKey,
} from '@common/components/data/tree-list-view/TreeListViewModel';

interface IProps {
  list: ITreeItem[];
  selectedKey?: number;
  selectItem: (key: TreeListViewItemKey) => void;
}

const useSelected = ({ selectItem, selectedKey, list }: IProps) => {
  const [collapsed, setCollapsed] = React.useState<TreeListViewItemKey>();
  const [selected, setSelected] = React.useState<TreeListViewItemKey>(selectedKey);

  React.useEffect(() => {
    setSelected(selectedKey);
    const selectedItem = list.find((item) => item.key === selectedKey);
    if (selectedItem) {
      setCollapsed(selectedItem.parent === '' ? selectedKey : selectedItem.parent);
    }
  }, [selectedKey]);

  const handleSelected = (key: TreeListViewItemKey) => {
    setSelected(key);
  };

  const handleCollapsed = (key: TreeListViewItemKey) => {
    setCollapsed(key);
  };

  const selectMenuItem = (key: TreeListViewItemKey) => {
    selectItem?.(key);
    setSelected(key);
  };

  return {
    collapsed,
    selected,
    handleSelected,
    handleCollapsed,
    selectMenuItem,
  };
};

export default useSelected;
