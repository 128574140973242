import { IMouseEventHandler } from '@common/types/mouseEvent';
import { IContextMenuAdvanceItem } from '@common/components/popups/context-menu-advance';
import { IButtonProps } from '@common/components/controls/Button/types';
import {
  IContextMenuAdvancedData,
  IContextMenuProps,
} from '@common/components/popups/context-menu-advance/types';
import { EIcon } from '@themes/types';

export enum EButtonDropdownVariant {
  variant1 = 'variant1',
  variant2 = 'variant2',
}

export enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum EAlign {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface IButtonDropdownProps<TActionType = string | number>
  extends IButtonProps,
    Pick<IContextMenuProps, 'customization' | 'hideOnLeave' | 'saveFocus'>,
    Pick<IContextMenuAdvancedData<TActionType>, 'onItemTypeClick'> {
  /** список пунктов меню */
  items: IContextMenuAdvanceItem<TActionType>[];
  /** событие при выборе пункта меню */
  onItemClick?: IMouseEventHandler<IContextMenuAdvanceItem<TActionType>>;
  /** Класс, передаваемый в саму кнопку */
  buttonClassName?: string;
  /** Вариант отображения */
  displayVariant?: EButtonDropdownVariant;
  /** Иконка для отображения на ButtonDropdown */
  icon?: EIcon;
  /** Позиция иконки относительно текста */
  iconPosition?: IconPosition;
  /** Применять ли "display:inline-flex" к компоненту. TODO: если потом понадобится - можно расширить этот проп */
  inlineFlex?: boolean;
  /** Включает разделительный вид. Слева отображается кнопка, на которую можно кликнуть, справа - кнопка открывающая выпадающий список */
  isDividedMode?: boolean;
  /** Заголовок у кнопки с меню в разделительном виде (isDividedMode: true) */
  menuButtonCaption?: string;
  /** Показывать контекстное меню при наведении */
  showOnHover?: boolean;
  /** Расположение выпадающего меню */
  position?: IPosition;
  onShow?: (id: string) => void;
  onHide?: () => void;
  /** Ширина контекстного меню */
  contextMenuWidth?: number;
  /** Выравнивание выпадающего меню относительно кнопки */
  align?: IAlign;
}

export type IPosition = 'left' | 'right' | 'top' | 'bottom';
export type IAlign = 'left' | 'right';

export interface IStyledButton extends IButtonProps {
  /** Цвет кнопки, когда выделен элемент подменю */
  activeColor?: string;
}
