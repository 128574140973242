import { currentPalette } from '@themes/utils/getters/palette';
import styled, { css } from 'styled-components';
import { withPx } from '@themes/utils/size-manipulator';
import { disabled } from '@themes/utils/pseudo-classes';
import { IButtonBaseProps } from './types';

const createDisabledStyles = () =>
  disabled(css`
    cursor: default;
    color: ${(props) => currentPalette(props).text.disabled};
  `);

const StyledButton = styled.button<IButtonBaseProps>`
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: ${(props) => withPx(props.theme.shape?.borderRadius)};
  outline: none;
  cursor: pointer;
  transition-duration: 0.15s;
  transition-property: color, border-color, background-color;
  user-select: none;
  // DISABLED
  ${createDisabledStyles()};
`;

export default StyledButton;
