import * as React from 'react';
import {
  ITreeItem,
  ITreeListViewAutoSelectedAndCollapsed,
  TreeListViewItemKey,
} from '@common/components/data/tree-list-view/TreeListViewModel';
import { ITreeListViewCustomization } from '@common/components/data/tree-list-view/types/types';
import { EIcon } from '@themes/types';
import { StyledTreeListView } from '../../styled';
import { useClasses } from '../classes';
import { ITreeProps } from '../types';

const treeSettingsCollapse: ITreeListViewAutoSelectedAndCollapsed = {
  enabled: true,
  initiallyCollapsed: true,
};

const Tree: React.FC<ITreeProps> = ({
  list,
  selectItem,
  hideTreeIcons,
  hideChildIcons,
  imagesPath,
  handleCollapsed,
  selected,
  collapsed,
}) => {
  const slots = useClasses();

  const arrowIcon = `${imagesPath}/ActionsArrow-down-thin.svg`;
  const icon = imagesPath ? arrowIcon : EIcon.ActionsArrowDown;

  const treeCustomization: ITreeListViewCustomization = React.useMemo(
    () => ({
      collapseIcon: {
        icon,
        rotate: 180,
        padding: 28,
        marginRight: '12px',
      },
      changeSelectedBackground: false,
      hoverBackground: '#e8e9eb',
      wordWrap: true,
      itemPaddingRight: 0,
    }),
    [imagesPath],
  );

  const data = React.useMemo<ITreeItem[]>(
    () =>
      list?.map<ITreeItem>((el) => ({
        key: el.key,
        caption: el.caption,
        parent: el.parent || '',
        icon: el.icon,
        selected: el.key === selected,
        collapsed: el.key === collapsed,
        object: el,
        contentClass: slots.treeItem,
        itemSettings: el.itemSettings,
        iconSize: el.parent ? 16 : 24,
      })),
    [list, selected, collapsed, slots],
  );

  const handleToggleItem = React.useCallback(
    (key: TreeListViewItemKey) => {
      handleCollapsed(key);
    },
    [handleCollapsed],
  );

  const handleClick = React.useCallback(
    (key: TreeListViewItemKey) => {
      selectItem(key);
    },
    [selectItem],
  );

  return (
    <StyledTreeListView
      data={data}
      autoSelectedAndCollapsed={treeSettingsCollapse}
      customization={treeCustomization}
      parentsPartialSelection
      onItemClick={handleClick}
      onItemToggle={handleToggleItem}
      rightToggleIcon
      className={slots.tree}
      hideIcons={hideTreeIcons}
      hideChildIcons={hideChildIcons}
    />
  );
};
export default Tree;
