import * as React from 'react';
import { DraggableProvided, Draggable, Position } from 'react-beautiful-dnd';
import { EIcon } from '@themes';
import { IBuiltTreeItem } from '../TreeListViewModel';
import { StyledDnDItem, StyledTreeItemDraggableIndicator } from '../styled/StyledTreeItem';
import { IStyledDndProps } from '../types/types';
import { itemSelectedArray, treeItemClassNamesMap } from '../constants';

interface IDraggableAreaProps extends Pick<IStyledDndProps, 'cursor'> {
  item: IBuiltTreeItem;
  RenderedItem: JSX.Element;
  isDefaultVariant: boolean;
  draggingId: string;
  /** Используется для расчёта сдвига перетаскиваемого объекта относительно курсора */
  handlerBeforeCapture: (result: Position) => void;
}

const DraggableArea: React.FC<IDraggableAreaProps> = ({
  item,
  RenderedItem,
  isDefaultVariant,
  draggingId,
  handlerBeforeCapture,
  cursor,
}) => {
  /** Рефка для хранения перетаскиваемого контейнера при ДнД */
  const ref = React.useRef<HTMLElement>(null);

  const bindBeforeCapture = React.useCallback(
    (event: CustomEvent): void => {
      const { before } = event.detail;
      const { clientSelection, selectedCount } = event.detail;
      if (handlerBeforeCapture) {
        handlerBeforeCapture(clientSelection);
      }
      const isMultiDrag = selectedCount > 1;
      if (before.draggableId !== item?.draggableId) {
        return;
      }
      const el: HTMLElement = ref.current;
      if (!el) {
        return;
      }
      const rect: DOMRect = el.getBoundingClientRect();
      // Получаем приблизительные размеры перетаскиваемого элемента и его координаты
      const targetWidth = isMultiDrag ? 136 : 232;
      const halfWidth: number = targetWidth / 2;
      const distanceToLeft: number = Math.max(clientSelection.x - rect.left, 0);
      el.style.width = `${targetWidth}px`;

      // Если не выходим за размеры области перетаскивания ничего не меняем
      if (distanceToLeft < halfWidth) {
        return;
      }
      // Находим расчетный отступ слева
      const proposedLeftOffset: number = distanceToLeft - halfWidth;
      // Рассчитываем границу справа
      const targetRight: number = rect.left + proposedLeftOffset + targetWidth;
      // Находим на сколько мы выходим за границу справа
      const rightOverlap: number = Math.max(targetRight - rect.right, 0);
      // Рассчитываем конечный отступ слева
      const leftOffset: number = proposedLeftOffset - rightOverlap;
      el.style.position = 'relative';
      el.style.left = `${leftOffset}px`;
    },
    [ref?.current],
  );

  const refCallback = React.useCallback(
    (innerRef: (element: HTMLElement) => void) => (node: HTMLElement) => {
      innerRef(node);
      ref.current = node;
    },
    [],
  );

  // Подписываемся на кастомное событие onBeforeCapture чтобы скорректировать координаты перемещаемой части
  React.useEffect(() => {
    window.addEventListener('onBeforeCapture', bindBeforeCapture);
    return () => {
      window.removeEventListener('onBeforeCapture', bindBeforeCapture);
    };
  }, [bindBeforeCapture]);

  const draggableRow = React.useCallback(
    (provided: DraggableProvided) => {
      // подключаем обработку положения перетаскиваемого контейнера только для дефолтного варианта темы и перетаскиваемого контейнера
      const reference = isDefaultVariant ? refCallback(provided?.innerRef) : provided?.innerRef;
      const { selected, isDraggable } = item;
      const isItemSelected = itemSelectedArray.includes(selected);
      return (
        <StyledDnDItem
          key={item?.draggableId}
          ref={reference}
          {...provided?.dragHandleProps}
          {...provided?.draggableProps}
          style={{
            ...provided?.draggableProps?.style,
            transform: 'none !important',
            cursor: item.isDraggable ? cursor : 'pointer',
          }}
          isItemSelected={isItemSelected}
          isDraggable={isDraggable}
        >
          {RenderedItem}
          <StyledTreeItemDraggableIndicator
            icon={EIcon.ActionsDraggable}
            className={`${treeItemClassNamesMap.itemDnd}-indicator`}
          />
        </StyledDnDItem>
      );
    },
    [isDefaultVariant, item, RenderedItem, draggingId, cursor],
  );

  return (
    <Draggable
      key={item?.draggableId}
      draggableId={item?.draggableId}
      index={item.index}
      isDragDisabled={!item?.isDraggable}
    >
      {(provided: DraggableProvided): JSX.Element => draggableRow(provided)}
    </Draggable>
  );
};

export default DraggableArea;
