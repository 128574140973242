import { IMap } from '@common/types/map';
import { Theme } from '@themes/components/ThemeProvider';
import { getFullThemesPath } from '@themes/components/ThemeProvider/utils';
import axios, { AxiosResponse } from 'axios';
import { EIcon } from '@themes/types';

const iconLoadedMap: IMap<string> = {};
const promisesMapForThemes: IMap<IMap<Promise<AxiosResponse>>> = {};

const loadFromPath = async (path: string, theme: string, icon: EIcon) => {
  if (!promisesMapForThemes[theme]) promisesMapForThemes[theme] = {};
  let promise;
  if (promisesMapForThemes[theme][icon]) {
    promise = promisesMapForThemes[theme][icon];
  } else {
    promise = axios.get(path);
    promisesMapForThemes[theme][icon] = promise;
  }
  return (await promise)?.data;
};
/**
 * лучше использовать компонент Icon, который сам определяет
 * путь в зависимости от установленной темы
 */
export const getIcon = async (icon: EIcon): Promise<string> => {
  if (!icon) {
    return undefined;
  }
  // проверяем загружена ли иконка и берем актуальный путь
  if (iconLoadedMap[icon]) {
    return iconLoadedMap[icon];
  }

  const selected = Theme.selected();
  const defaultThemeKey = Theme.defaultKey;

  let path = `${getFullThemesPath(Theme.themesPath)}/${selected}/icons/${icon}.svg`;
  let image;

  try {
    image = await loadFromPath(path, selected, icon);
  } catch (e) {
    try {
      if (selected === defaultThemeKey) throw new Error();
      path = `${getFullThemesPath(Theme.themesPath)}/${defaultThemeKey}/icons/${icon}.svg`;
      image = await loadFromPath(path, defaultThemeKey, icon);
    } catch (ee) {
      image = `${getFullThemesPath(Theme.themesPath)}/${defaultThemeKey}/icons/${
        EIcon.NotImage
      }.svg`;
    }
  }

  iconLoadedMap[icon] = image;
  return image;
};

/**
 * Функция для получения url из иконки svg
 * @param  { string } svg - иконка в формате <svg ...>
 * @returns { string } - url
 */
export const svgToImageUrl = (svg: string): string =>
  `data:image/svg+xml;base64,${window.btoa(svg)}`;

/**
 * Функция для получения url иконки из EIcon
 * @param  { string } icon - EIcon
 * @returns { string } - url
 */
export const getIconUrl = async (icon: EIcon): Promise<string> =>
  svgToImageUrl(await getIcon(icon));

export const getLoadedSvgIcon = (icon: string): string => {
  if (!Object.values(EIcon).includes(icon as EIcon)) {
    return icon;
  }
  return iconLoadedMap[icon] ?? ' ';
};
