import styled, { css } from 'styled-components';
import {
  IStyledTreeContainerProps,
  IStyledTreeWrapper,
} from '@common/components/data/tree-list-view/types/types';
import { getThemeValue } from '@themes/utils/getters';
import { EThemeControlSize } from '@themes/styles/size';

export const StyledTreeContainer = styled.div<IStyledTreeContainerProps>`
  display: inline-flex;
  // inline-flex добавляет пустое пространство снизу, т.к. вертикальное
  // выравнивание у inline - baseline.
  vertical-align: top;
  flex-direction: column;
  min-width: 100%;
  background: ${(props) => props?.background ?? getThemeValue.paletteBgDefault(props)};
  ${({ size }) =>
    size === EThemeControlSize.XS &&
    css`
      padding: 0;
    `}

  ${({ customStyle }) =>
    customStyle
      ? css`
          color: ${customStyle?.color};
          font-size: ${customStyle?.fontSize};
          font-style: ${customStyle?.fontStyle};
          font-weight: ${customStyle?.fontWeight};
          line-height: ${customStyle?.lineHeight};
          text-decoration: ${customStyle?.textDecoration};
          * {
            font-family: ${customStyle?.fontFamily};
          }
        `
      : ''}
`;

export const StyledTreeWrapper = styled.div<IStyledTreeWrapper>`
  width: 100%;
  min-width: max-content;

  &:focus {
    outline: none;
  }

  ${({ wordWrap }) =>
    wordWrap &&
    css`
      position: relative;
      min-width: 100%;
    `}

  ${({ disableTextSelection }) =>
    disableTextSelection &&
    css`
      user-select: none;
    `}
`;
