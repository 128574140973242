import * as React from 'react';
import classNames from 'classnames';
import Box from '@common/components/layout/box';
import SVG from '../react-inlinesvg';
import './SVGIcon.less';

export interface ISvgIconProps {
  /** Путь до иконки */
  src: string;
  /** Дополнительный css класс */
  className?: string;
  /** Дополнительный css класс для лоадера */
  loaderClassName?: string;
  /** Размер лоадера (px) */
  loaderSize?: number | string;
  /** Размер иконки */
  size?: 'XS' | 'S' | 'M' | 'L';
  /** Задание высоты */
  height?: number | string;
  /** Задание ширины */
  width?: number | string;
}

const mainCls = 'ui-svg-icon';

const SVGIcon: React.FC<ISvgIconProps> = ({
  className,
  loaderClassName,
  src,
  loaderSize,
  size,
  height,
  width,
}) => {
  const classes = React.useMemo(
    () =>
      classNames(mainCls, {
        [className]: className,
        [`${mainCls}__size-${size}`]: size,
      }),
    [className, size],
  );

  const svgIconStyle = React.useMemo<React.CSSProperties>(() => {
    if (!height && !width) {
      return undefined;
    }
    return {
      height,
      width,
    };
  }, [height, width]);

  const loaderClasses = classNames(`${mainCls}-loader`, {
    [loaderClassName]: loaderClassName,
  });

  const loader = React.useMemo(() => {
    const styles: React.CSSProperties = {
      width: loaderSize,
      height: loaderSize,
    };
    return <svg className={loaderClasses} style={styles} />;
  }, [loaderClasses, loaderSize]);

  if (!src || src === ' ') return loader;
  return (
    <Box
      className={classes}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height={height}
      width={width}
      minWidth="unset"
    >
      <SVG src={src} loader={loader} style={svgIconStyle} />
    </Box>
  );
};

SVGIcon.defaultProps = {
  loaderSize: 24,
};

export default SVGIcon;
