import { EThemeControlSize, EThemeIconSize } from '@themes/styles/size';
import cn from 'classnames';
import * as React from 'react';
import { useClasses } from '@common/components/controls/Button/classes';
import {
  StyledButton,
  StyledButtonContentWrapper,
  StyledButtonIconWrapper,
  StyledButtonLabel,
  StyledIcon,
} from './StyledButton';
import { IButtonIcon, IButtonProps } from './types';

const getIcon = (
  className: string,
  icon: IButtonIcon,
  width: IButtonProps['iconWidth'],
  height: IButtonProps['iconHeight'],
) => (
  <StyledButtonIconWrapper className={className}>
    <StyledIcon icon={icon} size={EThemeIconSize.M} width={width} height={height} />
  </StyledButtonIconWrapper>
);

/**
 * Кнопки позволяют пользователям выполнять действия и делать выбор одним нажатием. Кнопки
  обозначают действия, которые могут выполнять пользователи. Они используются в таких местах
  пользовательского интерфейса, как: Диалоги, Всплывающие окна, Формы, Мастера, Панели
  инструментов
 */
const Button: React.FC<IButtonProps> = (props) => {
  const {
    className,
    onlyIcon,
    caption,
    title,
    size,
    iconLeft,
    iconRight,
    iconTop,
    iconBottom,
    iconCentering,
    iconHeight,
    iconWidth,
    autoFocus,
  } = props;
  const classes = useClasses(props);
  const withDefaultSize = size ?? EThemeControlSize.M;
  const positionedIcon = iconLeft || iconRight || iconTop || iconBottom;

  const content = React.useMemo(() => {
    if (onlyIcon && positionedIcon) {
      return (
        <StyledIcon
          icon={positionedIcon}
          size={EThemeIconSize.M}
          width={iconWidth}
          height={iconHeight}
        />
      );
    }
    const label = (
      <StyledButtonLabel iconCentering={iconCentering} className={classes.label}>
        {caption}
      </StyledButtonLabel>
    );
    if (positionedIcon) {
      const middleContent = (
        <>
          {iconLeft && getIcon(classes.iconLeft, iconLeft, iconWidth, iconHeight)}
          {label}
          {iconRight && getIcon(classes.iconRight, iconRight, iconWidth, iconHeight)}
        </>
      );
      if (iconTop || iconBottom) {
        return (
          <StyledButtonContentWrapper display="flex" flexDirection="column">
            {iconTop && getIcon(classes.iconTop, iconTop, iconWidth, iconHeight)}
            <StyledButtonContentWrapper display="inline-flex">
              {middleContent}
            </StyledButtonContentWrapper>
            {iconBottom && getIcon(classes.iconBottom, iconBottom, iconWidth, iconHeight)}
          </StyledButtonContentWrapper>
        );
      }
      return middleContent;
    }
    return label;
  }, [
    onlyIcon,
    iconRight,
    iconLeft,
    caption,
    iconCentering,
    iconTop,
    iconBottom,
    iconWidth,
    iconHeight,
  ]);

  return (
    <StyledButton
      {...props}
      className={cn(classes.root, className)}
      testId={props.testId}
      size={withDefaultSize}
      title={title ?? caption}
      autoFocus={autoFocus}
    >
      {content}
    </StyledButton>
  );
};

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
};

export default Button;
