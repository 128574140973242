import * as React from 'react';
import { AutoSizer } from 'react-virtualized';
import { Size } from '@common/hooks/types';
import Box from '@common/components/layout/box';

export type WithAutosize = <P extends Partial<Size>>(
  Component: React.ComponentType<P>,
) => (props: Omit<P, keyof Size>) => JSX.Element;

const autosizerStyle: React.CSSProperties = { width: '100%', height: '100%' };

const withAutosize: WithAutosize = (Component) => (props) => {
  const WrapperComponent: React.ComponentType<Omit<typeof props, keyof Size>> = Component;
  return (
    <Box overflow="hidden" width="100%" height="100%">
      <AutoSizer className="autosizer" style={autosizerStyle}>
        {({ height, width }) => <WrapperComponent {...props} {...{ height, width }} />}
      </AutoSizer>
    </Box>
  );
};

export default withAutosize;
