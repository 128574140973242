export enum EThemeControlSize {
  S = 's',
  M = 'm',
  L = 'l',
  XS = 'xs',
}

export enum EThemeIconSize {
  M = 'm',
  L = 'l',
}

export interface ComboBox {
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
}

export interface Select {
  padding: string;
}

export interface IThemeSize {
  control: Partial<{ [key in EThemeControlSize]: string }>;
  icon: Partial<{ [key in EThemeIconSize]: string }>;
  comboBox?: Partial<{ [key in EThemeControlSize]: ComboBox }>;
  select?: Partial<{ [key in EThemeControlSize]: Select }>;
}
