import * as React from 'react';
import classNames from 'classnames';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import Item from '@common/components/data/tree-list-view/components/Item';
import { treeBuilder, treeListItemClick } from '@common/components/data/tree-list-view/utils';
import { MouseEvent } from '@common/types/mouseEvent';
import withAutosize from '@common/hocs/withAutosize/withAutosize';
import {
  IBuiltTreeItem,
  ITreeItem,
  ITreeListViewImplProps,
  TreeListViewItemKey,
} from '@common/components/data/tree-list-view/TreeListViewModel';
import {
  StyledTreeContainer,
  StyledTreeWrapper,
} from '@common/components/data/tree-list-view//styled/StyledTree';
import { ITreeListViewWithAutosize } from '@common/components/data/tree-list-view/types/types';
import { getTreeItemStylesBySize } from '@common/components/data/tree-list-view/styled/utils';
import { EThemeControlSize } from '@themes/index';
import { treeClassNamesMap } from '@common/components/data/tree-list-view/constants';

type ITreeListViewVisibleRange = {
  visibleStartIndex: number;
  visibleStopIndex: number;
};

const Tree: React.FC<ITreeListViewImplProps & ITreeListViewWithAutosize> = (props) => {
  const {
    data,
    parentsPartialSelection,
    calcDedicatedParents,
    buildTreeData,
    focusedKey,
    onItemClick,
    className,
    testId,
    customization,
    height,
    virtualize,
    onItemHover,
    onItemLeave,
    onItemDoubleClick,
    onItemToggle,
    onItemRightClick,
    onVisibleRangeChange,
  } = props;

  const outerRef = React.useRef<HTMLDivElement>();
  const innerRef = React.useRef<HTMLDivElement>();
  const [visibleRange, setVisibleRange] = React.useState<ITreeListViewVisibleRange>(null);

  const tree = React.useMemo((): IBuiltTreeItem[] => {
    const isItemsSorted = typeof virtualize === 'boolean' ? false : virtualize.isItemsSorted;
    const treeData =
      buildTreeData ??
      treeBuilder(
        data,
        parentsPartialSelection,
        { autoIsDraggable: false },
        calcDedicatedParents,
        isItemsSorted,
      );
    return treeData?.flat;
  }, [data, parentsPartialSelection, calcDedicatedParents, buildTreeData, virtualize]);

  React.useEffect(() => {
    // Подгоняем горизонтальный скролл, т.к. элементы в виртуализированном дереве
    // в абсолютной позиции, то приходится здавать ширину вручную.
    if (visibleRange && innerRef.current && outerRef.current && customization?.isOverflowAuto) {
      innerRef.current.style.minWidth = '';
      // Это вызывает доп. перерисовку в бразуере.
      // Иной вариант считать ширину всех элементов, с учетом виртуализации
      // их не так много, но не понятно, что быстрее, пока так.
      innerRef.current.style.minWidth = `${outerRef.current.scrollWidth}px`;
    }
  }, [visibleRange, innerRef.current, outerRef.current, height, customization?.isOverflowAuto]);

  const handleItemClick = React.useCallback(
    (key: TreeListViewItemKey, event: MouseEvent, item: ITreeItem): void => {
      treeListItemClick(key, event, data, focusedKey, onItemClick, tree, item);
    },
    [data, focusedKey, onItemClick, tree],
  );

  const handleVisibleRangeChange = React.useCallback(
    (range: ITreeListViewVisibleRange) => {
      setVisibleRange(range);
      onVisibleRangeChange?.({
        start: range.visibleStartIndex,
        stop: range.visibleStopIndex,
      });
    },
    [onVisibleRangeChange],
  );

  const renderItem = React.useCallback(
    ({ index, style }: ListChildComponentProps): JSX.Element => {
      const item = tree?.[index];
      if (customization?.isOverflowAuto) {
        style.width = '';
        // Если ширина элемента меньше, чем доступная ширина, то дотягиваем его до конца, чтобы селкшен не обрезанный был.
        style.minWidth = 'inherit';
      }

      return (
        <Item
          cssStyle={style}
          item={item}
          onItemClick={handleItemClick}
          onItemRightClick={onItemRightClick}
          onItemDoubleClick={onItemDoubleClick}
          onItemToggle={onItemToggle}
          onItemHover={onItemHover}
          onItemLeave={onItemLeave}
          testId={testId}
          customization={customization}
        />
      );
    },
    [
      handleItemClick,
      testId,
      onItemHover,
      onItemLeave,
      onItemDoubleClick,
      onItemToggle,
      onItemRightClick,
      customization,
      tree,
    ],
  );

  const length = tree?.length;
  if (!length) {
    return null;
  }

  const { tree: treeClassName, wrapper: wrapperClassName } = treeClassNamesMap;
  const classes = classNames(treeClassName, {
    [className]: className,
  });
  const { rowHeight } = getTreeItemStylesBySize(customization?.size ?? EThemeControlSize.M);

  return (
    <StyledTreeContainer
      className={classes}
      data-testid={testId}
      size={customization?.size}
      customStyle={customization?.customStyle}
      background={customization?.background}
    >
      <StyledTreeWrapper className={wrapperClassName}>
        <FixedSizeList
          width="100%"
          height={height}
          itemSize={rowHeight}
          outerRef={outerRef}
          innerRef={innerRef}
          itemCount={length}
          onItemsRendered={handleVisibleRangeChange}
        >
          {renderItem}
        </FixedSizeList>
      </StyledTreeWrapper>
    </StyledTreeContainer>
  );
};

export default React.memo(withAutosize(Tree));
