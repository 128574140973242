// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./img/waiter.gif";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-waiter {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 100000;\n  width: 100%;\n  min-width: 40px;\n  height: 100%;\n  min-height: 28px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n  background-size: 32px;\n  cursor: progress;\n}\n", "",{"version":3,"sources":["webpack://./../../common/components/data/waiter/Waiter.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,eAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,oEAAA;EACA,qBAAA;EACA,gBAAA;AACF","sourcesContent":[".ui-waiter {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 100000;\n  width: 100%;\n  min-width: 40px;\n  height: 100%;\n  min-height: 28px;\n  background: url('img/waiter.gif') no-repeat center;\n  background-size: 32px;\n  cursor: progress;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
