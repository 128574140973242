import styled, { css } from 'styled-components';
import Button from '@common/components/controls/Button';
import Box from '@common/components/layout/box';
import { IButtonDropdownProps, IStyledButton } from './types';

export const StyledBox = styled(Box)<Pick<IButtonDropdownProps, 'inlineFlex'>>`
  max-width: 220px;
  width: fit-content;

  ${({ inlineFlex }) =>
    inlineFlex &&
    css`
      display: inline-flex;
    `}
`;

export const StyledButton = styled(Button)<IStyledButton>`
  max-width: 220px;
  ${({ active, activeColor }) => {
    if (active && activeColor) {
      return css`
        background: ${activeColor};
      `;
    }
    return undefined;
  }}
`;
export const StyledArrowButton = styled(Button)`
  margin-left: 1px;
`;
export const StyledDividedModeButton = styled.div<{ isDefThemeVariant?: boolean }>`
  display: flex;
  overflow: hidden;
  border-radius: ${(props) => (props?.isDefThemeVariant ? `4px` : `2px`)};
  ${StyledButton} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  ${StyledArrowButton} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
