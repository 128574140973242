import { generateHookSlots, SlotMap } from '@themes/utils/classes';

export const componentName = 'NavigationList';
export const prefix = `Ui${componentName}`;
const slots = [
  'root',
  'flat',
  'flatWrapper',
  'flatList',
  'flatItem',
  'flatSubItem',
  'tree',
  'treeItem',
  'scrollUp',
  'scrollDown',
] as const;
const slotMap: SlotMap<typeof slots> = {
  root: ['root'],
  flat: ['flat'],
  flatWrapper: ['flatWrapper'],
  flatList: ['flatList'],
  flatItem: ['flatItem'],
  flatSubItem: ['flatSubItem'],
  tree: ['tree'],
  treeItem: ['treeItem'],
  scrollUp: ['scrollUp'],
  scrollDown: ['scrollDown'],
};
export const useClasses = generateHookSlots(slotMap, prefix);
