// eslint-disable-next-line import/no-extraneous-dependencies
import { useTheme } from 'styled-components';
import * as React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as _ from 'lodash';
import withComponentThemeProps from './withComponentThemeProps';

/**
 * Получаем пропсы с пропсами замерженными из темы
 * @param componentName
 * @param section
 * @param defaultProps
 */
const useComponentThemeProps = <P>(
  componentName: string,
  section: 'common' | 'platform' = 'common',
  defaultProps: P,
): P => {
  const theme = useTheme();
  return React.useMemo(() => {
    const componentTheme = theme?.[section]?.[componentName];
    const themeDefaultProps = componentTheme?.defaultProps ?? ({} as P);
    if (Object.keys(themeDefaultProps).length) {
      return _.merge(defaultProps, themeDefaultProps) as P;
    }
    return defaultProps;
  }, [componentName, theme]);
};

export { useComponentThemeProps, withComponentThemeProps };
