import { generateHookSlots, SlotMap } from '@themes/utils/classes';
import { IButtonDropdownProps } from '@common/components/controls/button-dropdown/types';

export const componentName = 'ButtonDropdown';
export const prefix = `Ui${componentName}`;
const slots = ['root', 'button', 'menu'] as const;
const slotMap: SlotMap<typeof slots, IButtonDropdownProps> = {
  root: ['root', ({ disabled }) => disabled && 'disabled'],
  button: ['button'],
  menu: ['menu'],
};
export const useClasses = generateHookSlots(slotMap, prefix);
