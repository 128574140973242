const pascalcase = require('pascalcase');

type StringProperties<T> = { [K in keyof T]: T[K] extends string ? T[K] : never };
type StringPropertiesNames<T> = { [K in keyof T]: T[K] extends string ? K : never }[keyof T];

export function genNonOverlappingStringSuffix<
  T,
  ST extends StringProperties<T>,
  K extends StringPropertiesNames<T>
>(items: T[], fields: K[], prefix: string, startNumber = 0): string {
  const existing = new Set<string>();
  fields.forEach((field) => {
    items.forEach((item) => {
      existing.add(((item as unknown) as ST)[field]);
    });
  });
  let uid = startNumber;
  while (existing.has(`${prefix}${uid || ''}`)) {
    uid++;
  }
  return `${uid || ''}`;
}

export function genNonOverlappingString<T, K extends StringPropertiesNames<T>>(
  items: T[],
  fields: K[],
  prefix: string,
  startNumber?: number,
): string {
  return `${prefix}${genNonOverlappingStringSuffix(items, fields, prefix, startNumber)}`;
}

export function genNonOverlappingFlatStringSuffix(
  ids: string[],
  prefix: string,
  startNumber = 0,
): string {
  let uid = startNumber;
  while (ids.includes(`${prefix}${uid || ''}`)) {
    uid++;
  }
  return `${uid || ''}`;
}

export function genNonOverlappingFlatString(
  ids: string[],
  prefix: string,
  startNumber?: number,
): string {
  return `${prefix}${genNonOverlappingFlatStringSuffix(ids, prefix, startNumber)}`;
}

export function truncText(text: string, length: number): string {
  if (!text) {
    return text;
  }
  return text.length > length ? `${text.substr(0, length - 1)}...` : text;
}

export const toPascalCase = (text: string): string => pascalcase(text);

export const wordRegExp = (value: string): RegExp => new RegExp(`\\b(${value})\\b`, 'gimu');

export const replaceWord = (value: string, from: string, to: string): string => {
  const searchRegExp: RegExp = wordRegExp(from);
  return value.replace(searchRegExp, to);
};

export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

/** Функция преобразования строки со ссылками
 * если в строке есть ссылки - они оборачиваются в <a></a>
 * можно использовать для вставки через dangerouslySetInnerHTML
 * */
export const parsedURL = (value: string): string => {
  const urlCheckRegExp = /(https?:\/\/(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^\s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^\s]{2,}|https?:\/\/(?:www.|(?!www))[a-zA-Z0-9]+.[^\s]{2,}|www.[a-zA-Z0-9]+.[^\s]{2,})/gm;
  return value
    ? value.replace(
        urlCheckRegExp,
        (oldValue) => `<a href=${oldValue} target='_blank'>${oldValue}<a/>`,
      )
    : '';
};
