import { DragUpdate } from 'react-beautiful-dnd';
import {
  ITreeListCommonSettings,
  ITreeListViewItemProps,
  ITreeListViewProps,
} from '@common/components/data/tree-list-view/TreeListViewModel';
import { Size } from '@common/hooks/types';
import { ECursor } from '@common/enums/cursor';
import { IMap } from '@common/types/map';
import { EThemeControlSize } from '@themes/styles/size';
import { EIcon } from '@themes/types';
import { IControlCssStyle } from '@common/types/control-style';

export interface IStyledTreeContainerProps
  extends Pick<ITreeListViewCustomization, 'size' | 'customStyle' | 'background'> {
  /** Включен режим dnd */
  enabledDnd?: boolean;
}

export interface IStyledDndProps {
  /** Активен режим перемещения */
  isDragging?: boolean;
  /** Элемент, перемещаемый визуально */
  clone?: boolean;
  ghost?: boolean;
  source?: boolean;
  destination?: boolean;
  combine?: boolean;
  /** Курсор при ховере над элементом */
  cursor?: ECursor;
}

export interface IStyledTreeItemContainer
  extends IStyledDndProps,
    Pick<ITreeListViewItemProps, 'customization' | 'rightToggleIcon' | 'displayVariant'> {
  /** Есть ли у компонента потомки */
  isChildrenExists?: boolean;
  /** Возможно ли взаимодействовать с компонентом */
  selectable?: boolean;
  /** Выделен ли компонент */
  isItemSelected?: boolean;
  /** Выделен ли дочерний элемент у компонента */
  isItemChildSelected?: boolean;
  /** Настройки */
  itemSettings?: ITreeListCommonSettings;
  /** Уровень вложенности */
  nesting?: number;
  /** Элемент может участвовать в dnd */
  isDraggable?: boolean;
  /** Элемент является разделителем */
  isDivider?: boolean;
}

export type IStyledContent = Pick<ITreeListViewItemProps, 'customization'>;

export interface IStyledTreeItemArrow extends Pick<ITreeListViewItemProps, 'customization'> {
  collapsed?: boolean;
}

export interface ITreeItemStyles {
  rowHeight: number;
  contentMargin: string;
  paddingForCollapseIcon: number;
  paddingLeft?: number;
  rotate: string;
  fontSize?: number;
}
export interface ITreeItemStylesByTheme {
  default: ITreeItemStyles;
  xs?: ITreeItemStyles;
}

export type ITreeListViewWithAutosize = ITreeListViewProps & Size;

export interface ITreeListViewCustomization {
  collapseIcon?: {
    icon?: EIcon | string;
    padding?: number;
    rotate?: number;
    marginRight?: string;
  };
  rowHeight?: number;
  contentMargin?: string;
  /** Менять ли заливку выделеных элементов */
  changeSelectedBackground?: boolean;
  background?: string;
  /** Заливка при наведении */
  hoverBackground?: string;
  /** Переносить ли по словам */
  wordWrap?: boolean;
  /** Отступ контента справа, внутри элемента дерева */
  itemPaddingRight?: number;
  /** Размер элемента */
  size?: EThemeControlSize;
  /** Стили элемента */
  customStyle?: IControlCssStyle;
  /** Запрещает выделение текста в дереве */
  disableTextSelection?: boolean;
  /** Предотвращает обрезание элементов дерева, которые уходят за края контейнера, добавляя горизонтальный скролл. */
  isOverflowAuto?: boolean;
}

export type IStyledTreeWrapper = Pick<
  ITreeListViewCustomization,
  'wordWrap' | 'disableTextSelection'
>;

export enum ETreeListViewItemSelected {
  NOT_SELECTED = 'not-selected',
  SELECTED = 'selected',
  PART_CHILD = 'selected-partchild',
  PARENT_PART_CHILD = 'selected-parent-partchild',
}

export interface ITreeListViewItemValueState {
  update: DragUpdate;
  end: DragUpdate;
  selected1: IMap<boolean>;
  selected2?: IMap<boolean>;
  selected3?: IMap<boolean>;
  collapsed1: IMap<boolean>;
  collapsed2?: IMap<boolean>;
  collapsed3?: IMap<boolean>;
}

/** Индексы отображаемых элементов. */
export type ITreeListViewVisibleRange = {
  /** Видимый начальный индекс. */
  start: number;
  /** Видимый последний индекс. */
  stop: number;
};

export type ITreeListViewVirtualize = {
  /**
   * Данные уже отсортированы и дополнительных действий для этого не требуется.
   * TODO: Является костылем, т.к. парсер трилиста не умеет работать с не
   * полностью загруженным деревом. Доводить смысла нет, т.к. пишем новый компонент.
   */
  isItemsSorted: boolean;
};
