import styled from 'styled-components';
import { getThemeValue } from '@themes/utils/getters';

export const StyledMultiCloneLable = styled.span`
  flex: none;
  flex-grow: 0;
  margin-right: 10px;
`;

export const StyledCountContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 6px;

  height: 24px;

  background: ${getThemeValue.paletteBgPanel};
  border-radius: 2px;

  overflow: hidden;
`;

export const StyledCount = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;
