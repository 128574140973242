import * as React from 'react';
import { IMouseEventHandler } from '@common/types/mouseEvent';
import Box from '@common/components/layout/box';
import { EIcon } from '@themes/types';
import { StyledScrollButton, StyledTransparentBlock } from '../../styled';

interface IProps {
  imagesPath: string;
  /** функция вызова при клике на кнопку */
  onClick?: IMouseEventHandler;
  visible?: boolean;
  className?: string;
}

const ScrollButton: React.FC<IProps> = ({ imagesPath, onClick, className, visible = true }) => {
  const arrowIcon = `${imagesPath}/ActionsArrow-up-thin.svg`;
  const icon = imagesPath ? arrowIcon : EIcon.ActionsArrowUp;

  return visible ? (
    <Box
      position="absolute"
      zIndex={1}
      className={className}
      display="flex"
      flexDirection="column-reverse"
    >
      <StyledTransparentBlock />
      <StyledScrollButton onlyIcon iconLeft={icon} onClick={onClick} />
    </Box>
  ) : null;
};
export default ScrollButton;
