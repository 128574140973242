import * as React from 'react';
import cn from 'classnames';
import { EIcon } from '@themes/types';
import {
  StyledCheckIcon,
  StyledHotkey,
  StyledItem,
  StyledItemIcon,
  StyledTitle,
} from '@common/components/popups/context-menu-advance/styled';
import { Icon } from '@themes';
import { EThemePaletteColors } from '@themes/styles/palette';
import { IContextMenuItemProps } from '@common/components/popups/context-menu-advance/types';

export function ContextMenuAdvanceItem<TAdditionalData = {}>(
  props: IContextMenuItemProps<TAdditionalData>,
) {
  const {
    iconPath,
    title,
    tooltip,
    hotkey,
    isCheck,
    className,
    selected,
    customization,
    ...restProps
  } = props;
  const cssClass = className == null ? '' : `${className}__`;
  /** @deprecated классы только для старых тестов
   * wp/src/containers/macros-editor/containers/content-editor/code/context-menu/__tests__/MacrosEditorContextMenu.int.spec.tsx
   * */
  const checkClassNames = cn({
    [`check-icon`]: true,
    [`check-icon_selected`]: selected,
  });

  const renderIcon = React.useMemo(
    () => (
      <Icon icon={iconPath} width={customization?.iconWidth} height={customization?.iconHeight} />
    ),
    [customization, iconPath],
  );

  return (
    <StyledItem {...restProps} className={className} customization={customization}>
      {iconPath && <StyledItemIcon className={`${cssClass}icon`}>{renderIcon}</StyledItemIcon>}
      <StyledTitle title={title} tooltip={tooltip} mainClass={cssClass} />
      {hotkey && <StyledHotkey>{hotkey}</StyledHotkey>}
      {isCheck && (
        <StyledCheckIcon disabled={!selected} className={checkClassNames}>
          <Icon
            icon={EIcon.ActionsCheck}
            color={EThemePaletteColors.main}
            disabled={!selected}
            width={customization?.iconWidth}
            height={customization?.iconHeight}
          />
        </StyledCheckIcon>
      )}
    </StyledItem>
  );
}
ContextMenuAdvanceItem.displayName = 'ContextMenuAdvanceItem';

export default ContextMenuAdvanceItem;
