import { generateHookSlots, SlotMap } from '@themes/utils/classes';
import { INavigationPanelProps } from './types';

export const componentName = 'NavigationPanel';
export const prefix = `Ui${componentName}`;
const slots = ['root', 'head', 'logo', 'body', 'list', 'footer', 'button'] as const;
const slotMap: SlotMap<typeof slots, INavigationPanelProps> = {
  root: ['root'],
  head: ['head'],
  logo: ['logo'],
  body: ['body'],
  list: ['list'],
  footer: ['footer'],
  button: ['button'],
};
export const useClasses = generateHookSlots(slotMap, prefix);
