import * as React from 'react';
import memoizeOne from 'memoize-one';
import produce from 'immer';
import * as _ from 'lodash';
import { IMap } from '@common/types/map';
import {
  ITreeItem,
  ITreeListViewImplProps,
  TreeListViewItemKey,
} from '@common/components/data/tree-list-view/TreeListViewModel';
import { MouseEvent } from '@common/types/mouseEvent';
import Tree from '@common/components/data/tree-list-view/components/Tree';
import TreeDragAndDrop from '@common/components/data/tree-list-view/components/TreeDragAndDrop';
import { getTreeSelectedAndCollapsedState } from '@common/components/data/tree-list-view/utils';

interface IState {
  selectedKeys: IMap<TreeListViewItemKey>;
  toggledCollapseKeys: IMap<TreeListViewItemKey>;
}

export class TreeSelectedAndCollapsed extends React.Component<ITreeListViewImplProps, IState> {
  private updateState = memoizeOne((data: ITreeItem[]) =>
    this.setState({
      ...getTreeSelectedAndCollapsedState(data),
    }),
  );

  public constructor(props: ITreeListViewImplProps) {
    super(props);
    this.state = {
      selectedKeys: {},
      toggledCollapseKeys: {},
    };
  }

  public componentDidMount() {
    const { data } = this.props;
    if (data.length) this.updateState(data);
  }

  public componentDidUpdate(prevProps: Readonly<ITreeListViewImplProps>) {
    const { data: prevData } = prevProps;
    const { data: thisData } = this.props;
    if (!_.isEqual(prevData, thisData)) this.updateState(thisData);
  }

  private getTree = memoizeOne(
    (
      items: ITreeItem[],
      selectedKeys: IMap<TreeListViewItemKey>,
      toggledCollapseKeys: IMap<TreeListViewItemKey>,
    ): ITreeItem[] =>
      _.map<ITreeItem, ITreeItem>(items, (item: ITreeItem) => {
        const key = item?.key;
        return {
          ...item,
          selected: !!selectedKeys[key],
          collapsed: this.props.autoSelectedAndCollapsed?.initiallyCollapsed
            ? !toggledCollapseKeys[key]
            : !!toggledCollapseKeys[key],
        };
      }),
  );

  private get isMultiple(): boolean {
    const { autoSelectedAndCollapsed } = this.props;
    return autoSelectedAndCollapsed?.multiple ?? false;
  }

  private get enabledDND(): boolean {
    const { dragAndDrop } = this.props;
    return dragAndDrop?.enabled ?? false;
  }

  private updateKeys = (
    keys: IMap<TreeListViewItemKey>,
    key: TreeListViewItemKey,
  ): IMap<TreeListViewItemKey> =>
    produce(keys, (draft: IMap<TreeListViewItemKey>) => {
      if (draft[key]) {
        delete draft[key];
      } else {
        draft[key] = key;
      }
    });

  private onClickItem = (key: TreeListViewItemKey): void => {
    const { selectedKeys } = this.state;
    const updatedSelectedKeys = this.isMultiple
      ? this.updateKeys(selectedKeys, key)
      : { [key]: key };

    this.setState({
      selectedKeys: updatedSelectedKeys,
    });
  };

  private onLeftClickItem = (
    key: TreeListViewItemKey,
    keys: TreeListViewItemKey[],
    event: MouseEvent,
    item: ITreeItem,
  ): void => {
    const { onItemClick, autoSelectedAndCollapsed } = this.props;
    if (
      autoSelectedAndCollapsed?.canSelectItem &&
      autoSelectedAndCollapsed.canSelectItem(key) === false
    ) {
      return;
    }

    this.onClickItem(key);

    if (onItemClick) {
      onItemClick(key, keys, event, item);
    }
  };

  private onRightClickItem = (key: TreeListViewItemKey, object): void => {
    const { onItemRightClick } = this.props;
    this.onClickItem(key);

    if (onItemRightClick) {
      onItemRightClick(key, object);
    }
  };

  private onToggleItem = (key: TreeListViewItemKey): void => {
    const { toggledCollapseKeys } = this.state;
    const { onItemToggle } = this.props;
    this.setState({
      toggledCollapseKeys: this.updateKeys(toggledCollapseKeys, key),
    });

    if (onItemToggle) {
      onItemToggle(key);
    }
  };

  public render(): JSX.Element {
    const { selectedKeys, toggledCollapseKeys } = this.state;
    const { data } = this.props;
    const tree = this.getTree(data, selectedKeys, toggledCollapseKeys);
    const Component = this.enabledDND ? TreeDragAndDrop : Tree;
    return (
      <Component
        {...this.props}
        data={tree}
        onItemToggle={this.onToggleItem}
        onItemClick={this.onLeftClickItem}
        onItemRightClick={this.onRightClickItem}
      />
    );
  }
}

export default TreeSelectedAndCollapsed;
