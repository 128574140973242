import { EThemeControlSize } from '@themes/styles';
import { EThemeIconSize } from '@themes/styles/size';
import { IPropsWithTheme } from '@themes/utils/getters/types';

export const sizeGetters = {
  sizeControlL: (props: IPropsWithTheme) => props.theme.size?.control?.l,
  sizeControlM: (props: IPropsWithTheme) => props.theme.size?.control?.m,
  sizeControlS: (props: IPropsWithTheme) => props.theme.size?.control?.s,
  sizeIconL: (props: IPropsWithTheme) => props.theme.size?.icon?.l,
  sizeIconM: (props: IPropsWithTheme) => props.theme.size?.icon?.m,
  sizeControl: (props: IPropsWithTheme, size: EThemeControlSize) =>
    props.theme?.size?.control?.[size],
  sizeIcon: (props: IPropsWithTheme, size: EThemeIconSize) => props.theme?.size?.icon?.[size],
  sizeComboBox: (props: IPropsWithTheme, size?: EThemeControlSize) =>
    size && props.theme?.size?.comboBox?.[size],
  sizeSelect: (props: IPropsWithTheme, size?: EThemeControlSize) =>
    size && props.theme?.size?.select?.[size],
};
