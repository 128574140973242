import { generateHookSlots, SlotMap } from '@themes/utils/classes';
import { IButtonProps } from '@common/components/controls/Button/types';

export const componentName = 'IconButton';
export const prefix = `Ui${componentName}`;
const slots = ['root', 'pressed', 'withBackground'] as const;

const slotsMap: SlotMap<typeof slots, IButtonProps> = {
  root: ['root'],
  pressed: ['pressed'],
  withBackground: ['with-background'],
};

export const useClasses = generateHookSlots(slotsMap, prefix);
