import composeClasses from '@themes/utils/classes/compose';
import * as React from 'react';
import { FnSlot, SlotMap, WithSlot } from '@themes/utils/classes/types';
import { getDisplayName } from '@common/utils/getDisplayName';

export const getSlots = <ClassKey extends string>(
  slotMap: Record<ClassKey, ReadonlyArray<FnSlot | string | false | undefined | null>>,
  slotPrefix: string,
  props = {},
) => {
  const baseSlots = Object.keys(slotMap).reduce((acc, key: ClassKey) => {
    if (!acc[key]) {
      acc[key] = [];
    }
    slotMap[key].forEach((slotValue) => {
      if (typeof slotValue === 'string') {
        acc[key].push(slotValue);
      } else if (typeof slotValue === 'function') {
        acc[key].push(slotValue(props));
      }
    });
    return acc;
  }, {} as any);
  return composeClasses(baseSlots, slotPrefix);
};

export const generateHookSlots = <ClassKey extends string>(
  slotMap: Record<ClassKey, ReadonlyArray<FnSlot | string | false | undefined | null>>,
  slotPrefix: string,
) => (props?): Record<ClassKey, string> =>
  // так как это генератор хука то так можно сделать
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useMemo<Record<ClassKey, string>>(
    () => getSlots(slotMap, slotPrefix, props),
    Object.values(props || {}),
  );

export const withHocSlots = (slotMap: SlotMap, prefix: string) => {
  const useSlots = generateHookSlots(slotMap, prefix);
  return <P extends WithSlot>(
    Component: React.ComponentType<P>,
  ): React.ComponentType<Omit<P, 'slotClasses'>> => {
    const WrappedComponent = (props: P) => {
      const slots = useSlots(props);
      return <Component {...props} slotClasses={slots} />;
    };

    WrappedComponent.displayName = getDisplayName(Component);
    return WrappedComponent;
  };
};
