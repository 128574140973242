import { ITreeItemStyles } from '@common/components/data/tree-list-view/types/types';
import { EThemeControlSize } from '@themes/styles';

const DEFAULT_STYLE: ITreeItemStyles = {
  rowHeight: 32,
  contentMargin: '8px',
  paddingForCollapseIcon: 16,
  paddingLeft: 16,
  rotate: '90deg',
};

const style: Record<EThemeControlSize.S | EThemeControlSize.XS, ITreeItemStyles> = {
  s: {
    rowHeight: 32,
    contentMargin: '4px',
    paddingForCollapseIcon: 16,
    paddingLeft: 0,
    rotate: '90deg',
  },
  xs: {
    rowHeight: 24,
    contentMargin: '4px',
    paddingForCollapseIcon: 16,
    paddingLeft: 0,
    fontSize: 12,
    rotate: '90deg',
  },
};

export const getTreeItemStylesBySize = (size?: EThemeControlSize): ITreeItemStyles =>
  style[size] ?? DEFAULT_STYLE;
