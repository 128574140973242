import { ISvgIconProps } from '@common/components/data/svg-icon/SVGIcon';
import { EThemePaletteColors } from '@themes/styles/palette';
import { EThemeIconSize } from '@themes/styles/size';
import * as React from 'react';
import { getThemeValue } from '@themes/utils/getters';
import useTheme from '@themes/components/ThemeProvider/useTheme';
import { IIconProps } from './types';

export const useIconThemeProps = ({
  size,
  height: rawHeight,
  width: rawWidth,
  loaderSize: rawLoaderSize,
}: IIconProps): Partial<ISvgIconProps & { color: EThemePaletteColors; disabled: boolean }> => {
  const theme = useTheme();
  const themeProps = React.useMemo(() => ({ theme }), [theme]);
  const themeSize = React.useMemo(() => getThemeValue.sizeIcon(themeProps, size), [
    themeProps,
    size,
  ]);
  const loaderSize = rawLoaderSize || getThemeValue.sizeIcon(themeProps, size || EThemeIconSize.M);
  const getSize = React.useCallback(
    (rawSize: string | number, size: EThemeIconSize) => {
      if (rawSize) return rawSize;
      if (size) return themeSize;
      return undefined;
    },
    [themeSize],
  );

  const height = React.useMemo(() => getSize(rawHeight, size), [rawHeight, size]);
  const width = React.useMemo(() => getSize(rawWidth, size), [rawWidth, size]);

  return {
    loaderSize,
    width,
    height,
  };
};
